import React from "react";
// import LoaderSvg from "../../Assets/icons/loader.svg";
import LoaderSvgBlue from "../../Assets/icons/loader-blue.svg";

export default function AITextLoader() {
  return (
    <div role="status" className="animate-spin">
      <img alt="" src={LoaderSvgBlue} />
    </div>
  );
}
