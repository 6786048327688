import CloseIcon from "../../Assets/icons/closeIcon.svg";

function CloseModalButton({ onClick }) {
  return (
    <button
      type="button"
      data-modal-hide="default-modal"
      className=" absolute top-0 right-[16px] h-4 md:h-[24px] w-4 md:w-[24px] mt-1 md:mt-0"
      onClick={onClick}
    >
      <img src={CloseIcon} alt="CloseIcon" />
    </button>
  );
}
export default CloseModalButton;
