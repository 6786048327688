import React, { useState } from "react";
import CloseModalButton from "../buttons/CloseModalButton";
import UploadResumeImg from "../../Assets/Images/uploadResume.svg";
import DropZone from "../DropZone/DropZone";
import ArrowIcon from "../../Assets/icons/right_arrow.svg";
import { useUploadResumeForRegistrationMutation } from "../../Store/slice/AuthSlice";
import { toast } from "react-toastify";

const UploadResume = ({ setShowResume, setShowSteps, setShowModal, userProfile, setUserProfile, userDetail, setUserDetails }) => {
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadResumeOfUser, { error, isLoading }] = useUploadResumeForRegistrationMutation();


  // Function to transform the object
  // const transformSpecialitiesArray = async (arr) => {
  //   return arr.map(obj => {
  //     const result = [];

  //     Object.entries(obj).forEach(([key, value]) => {
  //       if (value && typeof value === 'object') {
  //         const trueKeys = Object.keys(value).filter(subKey => value[subKey] === true);
  //         if (trueKeys.length > 0) {
  //           result.push(`{ ${key}: [${trueKeys.join(',')}] }`);
  //         }
  //       }
  //     });

  //     return result;
  //   }).flat(); // Flatten the array if you want all strings in a single array
  // };
  const transformSpecialitiesArray = async (obj) => {
    const object = obj || {};
    const result = [];

    Object.entries(object).forEach(([key, value]) => {
      if (value && typeof value === 'object') {
        const trueKeys = Object.keys(value).filter(subKey => value[subKey] === true);
        if (trueKeys.length > 0) {
          result.push(`{ ${key}: [${trueKeys.join(',')}] }`);
        }
      }
    });

    return result;
  };

  const handleSubmit = async () => {

    const formdata = new FormData();
    formdata.append("file", file[0])
    setShowModal(true);
    // setShowResume(false);
    const response = await uploadResumeOfUser(formdata);

    // const response = {
    //   data: {
    //     "job_history": [
    //       {
    //         "company_description": "Test 12",
    //         "company_name": "Test 12",
    //         "employees": 50,
    //         "employment_type": "契約社員",
    //         "end_date": null,
    //         "job_description": "Test 12",
    //         "listing": "東証グロース市場",
    //         "position": "Test 12",
    //         "revenue": "100~500億円",
    //         "start_date": "1900-01-01"
    //       },
    //       {
    //         "company_description": "test 2",
    //         "company_name": "test 2",
    //         "employees": 50,
    //         "employment_type": "役員",
    //         "end_date": null,
    //         "job_description": "test 2",
    //         "listing": "東証スタンダード市場",
    //         "position": "test 2",
    //         "revenue": "10~100億円",
    //         "start_date": "2000-05-01"
    //       },
    //       {
    //         "company_description": "test",
    //         "company_name": "test",
    //         "employees": 80,
    //         "employment_type": "業務委託",
    //         "end_date": null,
    //         "job_description": "test",
    //         "listing": "東証プライム市場",
    //         "position": "test",
    //         "revenue": "100~500億円",
    //         "start_date": "2000-08-01"
    //       }
    //     ],
    //     "job_summary": {
    //       "summary": "申し訳ありませんが、提供された情報だけでは300文字程度の職務要約を作成するには不十分です。AI開発エンジニアとして3年間プロジェクトに参加し、技術的知見を習得した。その後、ビジネスサイドに転向し、PMOとして2年間にわたりシステム開発やコンサルティングのプロジェクトに従事した。現在は主にシステム開発に関わっています。"
    //     },
    //     "personal_info": {
    //       "affiliation": "new region",
    //       "birth_date": "2024/02/05",
    //       "first_name": "敦",
    //       "first_name_romaji": "Taroe",
    //       "last_name": "吉田",
    //       "last_name_romaji": "Shike",
    //       "region": null
    //     },
    //     "project_history": [
    //       {
    //         "achievements": "Achievements",
    //         "affiliation": "chirag",
    //         "end_date": "2017-07-01",
    //         "industry": "company name",
    //         "project_name": "chirag",
    //         "project_overview": "over view",
    //         "related_skills": {
    //           "ITプロジェクト管理": {
    //             "ITプロジェクトサポート": null,
    //             "IT補佐その他": null,
    //             "PMIT": false,
    //             "PMOIT": null
    //           },
    //           "SCM・物流": null,
    //           "その他": null,
    //           "インフラ": null,
    //           "システム手法": null,
    //           "システム開発": null,
    //           "プロジェクト管理": {
    //             "PM": true,
    //             "PMO": true,
    //             "プロジェクトサポート": null,
    //             "補佐その他": null
    //           },
    //           "マーケティング": null,
    //           "リスク管理": null,
    //           "人事・組織": null,
    //           "営業": null,
    //           "情報セキュリティ": null,
    //           "技術": null,
    //           "新規事業": null,
    //           "業務改善": null,
    //           "経営戦略": null,
    //           "財務・会計": null
    //         },
    //         "responsibilities": "O Skill 1, Skill 2",
    //         "role": "role",
    //         "start_date": "2015-07-01"
    //       },
    //       {
    //         "achievements": "AI",
    //         "affiliation": "ABC",
    //         "end_date": "2024-07-01",
    //         "industry": "project 1",
    //         "project_name": "ABC",
    //         "project_overview": "AI",
    //         "related_skills": {
    //           "ITプロジェクト管理": {
    //             "ITプロジェクトサポート": null,
    //             "IT補佐その他": null,
    //             "PMIT": false,
    //             "PMOIT": null
    //           },
    //           "SCM・物流": null,
    //           "その他": null,
    //           "インフラ": null,
    //           "システム手法": null,
    //           "システム開発": null,
    //           "プロジェクト管理": {
    //             "PM": true,
    //             "PMO": true,
    //             "プロジェクトサポート": null,
    //             "補佐その他": null
    //           },
    //           "マーケティング": null,
    //           "リスク管理": null,
    //           "人事・組織": null,
    //           "営業": null,
    //           "情報セキュリティ": null,
    //           "技術": null,
    //           "新規事業": null,
    //           "業務改善": null,
    //           "経営戦略": null,
    //           "財務・会計": null
    //         },
    //         "responsibilities": "O AI",
    //         "role": "Project 1",
    //         "start_date": "2020-09-01"
    //       }
    //     ],
    //     "skills": {
    //       "skills": [
    //         "プロジェクト管理",
    //         "チームリーダー",
    //         "skill 3"
    //       ]
    //     },
    //     "specialties": [
    //       {
    //         "ITプロジェクト管理": null,
    //         "SCM・物流": null,
    //         "その他": null,
    //         "インフラ": null,
    //         "システム手法": null,
    //         "システム開発": null,
    //         "プロジェクト管理": {
    //           "PM": false,
    //           "PMO": false,
    //           "プロジェクトサポート": false,
    //           "補佐その他": false
    //         },
    //         "マーケティング": {
    //           "CRM": true,
    //           "その他（マーケティング）": null,
    //           "カスタマーサービス": false,
    //           "サービス開発": false,
    //           "デジタルマーケティング（ToB）": true,
    //           "デジタルマーケティング（ToC）": true,
    //           "データサイエンティスト/データ分析・解析": true,
    //           "マーケティング（ToB）": false,
    //           "マーケティング（ToC）": true,
    //           "商品企画/サービス企画": false,
    //           "市場調査": true,
    //           "認知/ブランディング": true
    //         },
    //         "リスク管理": null,
    //         "人事・組織": null,
    //         "営業": null,
    //         "情報セキュリティ": null,
    //         "技術": null,
    //         "新規事業": {
    //           "その他(新規事業)": null,
    //           "リサーチ(新規事業)": false,
    //           "事業開発(企画)": true,
    //           "事業開発(実務)": false,
    //           "資金調達(新規事業)": false
    //         },
    //         "業務改善 (BPR / RPA / BPO)": {
    //           "BPO": false,
    //           "BPR": true,
    //           "RPA": false,
    //           "業務改革": true
    //         },
    //         "経営戦略": null,
    //         "財務・会計": null
    //       }
    //     ]
    //   }
    // }

    if (response?.error) {
      toast.error(
        response?.error?.data?.message || "何か問題が発生しました"
      );
      setShowModal(false);
    }

    if (response?.data) {

      const work_histories = response?.data?.job_history.map((work) => ({
        currently_affiliated: work?.currently_affiliated,
        company_name: work?.company_name,
        position: work?.position,
        description: work?.job_description,
        start_date: work?.start_date,
        end_date: work?.end_date,
        employment_type: work?.employment_type,
        amount_of_sales: work?.revenue,
        capital: work?.capital,
        listing_type: work?.listing,
        number_of_employees: work?.employees,
        business_details: work?.company_description,
      }))

      // const project_histories =  response?.data?.project_history.map(async (project) => ({
      //   currently_affiliated: project?.currently_affiliated,
      //   project_name: project?.project_name,
      //   company: project?.industry,
      //   role: project?.role,
      //   affiliation: project?.affiliation,
      //   start_date: project?.start_date,
      //   end_date: project?.end_date,
      //   project_overview: project?.project_overview,
      //   achievements_initiatives: project?.achievements,
      //   responsibilities: project?.responsibilities,
      //   related_skills: await transformSpecialitiesArray(project?.related_skills),
      // }))

      const project_histories = await Promise.all(
        response?.data?.project_history.map(async (project) => ({
          // currently_affiliated: project?.currently_affiliated,
          project_name: project?.project_name,
          company: project?.industry,
          role: project?.role,
          affiliation: project?.affiliation,
          start_date: project?.start_date,
          end_date: project?.end_date,
          project_overview: project?.project_overview,
          achievements_initiatives: project?.achievements,
          responsibilities: project?.responsibilities,
          related_skills: await transformSpecialitiesArray(project?.related_skills),
        }))
      );
      
      // Set Details of user
      const userdetails = {
        ...userDetail,
        details: {
          ...userDetail?.details,
          job_summary: response?.data?.job_summary?.summary,
          self_promotion: null,
          skills: response?.data?.skills?.skills,
          specialities: await transformSpecialitiesArray(response?.data?.specialties[0]),
          work_histories,
          project_histories,
        }
      }

      // Set Profile of user
      const userprofile = {
        ...userProfile,
        affiliation: response?.data?.personal_info?.affiliation,
        dob: response?.data?.personal_info?.birth_date,
        first_name: response?.data?.personal_info?.first_name,
        last_name: response?.data?.personal_info?.last_name,
        romanized_first_name: response?.data?.personal_info?.first_name_romaji,
        romanized_last_name: response?.data?.personal_info?.last_name_romaji,
        region: response?.data?.personal_info?.region,
      }

      setUserProfile(userprofile);
      setUserDetails(userdetails);
      setShowModal(false);
      setShowResume(false);
      setShowSteps(true);
      toast.success(
        response?.data?.message ||
        "プロファイルが正常に更新されました。"
      );
    }
  };


  return (
    <>
      <div className="overlay">
        <div
          id="upload-resume-modal"
          tabIndex="-1"
          aria-hidden="true"
          className=" fixed z-50 inset-0 overflow-y-auto"
        >
          <div className="flex justify-center items-center h-full">
            <div className="bg-[#fff] w-[326px] md:w-[500px]">
              <div className="relative">
                <div className="mx-3 md:mx-[50px] mt-[24px] mb-[16px] flex justify-center">
                  <div>
                    <h3 className="text-[16px] tracking-[5%] font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#0956FF] to-[#5389FF] text-center h-[24px] leading-[24px]">
                      AIで職務経歴書を簡単生成！
                    </h3>
                    <p className="text-xs font-normal text-[#212121] pt-3 px-2 md:px-10 text-center">
                      既存の職務経歴書をアップロードすると、AIが内容を抽出して
                      職務経歴書に反映します。
                    </p>
                  </div>
                  <CloseModalButton onClick={() => setShowResume(false)} />
                </div>
              </div>
              <div className="mx-4 my-0 flex justify-center items-center">
                <img
                  src={UploadResumeImg}
                  alt=""
                  // className="h-[85%] w-[85%] lg:h-full lg:w-full"
                  className="h-[132px] md:h-[145px] w-[280px] md:w-[307px]"
                />
              </div>
              <div>
                <DropZone
                  setIsButtonEnabled={setIsButtonEnabled}
                  isButtonEnabled={isButtonEnabled}
                  file={file}
                  setFile={setFile}
                />
              </div>
              <div className="px-4">
                <button
                  type="button"
                  onClick={() => handleSubmit()}
                  className={`${isButtonEnabled ? "bg-[#3B89FF]" : "bg-[#CED0D8]"
                    } text-white py-2 px-auto m-auto rounded-[4px] mt-6 mb-4 font-bold text-xs w-full hidden md:block`}
                >
                  アップロード
                </button>
                <button
                  type="button"
                  onClick={() => handleSubmit()}
                  className={`${isButtonEnabled ? "bg-[#3B89FF]" : "bg-[#CED0D8]"
                    } text-white py-2 px-auto m-auto rounded-[4px] mt-6 mb-4 font-bold text-xs w-full block md:hidden`}
                >
                  ドラフト生成
                </button>
                <button
                  type="button"
                  className="font-700 text-sm text-[#0956FF] mx-auto mb-6 flex justify-center items-center font-bold"
                  onClick={() => {
                    setShowResume(false);
                    setShowSteps(true);
                  }}
                >
                  スキップ
                  <img
                    src={ArrowIcon}
                    alt="arrow icon"
                    className="inline-block h-4 w-4 ml-2"
                  ></img>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadResume;
