import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const OutputResume = createApi({
    reducerPath : "OutputResume",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BASE_URL}/api`,
    }),
    endpoints: (builder)=>({
        OutputResume: builder.mutation({
            query: (payload)=>{
                return{
                    url: "/v1/users/upload_resume",
                    method: "Put",
                    body: payload,
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    }
                }
            }
        }),
        OutputResumeExcel: builder.mutation({
            query: (payload)=>{
                return{
                    url: "/v1/users/generate_resume",
                    method: "Post",
                    body: payload,
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    }
                }
            }
        }),
        OutputResumePDF: builder.mutation({
            query: (payload)=>{
                return{
                    url: "/v1/users/generate_pdf",
                    method: "Post",
                    body: payload,
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    }
                }
            }
        })
    })
})

export const { useOutputResumeMutation } = OutputResume;
export const { useOutputResumeExcelMutation } = OutputResume;
export const { useOutputResumePDFMutation } = OutputResume;