import { configureStore } from "@reduxjs/toolkit";
import { Auth } from "./slice/AuthSlice";
import { ProjectHistory } from "./slice/ProjectSlice";
import { WorkHistory } from "./slice/WorkSlice";
import { ForgotPasswordMail } from "./slice/ForgetPassword";
import { OutputResume } from "./slice/OutputResume";
import { AiDraft } from "./slice/AidraftSlice";

export const store = configureStore({
  reducer: {
    [Auth.reducerPath]: Auth.reducer,
    [WorkHistory.reducerPath]: WorkHistory.reducer,
    [ProjectHistory.reducerPath]: ProjectHistory.reducer,
    [ForgotPasswordMail.reducerPath]: ForgotPasswordMail.reducer,
    [OutputResume.reducerPath]: OutputResume.reducer,
    [AiDraft.reducerPath]:AiDraft.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      Auth.middleware,
      WorkHistory.middleware,
      ProjectHistory.middleware,
      ForgotPasswordMail.middleware,
      OutputResume.middleware,
      AiDraft.middleware,
    ]),
});
