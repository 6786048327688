import React, { useRef } from "react";
import { useDropzone } from "react-dropzone";
import ResumeIcon from "../../Assets/icons/resumeIcon.svg"
import CloseIcon from "../../Assets/icons/closeIcon.svg"

const Dropzone = (props) => {
  const { required, name, setIsButtonEnabled, isButtonEnabled,file,setFile } = props;
  const hiddenInputRef = useRef(null);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (incomingFiles) => {
      if (hiddenInputRef.current) {
        const dataTransfer = new DataTransfer();
        incomingFiles.forEach((v) => {
          dataTransfer.items.add(v);
        });
        hiddenInputRef.current.files = dataTransfer.files;
        
        setFile(incomingFiles);
        setIsButtonEnabled(true);
      }
    },
    accept: {
      "application/pdf": [".pdf"],
    },
    maxFiles:1
  });

  const handleDeleteFile = () => {
    if (hiddenInputRef.current) {
      hiddenInputRef.current.value = "";
    }
    setFile(null)
    setIsButtonEnabled(false);
  }

  return (
    <div>
      {!isButtonEnabled ? (
        <div
          {...getRootProps({
            className:
              "md:border border-dashed  border-[#5389FF] flex justify-center items-center flex-col m-4 bg-[#F6F6F6]",
          })}
        >
          <input
            type="file"
            name={name}
            required={required}
            style={{ opacity: 0 }}
            ref={hiddenInputRef}
            className="hidden md:block"
          />
          <input {...getInputProps()} />
          <p className="text-xs font-normal hidden md:block">ファイルをドラッグ&ドロップ</p>
          <p className="text-xs font-normal hidden md:block">または</p>
          <button
            type="button"
            className="py-2 px-8 lg:px-10 bg-[#0956FF] rounded-[4px] md:mt-2 md:mb-6 font-bold text-white text-xs w-full md:w-fit" 
          >
            ファイルを選択する
          </button>
          <p className="text-xs font-normal block md:hidden mt-2">ファイルが選択されていません</p>
        </div>
      ) : (
        <aside className="flex justify-between items-center bg-[#F6F6F6] mx-5 py-2 px-4 my-4 gap-2">
          <div className="flex justify-start items-center overflow-hidden">
            <img src={ResumeIcon} alt="resumeIcon" />
            <span className="pl-3 text-xs md:text-sm font-normal truncate">{file[0]?.path}</span>
          </div>
          <button onClick={()=>handleDeleteFile()}>
            <img
              src={CloseIcon}
              alt="closeIcon"
              className="w-[18px] h-[18px] cursor-pointer"
            />
          </button>
        </aside>
      )}
    </div>
  );
}

<form
  onSubmit={(e) => {
    e.preventDefault();

    // Now get the form data as you regularly would
    const formData = new FormData(e.currentTarget);
    const file = formData.get("my-file");
    alert(file.name);
  }}
>
  <Dropzone name="my-file" required />
  <button type="submit">Submit</button>
</form>;

export default Dropzone
