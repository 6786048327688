import { useNavigate } from "react-router-dom";
import logo from "../src/Assets/Images/logo.png";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="px-[40px] bg-white w-full h-[67.5px] ">
        <div className="pt-[12px] flex justify-between items-center">
          <div className="w-[107px] h-[36px] mt-[3px] cursor-pointer">
            <img src={logo} alt="logo" onClick={() => navigate("/profile")} />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center h-screen bg-[#F6F6F6] text-black ">
        <div className="text-center">
          <h1 className="text-9xl border-b border-black">404</h1>
          <h3>ページが見つかりません</h3>
          <button
            onClick={() => navigate("/profile")}
            type="button"
            className="border border-[#FF6F00] bg-transparent outline-none px-8 py-4 text-lg font-bold uppercase transition-all duration-200 ease-in hover:text-[#FF6F00] mt-8"
          >
            ホームに戻る
          </button>
        </div>
      </div>
    </div>
  );
};
export default PageNotFound;
