export const emp_type = [
  { label: "正社員", value: "正社員" },
  { label: "役員", value: "役員" },
  { label: "業務委託", value: "業務委託" },
  { label: "契約社員", value: "契約社員" },
  { label: "アルバイト", value: "アルバイト" },
  { label: "派遣社員", value: "派遣社員" },
  { label: "その他", value: "その他" },
];

export const listed_type = [
  { label: "非上場", value: "非上場" },
  { label: "東証プライム市場", value: "東証プライム市場" },
  { label: "東証スタンダード市場", value: "東証スタンダード市場" },
  { label: "東証グロース市場", value: "東証グロース市場" },
  { label: "その他", value: "その他" },
];

export const affiliation_option = [
  { label: "自営業", value: "自営業" },
  { label: "会社役員", value: "会社役員" },
  { label: "個人事業主", value: "個人事業主" },
  { label: "会社員", value: "会社員" },
];

export const Sales_amount = [
  { label: "〜1億円", value: "〜1億円" },
  { label: "1〜10億円", value: "1〜10億円" },
  { label: "10〜100億円", value: "10〜100億円" },
  { label: "100〜500億円", value: "100〜500億円" },
  { label: "500〜1000億円", value: "500〜1000億円" },
  { label: "1000億円〜", value: "1000億円〜" },
];

export const region = [
  "北海道",
  "青森県",
  "岩手県",
  "宮城県",
  "秋田県",
  "山形県",
  "福島県",
  "茨城県",
  "栃木県",
  "群馬県",
  "埼玉県",
  "千葉県",
  "東京都",
  "神奈川県",
  "新潟県",
  "富山県",
  "石川県",
  "福井県",
  "山梨県",
  "長野県",
  "岐阜県",
  "静岡県",
  "愛知県",
  "三重県",
  "滋賀県",
  "京都府",
  "大阪府",
  "兵庫県",
  "奈良県",
  "和歌山県",
  "鳥取県",
  "島根県",
  "岡山県",
  "広島県",
  "山口県",
  "徳島県",
  "香川県",
  "愛媛県",
  "高知県",
  "福岡県",
  "佐賀県",
  "長崎県",
  "熊本県",
  "大分県",
  "宮崎県",
  "鹿児島県",
  "沖縄県",
];
