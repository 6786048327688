import React, { useEffect, useRef } from "react";
// import { Formik } from "formik";

import CloseModalButton from "../buttons/CloseModalButton";
// import Loader from "../buttons/Loader";
import { toast } from "react-toastify";

function Agreement({ setOpenAgreement, terms_condition, setTerms_condition, terms_ID }) {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpenAgreement(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setOpenAgreement]);

  const copyText = (project_id) => {
    if (terms_condition) { 
      navigator.clipboard.writeText(`${window.location.origin}/project-evalution/${project_id}`);
      toast.success("Link Copy Successfully.")
    }
    setTerms_condition(false)
    setOpenAgreement(false)
  }

  return (
    <div className="overlay">
      <div
        id="resetPassword-modal"
        tabIndex="-1"
        aria-hidden="true"
        className=" fixed z-50 inset-0 overflow-y-auto"
      >
        <div className="flex justify-center items-center h-full">
          <div
            className="bg-[#fff] w-[326px] md:w-[420px] lg:w-[500px]"
            ref={modalRef}
          >
            <div className="border-b border-[#CED0D8] mb-[24px] relative">
              <div className="mx-[50px] my-[16px]">
                <h3 className="text-sm md:text-[16px] tracking-[5%] font-bold text-[#212121] text-center h-[24px] leading-[24px]">
                  同意事項
                </h3>
                <CloseModalButton onClick={() => setOpenAgreement(false)} />
              </div>
            </div>
            <div className="">
              {/* <Formik>
                {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  values,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}> */}
              <div className="h-fit p-6 pt-0 overflow-y-auto">
                <div className="font-medium text-[14px] leading-[22px] text-[#212121] relative">
                  他人になりすます行為や偽の情報を提供することは、当社の利用規約に反する行為です。
                  このような行為が発覚した場合、アカウントの停止や法的措置を取る可能性があります。
                  利用規約に従い、正確かつ誠実に情報を提供してください。
                </div>
                <div className="mt-[24px] py-[12px] px-4">
                  <div className="cursor-pointer flex justify-center items-center">
                    <input
                      name="terms_condition"
                      type="checkbox"
                      defaultChecked={terms_condition}
                      onChange={() => setTerms_condition(!terms_condition)}
                      className="h-[20px] w-[20px] cursor-pointer mt-[2px]"
                    // onBlur={handleBlur}
                    />
                    <div className="flex justify-center ml-[8px] font-medium text-base text-black">
                      <span className="font-bold text-base text-[#0956FF]">
                        利用規約
                      </span>
                      に同意します
                    </div>
                  </div>
                </div>
                <div className="mt-4 flex justify-center px-4 md:px-0">
                  <button
                    type="button"
                    disabled={!terms_condition}
                    onClick={() => copyText(terms_ID)}
                    className={`w-[200px] h-[40px] ${terms_condition ? "bg-gradient-to-r from-[#0956FF] to-[#5389FF]" : "bg-neutral-500"} rounded-[4px] text-xs font-bold text-[#fff]`}
                  >
                    {/* {dataSubmitting ? (
                      <div className="flex justify-center">
                        <Loader />
                      </div>
                    ) : ( */}
                    確認
                    {/* )} */}
                  </button>
                </div>
              </div>
              {/* </form>
                )}
              </Formik> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Agreement;
