import { useEffect, useState, useRef } from "react";

import { Formik } from "formik";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";

import Data from "../../JSON/data.json";
import { affiliation_option, region } from "../comman/Options";
import userLoader from "../../Assets/Images/userLoader.png";
import CloseModalButton from "../buttons/CloseModalButton";
import { editProfileSchema } from "../validation/FormikValidation";
import { useUpdateUserMutation } from "../../Store/slice/AuthSlice";
import Loader from "../buttons/Loader";
import Deletemodal from "./Deletemodal";
import { scrollToErrors } from "../comman/ScrollToError";

function EditProfile({ setOpenEditProfile, user, setUser, refModal }) {
  const [updateuser, { error, isLoading }] = useUpdateUserMutation();
  const [delImg, setDelImg] = useState(false);
  const [image, setImage] = useState();
  const [selectedImg, seltSelectedImg] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const fileInputRef = useRef(null);

  if (error) {
    if (error?.originalStatus === 503) {
      toast.error("サービスは利用できません ！");
    } else {
      toast.error(error?.data?.message || "Something went wrong!");
    }
  }

  useEffect(() => {
    setImage(
      user?.avatar
        ? user?.avatar
        : ""
    );
  }, [user]);

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const handleDelete = async () => {
    setDelImg(true);
    seltSelectedImg("");
    setImage("");
    setModalOpen(false);
  };

  return (
    <div className="overlay">
      <div
        id="editUserName-modal"
        tabIndex="-1"
        aria-hidden="true"
        className=" fixed z-50 inset-0"
      >
        <div className="flex justify-center items-center h-full">
          <div
            className="bg-[#fff] overflow-y-auto w-[326px] md:w-[420px] lg:w-[500px]"
            ref={refModal}
          >
            <div className="border-b border-[#CED0D8] mb-[24px] relative">
              <div className="mx-[50px] my-[16px]">
                <h3 className="text-[14px] md:text-[16px] tracking-[5%] font-bold text-[#212121] text-center h-[24px] leading-[24px]">
                  名前を編集
                </h3>
                <CloseModalButton onClick={() => setOpenEditProfile(false)} />
              </div>
            </div>
            <div className="ml-[16px] md:ml-[22px] mr-[16px] md:mr-[22px] max-h-[600px] md:max-h-[1000px] overflow-y-auto mb-[24px]">
              <Formik
                initialValues={{
                  image: image ? image : "",
                  first_name: user?.first_name ? user.first_name : " ",
                  last_name: user?.last_name ? user.last_name : " ",
                  dob: user?.dob ? user?.dob : "",
                  affiliation: user?.affiliation ? user.affiliation : "",
                  region: user?.region ? user.region : "",
                  romanized_first_name: user?.romanized_first_name ? user?.romanized_first_name : "",
                  romanized_last_name: user?.romanized_last_name ? user?.romanized_last_name : "",
                }}
                validationSchema={editProfileSchema}
                innerRef={(ref) => {
                  if (ref?.errors && ref?.isSubmitting === true) {
                    scrollToErrors(ref?.errors);
                  }
                }}
                onSubmit={async (values, { setErrors, resetForm }) => {
                  const formData = new FormData();
                  formData.append("first_name", values.first_name);
                  formData.append("last_name", values.last_name);
                  formData.append("dob", values.dob);
                  formData.append("affiliation", values.affiliation);
                  formData.append("region", values.region);
                  formData.append("romanized_first_name", values?.romanized_first_name);
                  formData.append("romanized_last_name", values?.romanized_last_name);
                  if (delImg) {
                    formData.append("image_attributes[avatar]", "");
                    setDelImg(false);
                    setImage("");
                  }
                  if (selectedImg) {
                    formData.append("avatar", values?.image);
                  }

                  const response = await updateuser(formData);
                  if (response?.error) {
                    let filedErr = {};
                    response?.error?.data?.errors?.forEach((element) => {
                      filedErr[element.field] = element.detail;
                    });
                    setErrors(filedErr);
                    toast.error(
                      response?.error?.data?.message || "何か問題が発生しました"
                    );
                  }

                  if (response?.data?.success === true) {
                    setUser(response?.data?.data?.user);
                    setOpenEditProfile(false);
                    toast.success(
                      response?.data?.message ||
                        "プロファイルが正常に更新されました。"
                    );
                    resetForm();
                  }
                }}
              >
                {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  values,
                  setFieldValue,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit} className="h-[450px]">
                    <div className="flex justify-center items-center">
                      <div>
                        <img
                          src={
                            (selectedImg && selectedImg) ||
                            (delImg && userLoader) ||
                            image ||
                            userLoader
                          }
                          className="h-[160px] md:h-[170px] lg:h-[200px] w-[160px] md:w-[170px] lg:w-[200px] rounded-full"
                          alt="user Profile"
                        />
                        <div className="mt-[10px] lg:mt-[16px] w-[160px] md:w-[170px] lg:w-[200px]">
                          <div
                            className=" rounded-[4px] border border-[#212121] cursor-pointer"
                            onClick={handleFileInputClick}
                          >
                            <p className="text-[12px] font-bold text-center my-[11px] mx-auto leading-[18px]">
                              写真をアップロード
                            </p>
                          </div>
                          {errors.image && touched.image && (
                            <p className="text-red-600 text-[12px] ">
                              *{errors.image}
                            </p>
                          )}
                          {!delImg && (
                            <>
                              <p
                                className="text-[12px] font-normal text-center mt-[10px] lg:mt-[16px] cursor-pointer leading-[18px] text-transparent bg-clip-text bg-gradient-to-r from-[#0956FF] to-[#5389FF]"
                                onClick={() => {
                                  setModalOpen(true);
                                }}
                              >
                                写真を削除
                              </p>
                              {modalOpen && (
                                <Deletemodal
                                  heading={"写真を消去"}
                                  text={"本当に消去しますか？"}
                                  yes="削除"
                                  no="キャンセル"
                                  setModalOpen={setModalOpen}
                                  handleDelete={handleDelete}
                                />
                              )}
                            </>
                          )}

                          <input
                            name="image"
                            type="file"
                            accept="image/png, image/jpg, image/jpeg"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={(e) => {
                              setDelImg(false);
                              setFieldValue(`image`, e.target.files[0]);
                              setImage(e.target.files[0]);
                              if (e.target.files[0]) {
                                const imageURL = URL.createObjectURL(
                                  e.target.files[0]
                                );
                                seltSelectedImg(imageURL);
                              }
                            }}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                    </div>
                    <div className=" mt-[24px] lg:mt-[40px]">
                      <div className="text-[14px]">
                        <label
                          htmlFor="first_name"
                          className="font-medium leading-[21px] text-[#212121]"
                        >
                          氏名
                          <span className="text-red-500">*</span>
                        </label>
                        <div className="flex gap-[10px] mt-[8px]">
                          <div>
                            <input
                              name="first_name"
                              type="text"
                              placeholder=""
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.first_name}
                              className="w-full lg:w-[219px] h-[40px] lg:h-[48px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px]"
                            />
                            {errors.first_name && touched.first_name && (
                              <p className="text-red-600 text-[12px] ">
                                *{errors.first_name}
                              </p>
                            )}
                          </div>
                          <div>
                            <input
                              name="last_name"
                              type="text"
                              placeholder=""
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.last_name}
                              className="w-full lg:w-[219px] h-[40px] lg:h-[48px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px]"
                            />
                            {errors.last_name && touched.last_name && (
                              <p className="text-red-600 text-[12px] ">
                                *{errors.last_name}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="text-[14px] mt-[24px]">
                        <div className="flex justify-between">
                          <label
                            htmlFor="dob"
                            className="font-medium leading-[21px] text-[#212121]"
                          >
                            {Data?.date_of_birth}
                            <span className="text-red-500">*</span>
                          </label>
                          <label
                            htmlFor="affiliation"
                            className="font-medium leading-[21px] text-[#212121] mr-[42%]"
                          >
                            {Data?.affiliation}
                          </label>
                        </div>
                        <div className="flex gap-[10px] mt-[8px]">
                          <div>
                            {/* <input
                      name="dob"
                      type="date"
                      placeholder="生年月日を入力してください。"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.dob}
                      className="w-full lg:w-[219px] h-[40px] lg:h-[48px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px]"
                    /> */}
                            <DatePicker
                              name="dob"
                              selected={values?.dob}
                              dateFormat={"YYYY/MM/dd"}
                              maxDate={new Date()}
                              showYearDropdown
                              peekNextMonth
                              showMonthDropdown
                              dropdownMode="select"
                              onChange={(date) => {
                                const dateString = date;
                                const dates = new Date(dateString);
                                const formattedDate = dates
                                  ?.toISOString()
                                  .split("T")[0];
                                setFieldValue("dob", formattedDate);
                              }}
                              className="w-full lg:w-[219px] h-[40px] lg:h-[48px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px] outline-none"
                              placeholderText={Data?.date_of_birth}
                            />
                          </div>
                          <div>
                            <select
                              name="affiliation"
                              value={values.affiliation}
                              placeholder="所属を入力してください。"
                              onChange={handleChange}
                              className="w-full lg:w-[219px] h-[40px] lg:h-[48px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px] focus:outline-none focus:bg-transparent bg-transparent"
                              onBlur={handleBlur}
                              style={{ display: "block" }}
                            >
                              <option value="" label="所属を入力してください。">
                                所属を入力してください。
                              </option>
                              {affiliation_option?.map((option, index) => {
                                return (
                                  <option key={index} value={option?.value}>
                                    {option?.label}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="text-[14px] mt-[24px] ">
                          <label
                            htmlFor="region"
                            className="font-medium leading-[21px] text-[#212121]"
                          >
                            {Data?.region}
                          </label>
                          <div className="flex gap-[10px] mt-[8px]">
                            <select
                              name="region"
                              value={values.region}
                              onChange={handleChange}
                              className="w-full h-[49px] py-[6px] md:py-[10] lg:py-[11px] px-[10px] md:px-[14px] lg:px-[20px] border border-[#CED0D8] focus:outline-none focus:bg-transparent bg-transparent"
                              onBlur={handleBlur}
                              style={{ display: "block" }}
                            >
                              <option value="" label="地域を選択してください。">
                                地域を選択してください。
                              </option>
                              {region.map((ele, index) => (
                                <option key={index} value={ele}>
                                  {ele}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="text-[14px] mt-[24px]">
                        <label
                          htmlFor="name"
                          className="font-medium leading-[21px] text-[#212121]"
                        >
                          氏名(ローマ字)<span className="text-red-500">*</span>
                        </label>
                        <div className="flex gap-[10px] mt-[8px]">
                          <div>
                            <input
                              name="romanized_last_name"
                              type="text"
                              placeholder=""
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.romanized_last_name}
                              className="w-full lg:w-[219px] h-[40px] lg:h-[48px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px]"
                            />
                            {errors.romanized_last_name &&
                              touched.romanized_last_name && (
                                <p className="text-red-600 text-[12px] ">
                                  *{errors.romanized_last_name}
                                </p>
                              )}
                          </div>
                          <div>
                            <input
                              name="romanized_first_name"
                              type="text"
                              placeholder=""
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.romanized_first_name}
                              className="w-full lg:w-[219px] h-[40px] lg:h-[48px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px]"
                            />
                            {errors.romanized_first_name &&
                              touched.romanized_first_name && (
                                <p className="text-red-600 text-[12px] ">
                                  *{errors.romanized_first_name}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 md:mt-[40px]">
                      <div className="flex gap-[10px] items-center">
                        <button
                          type="button"
                          className="flex-1 h-[40px] py-[9px] px-auto font-normal text-[12px] leading-[22px] rounded-[4px] border border-[#CED0D8]"
                          onClick={() => setOpenEditProfile(false)}
                        >
                          キャンセル
                        </button>
                        <button
                          disabled={isLoading}
                          type="submit"
                          className={`w-[194px] md:w-[358px] h-[40px] ${isLoading
                              ? "bg-neutral-500"
                              : "bg-gradient-to-r from-[#0956FF] to-[#5389FF] "
                            } rounded-[4px] text-[12px] leading-[18px] font-bold  text-[#fff]`}
                        >
                          {isLoading ? (
                            <div className="flex justify-center">
                              <Loader />
                            </div>
                          ) : (
                            "保存する"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EditProfile;
