import { useEffect, useState } from "react";
import Data from "../../JSON/data.json";
import SidebarSvg from "../buttons/SidebarSvg";

function Sidebar() {
  const [active, setActive] = useState("section0");

  useEffect(() => {
    const handleScroll = () => {
      const pageYOffset = window.pageYOffset;
      let newActiveSection = null;
      const sections = document.querySelectorAll("section");

      sections.forEach((section) => {
        const sectionOffsetTop = section.offsetTop - 80;
        const sectionHeight = section.offsetHeight + 25;
        if (
          pageYOffset >= sectionOffsetTop &&
          pageYOffset < sectionOffsetTop + sectionHeight
        ) {
          newActiveSection = section.id;
        }
      });
      setActive(newActiveSection);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //  Temparory solution of null value.
  if (active == null) {
    setActive("section0");
  }

  return (
    <div className="sidebar-position w-full z-[10]">
      <div className="md:mt-[16px] w-full">
        <div className="bg-white h-[124px] md:h-auto w-full md:w-[190px] lg:w-[275px] xl:w-[315px] items-end flex md:block justify-end px-4 md:px-0">
          <ul className="sidebar-li flex flex-row md:flex-col mt-auto overflow-x-auto scrollbar-hide justify-between w-full">
            {Data?.sidebar?.map((item) => (
              <li
                key={item.id}
                className={`${
                  active === item.id
                    ? "border-b border-[#0956FF] md:border-[0px] md:bg-[#E6EEFF] text-[#0956FF]"
                    : "text-[#878787]"
                } px-[8px] md:px-[22px] xl:px-[40px] py-[8px] md:py-[14px] lg:py-[16px] xl:py-[20px] cursor-pointer flex justify-between items-center min-w-fit gap-1`}
                onClick={() => {
                  const offset =
                    document.getElementById(item.id).offsetTop - 80;
                  window.scroll({
                    top: offset,
                    behavior: "smooth",
                  });
                  setActive(item.id);
                }}
              >
                <span className="text-[14px] lg:text-[18px] font-bold leading-[28px] flex items-center gap-3 min-w-fit">
                  <span className="hidden md:block">
                  <SidebarSvg svg={item?.id} active={active} />
                  </span>
                  {item.label}
                </span>
                {active === item.id && <span className="dot hidden md:block"></span>}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Sidebar;
