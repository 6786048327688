import React, { createContext, useState } from "react";
const ContextData = createContext();

function ProviderData({ children }) {
  const [userProfile, setUserProfile] = useState("");
  const [userDetail, setUserDetails] = useState([]);
  const [tokenForLocal, setTokenForLocal] = useState(null);
  const [aiCall, setAiCall] = useState("");
  const [editJobSummaryData, setEditJobSummaryData] = useState("");
  const [addProjecthistoryData, setAddProjecthistoryData] = useState("");
  const [editProjecthistoryData, setEditProjecthistoryData] = useState("");

  return (
    <ContextData.Provider
      value={{
        userProfile,
        userDetail,
        tokenForLocal,
        aiCall,
        editJobSummaryData,
        addProjecthistoryData,
        editProjecthistoryData,
        setUserProfile,
        setUserDetails,
        setTokenForLocal,
        setAiCall,
        setEditJobSummaryData,
        setAddProjecthistoryData,
        setEditProjecthistoryData,
      }}
    >
      {children}
    </ContextData.Provider>
  );
}
export { ContextData, ProviderData };
