import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../Assets/Images/logo.png";
import SubmitButtonStroke from "../../Assets/icons/SubmitButtonStroke.svg";
import { useCallback, useEffect, useState } from "react";
import { useResendRegistreVerficationMutation } from "../../Store/slice/AuthSlice";
import { toast } from "react-toastify";
import Loader from "../../Components/buttons/Loader";

const UnderReview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [verificationMail, { isLoading }] = useResendRegistreVerficationMutation()
  const [counter, setCounter] = useState(30)

  const timeOutCallback = useCallback(
    () => setCounter((counter) => counter - 1),
    []
  );

  const sendVerificationMail = async (email) => {
    const response = await verificationMail({ 'email': email })

    if (response?.error) {
      toast.error(
        response?.error?.data?.errors[0] ||
        "何か問題が発生しました"
      );
    }
    if (response?.data?.success === true) {
      toast.success(
        response?.data?.message || "本人確認完了"
      );
      setCounter(30)
    }
  }

  useEffect(() => {
    if (!location?.state?.email) {
      navigate("/signup")
      return
    }
    counter > 0 && setTimeout(timeOutCallback, 1000);
    // eslint-disable-next-line
  }, [counter, timeOutCallback]);

  return (
    <div className="px-6 md:px-[16px] lg:px-[24px] md:py-[20px] lg:py-[64px] w-full md:w-fit lg:w-[458px] h-full">
      <div className="flex flex-col items-center h-[444px] lg:h-auto">
        <div className="flex flex-col justify-center items-center mt-10 md:mt-0">
          <img
            src={logo}
            alt="logo"
            className="w-[152px] h-[24px] flex justify-center"
          />
          <h1 className="leading-[150%] text-[24px] font-bold mt-[64px] md:mt-[18px] mb-4 md:mb-0">
            審査中
          </h1>
          <p className="flex gap-2 justify-center items-center font-normal text-[16px] leading-[22px] text-[#FF6F00]">
            <svg
              width="20"
              height="16"
              className="mt-[4px]"
              viewBox="0 0 17 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_775_19989)">
                <path
                  d="M15.4065 0H1.59403C1.17127 0 0.765821 0.171235 0.466882 0.476036C0.167942 0.780836 0 1.19424 0 1.62529V11.3753C0.000150254 11.8062 0.168159 12.2195 0.467082 12.5242C0.766005 12.8288 1.17137 13 1.59403 13H15.4065C15.8291 12.9998 16.2343 12.8286 16.5331 12.524C16.8319 12.2193 16.9998 11.8061 17 11.3753V1.62529C17 1.19434 16.8321 0.781025 16.5333 0.476241C16.2345 0.171456 15.8292 0.000153201 15.4065 0ZM1.59403 1.08333H15.4065C15.5474 1.08364 15.6824 1.14084 15.7821 1.24241C15.8817 1.34398 15.9378 1.48165 15.9381 1.62529V3.02698C15.211 3.65329 14.1718 4.51649 10.9378 7.13324C10.3762 7.5868 9.2701 8.67996 8.5 8.66667C7.7299 8.67996 6.62093 7.5868 6.06333 7.13324C2.82937 4.51649 1.7901 3.65329 1.06307 3.02698V1.62529C1.06337 1.48175 1.11938 1.34416 1.21888 1.24261C1.31837 1.14106 1.45325 1.08379 1.59403 1.08333ZM15.4065 11.9167H1.59403C1.45335 11.9162 1.31856 11.859 1.21908 11.7576C1.1196 11.6562 1.06352 11.5187 1.06307 11.3753V4.43502C1.82013 5.07058 3.01523 6.04644 5.4026 7.97969C6.0826 8.53493 7.28507 9.75693 8.50057 9.75C9.70927 9.7604 10.901 8.54822 11.5985 7.97969C13.9859 6.04644 15.181 5.06827 15.9381 4.43502V11.3753C15.9376 11.5188 15.8815 11.6564 15.7819 11.7578C15.6823 11.8592 15.5473 11.9164 15.4065 11.9167Z"
                  fill="#FF8C34"
                />
              </g>
              <defs>
                <clipPath id="clip0_775_19989">
                  <rect width="17" height="13" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {location?.state?.email}
          </p>
          <div className="mt-[40px] text-center text-[14px] lg:text-[16px]">
            <p>現在仮登録状態となっております。</p>
          </div>
          <div className="md:w-[298px] lg:w-[415px] mt-[16px] text-center text-[12px] lg:text-[16px]">
            <p>Vallie file運営より、仮登録時のメールアドレス宛に</p>
            <p>本人確認のためのご連絡をお送りしておりますので、</p>
            <p>ご確認とご対応をお願いいたします</p>
          </div>
          <div className="flex flex-col justify-center items-center mt-[16px] lg:mt-[30px]">
            <div className="md:w-[298px] lg:w-[415px] text-[14px] lg:text-sm text-center mb-3 text-[#878787]">
              <p>
                メールが届かない場合は、もう一度送信してみてください。
              </p>
            </div>
            <div className="flex">
              <button
                type="submit"
                disabled={counter > 0 || isLoading}
                onClick={() => sendVerificationMail(location?.state?.email)}
                className={`w-[159px] sm:w-[259px] md:w-[279px] lg:w-[396px] h-[60px] leading-6 font-medium ${isLoading ? "bg-neutral-500" : counter === 0 ? "bg-custom-gradient" : "bg-neutral-500"} text-[#fff]`}
              >
                {
                  isLoading ? <div className="flex justify-center"> <Loader /> </div> : counter === 0 ? "再送信" : `再送信 ${counter}`
                }
              </button>
              <img src={SubmitButtonStroke} alt="SubmitButtonStroke" />
            </div>
          </div>
          <div className="devider mt-10 md:mt-[16px] lg:mt-[20px] mb-10 md:mb-[16px] lg:mb-[20px] h-[1px] w-full md:w-[298px] lg:w-[415px] bg-[#D6DEE2] "></div>
          <div className="w-full md:w-[298px] lg:w-[415px] text-[12px] lg:text-[14px] text-[#878787]">
            <p>
              ※仮登録時のメールアドレス宛に、Vallie file運営からのメールが
              届かない場合は、お手数をおかけしますが{" "}
              <span className="text-[#3B89FF] underline">
                お問い合わせフォーム
              </span>
              よりお問い合わせください。
            </p>
          </div>
          <div className="w-full md:w-[298px] lg:w-[415px] mt-[16px] text-[12px] lg:text-[14px] text-[#878787]">
            <p>
              ※迷惑メールフォルダに振り分けられていることもありますので
              ご確認ください。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UnderReview;
