function SpecialityLoader() {
  return (
    <div>
      {[...Array(2)].map((abc, index) => (
        <div key={index}>
          <header className="mt-[16px] mb-[8px] bg-slate-200 w-[85px] h-[16px] animate-pulse rounded"></header>
          <div className="flex gap-2 flex-wrap animate-pulse">
            <div className=" px-[16px] py-[6px] bg-slate-200 w-[50px] h-[20px] rounded-full cursor-pointer"></div>
            <div className=" px-[16px] py-[6px] bg-slate-200 w-[70px] h-[20px] rounded-full cursor-pointer"></div>
            {index !== 0 && (
              <div className=" px-[16px] py-[6px] bg-slate-200 w-[70px] h-[20px] rounded-full cursor-pointer"></div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default SpecialityLoader;
