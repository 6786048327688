import { Link, useNavigate, useParams } from "react-router-dom";

import Data from "../../JSON/data.json";
import logo from "../../Assets/Images/logo.png";
import SubmitButtonStroke from "../../Assets/icons/SubmitButtonStroke.svg";
import { useEffect, useState } from "react";
import { useRegisterAuthenticationMutation } from "../../Store/slice/AuthSlice";
import { toast } from "react-toastify";
import UrlExpired from "./UrlExpired";

const VerificationComplete = () => {
  const dataLayer = window.dataLayer;
  const registrationToken = useParams("regToken")
  const navigate = useNavigate();
  const [authenticate] = useRegisterAuthenticationMutation()
  const [verificationComplete, setVerificationComplete] = useState('')

  if (!registrationToken?.regToken) {
    if (localStorage.getItem("token")) {
      navigate("/profile");
    }
  }

  const verification = async () => {
    const response = await authenticate(registrationToken?.regToken)

    if (response?.error) {
      toast.error(
        response?.error?.data?.errors[0] ||
        "何か問題が発生しました"
      );
      setVerificationComplete(false)
    }

    if (response?.data?.success === true) {
      toast.success(
        response?.data?.message || "本人確認完了"
      );
      dataLayer?.push({
        event: 'reg_complete',
        user: response?.data?.data?.email,
      });
      setVerificationComplete(true)
    }
  }
  
  useEffect(() => {
    verification()
    // eslint-disable-next-line
  }, [])

  return (
    verificationComplete === '' ? (
      <div className="px-[16px] lg:px-[24px] py-[20px] lg:py-[64px] w-[444px] min-w[444px] lg:w-[458px] h-full">
        <div className="flex flex-col items-center h-[444px] lg:h-auto lg:min-w[444px]">
          <div className="min-w-[159px] sm:min-w-[259px] md:min-w-[279px] lg:min-w-[396px] flex justify-center">
            <div>
              <img src={logo} alt="logo" className="animate-bounce" />
              <div className="loader_line" />
            </div>
          </div>
        </div>
      </div>
    )
      : verificationComplete === true ? (
        <div className="px-6 md:px-[16px] lg:px-[24px] md:py-[20px] lg:py-[30px] w-full md:w-[444px] lg:w-[458px] h-full">
          <div className="flex flex-col items-center h-[444px] lg:h-auto lg:min-w-[444px] w-full">
            <div className="flex flex-col justify-center items-center mt-10 md:mt-0 w-full">
              <img
                src={logo}
                alt="logo"
                className="w-[152px] h-[24px] flex justify-center"
              />
              <h1 h1 className="leading-[150%] text-[24px] md:text-[22px] lg:text-[34px] font-bold mt-[64px] md:mt-[18px] lg:mt-[18px] text-center">
                本人確認が <br /> 完了しました
              </h1>
              <div className="mt-[16px] md:mt-[40px] text-center text-[14px] lg:text-[16px]">
                <p>本人確認が完了いたしました。</p>
                <p>ログインし、Value fileを引き続きお楽しみください。</p>
              </div>
              <div className="flex flex-col justify-center items-center mt-10 md:mt-[16px] lg:mt-[40px] w-full">
                <Link className="flex w-full" to="/">
                  <button
                    type="submit"
                    className={`flex-1 md:flex-[unset] md:w-[279px] lg:w-[396px] h-[60px] leading-6 font-medium bg-custom-gradient text-[#fff]`}
                  >
                    {Data?.log_in_button}
                  </button>
                  <img src={SubmitButtonStroke} alt="SubmitButtonStroke" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <UrlExpired />
      )
  );
};
export default VerificationComplete;
