import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import { toast } from "react-toastify";

import MainLayout from "./Layouts/MainLayout";
import AuthLayout from "./Layouts/AuthLayout";

import Login from "./Pages/Login";
import UnderReview from "./Pages/Registration/UnderReview";
import VerificationComplete from "./Pages/Registration/VerificationComplete";
// import RegistrationComplete from "./Pages/Registration/RegistrationComplete";
// import UrlExpired from "./Pages/Registration/UrlExpired";
// import ForgetSuccess from "./Pages/ForgotPassword/ForgetSuccess";
// import PasswordLinkExpired from "./Pages/ForgotPassword/PasswordLinkExpired";

import Profile from "./Pages/Profile";
import OutputResume from "./Pages/OutputResume";
import PageNotFound from "./PageNotFound";
import ResetPasswordPage from "./Pages/ForgotPassword/ResetPasswordPage";
import PasswordLinkSent from "./Pages/ForgotPassword/PasswordLinkSent";
import SignUp from "./Pages/Registration";
import ForgotPassword from "./Pages/ForgotPassword";

import { useUpdateActiveTabMutation } from "./Store/slice/AuthSlice";
import { ContextData } from "./Store/Context";
import RegistrationProcess from "./Pages/RegistrationProcess";
import ProjectEvalution from "./Pages/ProjectEvalution";

export default function Router() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [isTabActive, setIsTabActive] = useState(true);
  const { setTokenForLocal } = useContext(ContextData);

  const [updateActiveTab, { error }] = useUpdateActiveTabMutation();

  useEffect(() => {
    window.addEventListener("visibilitychange", () => {
      setIsTabActive(document.visibilityState === "visible");
    });

    return () => {
      window.removeEventListener("visibilitychange", () => {
        setIsTabActive(document.visibilityState === "visible");
      });
    };
  }, []);

  console.log("*isTabActive", isTabActive);

  useEffect(() => {
    if (!isTabActive && token) handleActiveTab();
  }, [isTabActive]);

  async function handleActiveTab() {
    const response = await updateActiveTab();
  }
  useEffect(() => {
    if (error) {
      if (error?.originalStatus === 503) {
        toast.error("サービスは利用できません ！");
      } else {
        let err = [];
        error?.data?.errors?.forEach((item) => {
          const keys = Object.keys(item)[0];
          const value = Object.values(item)[0];
          err[keys] = value;
        });
        if (err.token_expired) {
          setTokenForLocal(null);
          localStorage.removeItem("token");
          navigate("/");
        } else {
          toast.error(error?.data?.message || "Something went wrong!");
          localStorage.removeItem("token");
          setTokenForLocal(null);
          navigate("/");
        }
      }
    }
  }, [error]);

  function handleAuth() {
    if (token) {
      return <Navigate to="/profile" />;
    } else {
      return <AuthLayout />;
    }
  }

  function handleMainLaytout() {
    if (token) {
      return <MainLayout />;
    } else {
      return <Navigate to="/" />;
    }
  }

  let element = useRoutes([
    {
      element: handleAuth(),
      children: [
        { path: "/", element: <Login /> },
        { path: "/signup", element: <SignUp /> },
        { path: "/under-review", element: <UnderReview /> },
        { path: "/verify-email/:regToken", element: <VerificationComplete /> },

        { path: "/forgot-password", element: <ForgotPassword /> },
        {
          path: "/forgot-resetpassword/:forgotToken",
          element: <ResetPasswordPage />,
        },
        { path: "/password-link-sent", element: <PasswordLinkSent /> },
      ],
    },
    {
      element: handleMainLaytout(),
      children: [
        { path: "/profile", element: <Profile /> },
        { path: "/output-resume", element: <OutputResume /> },
        // { path: "/project-evalution", element: <ProjectEvalution /> },


        {
          path: "/registration-process",
          element: <RegistrationProcess />,
        },
      ],
    },
    {
      path: "/project-evalution/:project_id",
      element: <ProjectEvalution />,
    },

    {
      path: "*",
      element: <PageNotFound />,
    },
  ]);

  return element;
}
