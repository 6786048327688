import { useContext, useEffect, useState } from "react";

import { toast } from "react-toastify";

import Address from "../../Assets/icons/Addressicon.svg";
import PlusIcon from "../../Assets/icons/plusIcon.svg";
import EditButton from "../buttons/EditButton";
import DeleteButton from "../buttons/DeleteButton";
import Deletemodal from "../modals/Deletemodal";
import EditWorkHistory from "../modals/EditWorkHistory";
import AddWorkHistory from "../modals/AddWorkHistory";
import WorkHistoryLoader from "../Loader/WorkHistoryLoader";
import Data from "../../JSON/data.json";
import { ContextData } from "../../Store/Context";
import { useDeleteWorkHistoryMutation } from "../../Store/slice/WorkSlice";

function WorkHistory({
  modalOpen,
  setModalOpen,
  openEditWorkHistory,
  setOpenEditWorkHistory,
  openWorkHistoryBio,
  setOpenWorkHistoryBio,
  refModal,
}) {
  const { userDetail, setUserDetails } = useContext(ContextData);
  const [deleteHistory, { isLoading }] = useDeleteWorkHistoryMutation();
  const [workHistory, setWorkHistory] = useState([]);
  const [id, setId] = useState();

  useEffect(() => {
    setWorkHistory(userDetail?.details?.work_histories);
  }, [userDetail]);

  const handleDelete = async () => {
    const response = await deleteHistory(id);
    if (response?.error?.data?.errors) {
      toast.error(response?.error?.data?.errors[0] || "Something went wrong!");
    }
    if (response?.data?.success === true) {
      const findIndex = workHistory.findIndex((a) => a.id === id);
      const updatedWorkHistory = [
        ...workHistory.slice(0, findIndex),
        ...workHistory.slice(findIndex + 1),
      ];
      setWorkHistory(updatedWorkHistory);
      setUserDetails({
        ...userDetail,
        details: { ...userDetail?.details, work_histories: updatedWorkHistory },
      });
      toast.success(response?.data?.message);
    }
    setModalOpen(false);
  };

  return (
    <section
      className="bg-white p-[10px] md:p-[14px] lg:p-[20px] mt-[16px] w-full"
      id="section4"
    >
      <div className="flex items-center justify-between mb-[12px] lg:mb-[16px] border-b">
        <h1 className="font-bold text-[16px] md:text-[18px] lg:text-[20px] pb-[8px]">
          職務経歴(所属経歴)
        </h1>
      </div>
      <div className="mt-[16px]">
        {userDetail?.isLoading ? (
          <WorkHistoryLoader />
        ) : workHistory?.length ? (
          workHistory?.map((item, index) => {
            return (
              <div
                key={index}
                className={`flex gap-[8px] ${
                  item !== workHistory && "mt-[16px]"
                }`}
              >
                <div className="flex-col justify-between hidden md:flex">
                  <h1 className="text-[14px] h-[22px] w-[79px] font-bold leading-[22px]">
                    {item?.start_date}
                  </h1>
                  <h1 className="text-[14px] h-[22px] font-bold leading-[22px]">
                    {item?.end_date}
                  </h1>
                </div>
                <div className="time-line my-[10px]"></div>
                <div className="flex flex-col gap-[16px] my-[11px] w-full">
                <h1 className="text-[14px] mt-[-10px] h-[22px] w-[79px] font-bold leading-[22px] block md:hidden">
                    {item?.start_date}
                  </h1>
                  <div>
                    <div>
                      <div>
                        <div className="flex justify-between">
                          <h1 className="flex items-center gap-[4px]">
                            <img
                              src={Address}
                              alt="Address"
                              className="h-[15px] w-[14px]"
                            />
                            <span className="text-[12px] lg:text-[14px] font-bold leading-[22px] text-[#212121]">
                              {item?.company_name}
                            </span>
                          </h1>
                          <div className="flex gap-3">
                            <EditButton
                              onClick={() => {
                                setOpenEditWorkHistory(true);
                                setId(item?.id);
                              }}
                            />
                            <DeleteButton
                              onClick={() => {
                                setModalOpen(true);
                                setId(item?.id);
                              }}
                            />
                            {modalOpen && (
                              <Deletemodal
                                heading={"履歴を削除"}
                                text="この履歴を削除してもよろしいですか ?"
                                yes="削除"
                                no="キャンセル"
                                setModalOpen={setModalOpen}
                                handleDelete={() => handleDelete()}
                                refModal={refModal}
                                isLoading={isLoading}
                              />
                            )}
                          </div>
                        </div>
                        <h1 className="flex items-center mt-[4px] gap-[4px]">
                          <img
                            src="/Assets/icons/timezoneIcon.svg"
                            alt="emp type icon"
                            className="h-[15px] w-[14px]"
                          />
                          <span className="text-[12px] font-normal text-[#878787] leading-[18px]">
                            {item?.employment_type}
                          </span>
                        </h1>
                        <h1 className="flex items-center mt-[4px] gap-[4px]">
                          <img
                            src="/Assets/icons/position.svg"
                            alt="img icon"
                            className="h-[15px] w-[14px]"
                          />
                          <span className="text-[12px] font-normal text-[#878787] leading-[18px]">
                            {item?.position}
                          </span>
                        </h1>
                        <h1 className="flex items-center mt-[4px] gap-[4px]">
                          <img
                            src="/Assets/icons/msgIcon.svg"
                            alt="img icon"
                            className="h-[15px] w-[14px]"
                          />
                          <span className="text-[12px] font-normal text-[#878787] leading-[18px]">
                            {item?.business_details}
                          </span>
                        </h1>
                      </div>
                    </div>
                    <div className="mt-[16px]">
                      <div className="pt-[4px] pr-[0] pb-[4px] pl-[0] flex md:w-[400px] items-center text-[12px] lg:text-[14px]">
                        <h1 className="text-[#878787] font-bold w-[56px] leading-[22px]">
                          売上高
                        </h1>
                        <p className="text-[#212121] font-normal ml-[16px] text-start leading-[22px]">
                          {item?.amount_of_sales}
                        </p>
                      </div>
                      <div className="pt-[4px] pr-[0] pb-[4px] pl-[0] flex md:w-[400px] items-center text-[12px] lg:text-[14px]">
                        <h1 className="text-[#878787] font-bold w-[56px] leading-[22px]">
                          上場
                        </h1>
                        <p className="text-[#212121] font-normal ml-[16px] text-start leading-[22px]">
                          {item?.listing_type}
                        </p>
                      </div>
                      <div className="pt-[4px] pr-[0] pb-[4px] pl-[0] flex md:w-[400px] items-center text-[12px] lg:text-[14px]">
                        <h1 className="text-[#878787] font-bold w-[56px] leading-[22px]">
                          従業員数
                        </h1>
                        <p className="text-[#212121] font-normal ml-[16px] text-start leading-[22px]">
                          {item?.number_of_employees}{" "}
                        </p>
                        <p className="text-[14px] ml-[8px] font-normal leading-[22px] text-[#212121]">
                          人
                        </p>
                      </div>
                      <div className="pt-[4px] pr-[0] pb-[4px] pl-[0] flex md:w-[400px]  items-center text-[12px] lg:text-[14px]">
                        <h1 className="text-[#878787] font-bold w-[56px] leading-[22px]">
                          業務内容
                        </h1>
                        <p className="text-[#212121] font-normal ml-[16px] text-start leading-[22px]">
                          {item?.description}
                        </p>
                      </div>
                    </div>
                  </div>
                  <h1 className="text-[14px] mb-[-10px] h-[22px] font-bold leading-[22px] block md:hidden">
                    {item?.end_date}
                  </h1>
                </div>
              </div>
            );
          })
        ) : (
          <div className="font-bold text-center">データなし</div>
        )}
        <div className="mt-[24px] flex justify-center ">
          <button
            type="button"
            className="flex bg-gradient-to-r from-[#0956FF] to-[#5389FF] py-[11px] w-[300px] h-[40px] justify-center gap-[4px] rounded-[4px]"
            onClick={() => {
              setOpenWorkHistoryBio(!openWorkHistoryBio);
            }}
          >
            <img src={PlusIcon} alt="PlusIcon" />
            <p className="text-white text-[12px] ml-[4px] font-bold leading-[18px]">
              {Data?.add_bio_button}
            </p>
          </button>
        </div>
      </div>
      {openEditWorkHistory && (
        <EditWorkHistory
          setOpenEditWorkHistory={setOpenEditWorkHistory}
          id={id}
          workHistory={workHistory}
          setWorkHistory={setWorkHistory}
          refModal={refModal}
        />
      )}
      {openWorkHistoryBio && (
        <AddWorkHistory
          setOpenWorkHistoryBio={setOpenWorkHistoryBio}
          workHistory={workHistory}
          setWorkHistory={setWorkHistory}
          refModal={refModal}
        />
      )}
    </section>
  );
}
export default WorkHistory;
