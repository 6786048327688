import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import logo from "../../Assets/Images/logo.png";
import user from "../../Assets/Images/userLoader.png";
import EmailIcon from "../../Assets/icons/email.svg";
import NotificationIcon from "../../Assets/icons/notification.svg";
import { useContext, useState } from "react";
import ResetPassword from "../modals/ResetPassword";
import { ContextData } from "../../Store/Context";
import { Auth } from "../../Store/slice/AuthSlice";
import { googleLogout } from '@react-oauth/google';

function Header({ userProfile }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const { setTokenForLocal } = useContext(ContextData);
  const [profilePic, setProfilePic] = useState(user);

  const handleLogOut = () => {
    dispatch(Auth.util.resetApiState());
    localStorage.removeItem("token");
    setTokenForLocal(null);
    googleLogout();
    navigate("/");
  };

  function checkImage(url) {
    var image = new Image();
    image.onload = function () {
      if (this.width > 0) {
        setProfilePic(url);
      }
    };
    image.onerror = function () {
      setProfilePic(user);
    };
    image.src = url;
    return true;
  }
  checkImage(userProfile?.avatar);

  return (
    <div className="px-[40px] bg-white w-full h-[64px] ">
      <div className="pt-[12px] flex justify-between items-center">
        <div className="w-[152px] md:w-[128px] h-[24px] md:h-[25px] mt-[3px] cursor-pointer">
          <img src={logo} alt="logo" onClick={() => navigate("/profile")} />
        </div>
        <div className="flex gap-4 md:gap-[30px] justify-between items-center">
          <Link to="https://forms.gle/e4DTi9oA9CJpDk4k9" target="_blank" >
            <div className="h-fit md:h-[39px] flex items-center flex-col">
              <img
                src={EmailIcon}
                alt="Email Icon"
                className="h-[24px] w-[24px] cursor-pointer"
              />
              <span className="hidden md:block text-[10px] text-[#868686]">お問い合わせ</span>
            </div>
          </Link>
          <div className="h-fit md:h-[39px] flex items-center flex-col">
            <img
              src={NotificationIcon}
              alt="Notification Icon"
              className="h-[24px] w-[24px] cursor-pointer"
            />
            <span className="hidden md:block text-[10px] text-[#868686]">Coming soon</span>
          </div>
          <div className="h-fit group">
            <img
              src={profilePic}
              alt="user"
              className="h-[40px] w-[40px] cursor-pointer rounded-full"
            />
            <div
              className="absolute hidden shadow-2xl
            group-hover:block group-hover:transform group-hover:opacity-100 group-hover:scale-100 group-hover:transition group-hover:ease-in group-hover:duration-75
            right-14 top-10 z-10 mt-2 w-52 origin-top-right rounded-md bg-white py-1 ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu-button"
              tabIndex="-1"
            >
              <button
                type="button"
                onClick={() => setOpenResetPassword(true)}
                className="block px-4 py-2 font-bold text-gray-700 w-full text-left  hover:text-[#0956FF]"
                role="menuitem"
                tabIndex="-1"
                id="user-menu-item-1"
              >
                パスワードを再設定する
              </button>
              <button
                type="button"
                onClick={handleLogOut}
                className="block px-4 py-2 font-bold text-gray-700 w-full text-left  hover:text-[#0956FF]"
                role="menuitem"
                tabIndex="-1"
                id="user-menu-item-2"
              >
                サインアウト
              </button>
            </div>
          </div>
        </div>
      </div>
      {openResetPassword && (
        <ResetPassword setOpenResetPassword={setOpenResetPassword} />
      )}
    </div>
  );
}
export default Header;
