import { Formik } from "formik";
import { useEffect, useState } from "react";
import noteIcon from "../Assets/icons/note.svg";
// import Agreement from "../Components/modals/Agreement";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../Assets/Images/logo.png";
import Footer from "../Components/comman/Footer";
import { useAddProjectConfirmationMutation, useGetProjectHistoryByIdPublicMutation } from "../Store/slice/ProjectSlice";
import PageLoader from "../Components/Loader/PageLoader";
import { toast } from "react-toastify";
import { ProjectVerification } from "../Components/validation/FormikValidation";
import Loader from "../Components/buttons/Loader";



const ProjectEvalution = () => {
  const { project_id } = useParams("project_id");
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState({});
  const [skillData, setSkillData] = useState(null)
  // const [openAgreement, setOpenAgreement] = useState(false);
  // const [terms_condition, setTerms_condition] = useState(false)
  const [dataSubmitting, setDataSubmitting] = useState(false)
  const [getProjectById, { error, isLoading }] = useGetProjectHistoryByIdPublicMutation();
  const [addProjectConfirmation] = useAddProjectConfirmationMutation();

  if (error) {
    if (error?.originalStatus === 503) {
      toast.error("サービスは利用できません ！");
    } else {
      toast.error(error?.data?.message || "Something went wrong!");
      navigate("/");
    }
  }

  const getProjectData = async () => {
    const response = await getProjectById(project_id);
    setProjectData(response?.data?.data?.project_history);
    setSkillData(
      response?.data?.data?.project_history?.related_skills?.map((skill) => {
        const key = skill.split(":")[0].replace("{", "").trim();
        const value = skill
          .split(":")[1]
          .trim()
          .replace("}", "")
          .replace("[", "")
          .replace("]", "")
          .trim()
          .split(",");
        return { [key]: value };
      }));
  };

  useEffect(() => {
    getProjectData()
    // eslint-disable-next-line
  }, [project_id])


  // useEffect(() => {
  //   if (openAgreement) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "unset";
  //   }
  // }, [openAgreement]);


  return (
    isLoading ? <PageLoader /> : (
      <div className="bg-[#F6F6F6]">
        <div className="px-[40px] bg-white w-full h-[67.5px] ">
          <div className="pt-[12px] flex justify-between items-center">
            <div className="w-[107px] h-[36px] mt-[3px] cursor-pointer">
              <img src={logo} alt="logo" onClick={() => navigate("/")} />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center text-black mb-24">
          <div className="mt-[24px] md:mt-[72px] max-w-[1005px] mx-auto">
            <div className="text-[#212121]">
              <h1 className="flex items-center justify-center font-bold text-[18px] md:text-[20px] lg:text-[24px] leading-[22px] ">
                プロジェクト評価
              </h1>
            </div>

            <Formik
              initialValues={{
                role: projectData?.project_history_feedback?.role || "エンドクライアント",
                email: projectData?.project_history_feedback?.email || "",
                message: projectData?.project_history_feedback?.message || ""
              }}
              validationSchema={ProjectVerification}
              enableReinitialize={true}
              onSubmit={async (values) => {
                setDataSubmitting(true)
                const data = {
                  "project_history_feedback": {
                    "role": values?.role,
                    "email": values?.email,
                    "message": values?.message,
                    "project_history_id": project_id
                  }
                }
                const response = await addProjectConfirmation(data)

                if (response?.error) {
                  toast.error(
                    response?.error?.data?.errors[0]?.detail ||
                    "何か問題が発生しました"
                  );
                  setDataSubmitting(false)
                }

                if (response?.data?.success === true) {
                  toast.success(
                    response?.data?.message || "変更は正常に保存されました"
                  );
                  setDataSubmitting(false)
                  navigate("/")
                }

                // setOpenAgreement(false)
              }}
            >
              {({ errors,
                touched,
                handleChange,
                handleBlur,
                values,
                handleSubmit }) => (
                <form className="mx-[16px]" onSubmit={handleSubmit}>
                  <div>
                    {/* Project details */}
                    <section className="bg-white mt-6 md:mt-[40px] py-6 px-3 md:p-[14px] lg:p-[20px]">
                      <div className="flex items-center justify-between pb-[4px] lg:pb-[8px] border-b">
                        <div>
                          <h1 className="font-bold pb-4 md:pb-0 text-[16px] md:text-[18px] lg:text-[20px]">
                            プロジェクト内容
                          </h1>
                        </div>
                      </div>
                      <div className="pr-[0] pl-[0] flex items-center gap-[16px] font-bold text-[12px] lg:text-[14px] mt-[11px]">
                        <h1 className="text-[#878787]"><span>{projectData?.start_date}</span> 〜 <span>{projectData?.end_date}</span></h1>
                      </div>
                      <div className="flex flex-col gap-[4px] my-[11px] w-full">
                        <div>
                          <div className="flex justify-between">
                            <h1 className="text-[12px] lg:text-[14px] font-bold leading-[22px] flex items-center gap-[4px]">
                              <img
                                src={noteIcon}
                                alt="noteIcon"
                                className="h-[15px] w-[14px]"
                              />
                              {/* <span>建設系装置メーカー SCM刷新プロジェクト</span> */}
                              <span>{projectData?.project_name}</span>
                            </h1>
                          </div>
                          <h1 className="flex items-center mt-[4px] gap-[4px]">
                            <img
                              src="/Assets/icons/userIcon.svg"
                              alt="companyIcon"
                              className="h-[15px] w-[14px]"
                            />
                            {/* <span className="text-[12px] font-normal text-[#878787] leading-[18px]">大手製造業</span> */}
                            <span className="text-[12px] font-normal text-[#878787] leading-[18px]">{projectData?.company}</span>
                          </h1>
                          <h1 className="flex items-center mt-[4px] gap-[4px]">
                            <img
                              src="/Assets/icons/position.svg"
                              alt="roleIcon"
                              className="h-[15px] w-[14px]"
                            />
                            {/* <span className="text-[12px] font-normal text-[#878787] leading-[18px] ">PM</span> */}
                            <span className="text-[12px] font-normal text-[#878787] leading-[18px] ">{projectData?.role}</span>
                          </h1>
                          <h1 className="flex items-center mt-[4px] gap-[4px]">
                            <img
                              src="/Assets/icons/Addressicon.svg"
                              alt="affiliationIcon"
                              className="h-[15px] w-[14px]"
                            />
                            {/* <span className="text-[12px] font-normal text-[#878787] leading-[18px] ">株式会社Plus Synergy</span> */}
                            <span className="text-[12px] font-normal text-[#878787] leading-[18px] ">{projectData?.affiliation}</span>
                          </h1>
                        </div>
                        <div>
                          <div className="mt-[16px] w-full">
                            <div>
                              <div className="pr-[0] pl-[0] flex items-center gap-[16px] font-bold text-[12px] lg:text-[14px]">
                                <h1 className="text-[#878787]">
                                  プロジェクト概要 :{" "}
                                </h1>
                              </div>
                              <div className="mt-[4px] pr-[0] pb-[4px] pl-[0] flex items-center gap-[16px] font-normal text-[12px] lg:text-[14px]">
                                {/* <h1 className="text-[#212121] leading-[22px] break-all">
                                ここにプロジェクト概要が入ります。ここにプロジェクト概要が入ります。ここにプロジェクト概要が入ります。ここにプロジェクト概要が入ります。ここにプロジェクト概要が入ります。ここにプロジェクト概要が入ります。
                              </h1> */}
                                <h1 className="text-[#212121] leading-[22px] break-all">{projectData?.project_overview}</h1>
                              </div>
                            </div>
                          </div>
                          <div className="mt-[16px]">
                            <div>
                              <h1 className="text-[#878787] text-[12px] lg:text-[14px] font-bold">
                                担当業務 :
                              </h1>
                              {
                                projectData?.responsibilities?.map((item, index) => (
                                  <h1 key={index} className="text-[12px] lg:text-[14px] font-normal text-[#212121] leading-[22px] flex items-center mt-[4px] gap-[4px] break-all">
                                    <img src="/Assets/icons/pinicon.svg" alt="icon" />
                                    <span>
                                      {item}
                                      {/* ここに担当した業務が入ります。ここに担当した業務が入ります。 */}
                                    </span>
                                  </h1>

                                ))
                              }
                              {/* <h1 className="text-[12px] lg:text-[14px] font-normal text-[#212121] leading-[22px] flex items-center mt-[4px] gap-[4px] break-all">
                              <img src="/Assets/icons/pinicon.svg" alt="icon" />
                              <span>
                                ここに担当した業務が入ります。ここに担当した業務が入ります。
                              </span>
                            </h1>
                            <h1 className="text-[12px] lg:text-[14px] font-normal text-[#212121] leading-[22px] flex items-center mt-[4px] gap-[4px] break-all">
                              <img src="/Assets/icons/pinicon.svg" alt="icon" />
                              <span>
                                ここに担当した業務が入ります。ここに担当した業務が入ります。
                              </span>
                            </h1> */}
                            </div>
                          </div>
                          <div className="mt-[16px] w-full">
                            <div>
                              <div className="pr-[0] mb-[4px] pl-[0] flex items-center font-bold text-[12px] lg:text-[14px]">
                                <h1 className="text-[#878787]">実績 / 取り組み</h1>
                              </div>
                              <div className="pr-[0] mb-[4px] pl-[0] flex items-center font-normal text-[12px] lg:text-[14px]">
                                <h1 className="text-[#212121] leading-[22px] break-all">
                                  {projectData?.achievements_initiatives}
                                  ここに実績 / 取り組みが入ります。ここに実績 /
                                  取り組みが入ります。ここに実績 /
                                  取り組みが入ります。ここに実績 /
                                  取り組みが入ります。ここに実績 /
                                  取り組みが入ります。ここに実績 /
                                  取り組みが入ります。ここに実績 /
                                  取り組みが入ります。

                                </h1>
                              </div>
                            </div>
                          </div>
                          <div className="mt-[16px]">
                            <div>
                              <h1 className="text-[#878787] text-[14px] font-bold leading-[22px]">
                                関連スキル :
                              </h1>
                            </div>
                            {skillData?.map((skill, index) => {
                              return (
                                <div key={index}>
                                  <h1 className="text-[12px] lg:text-[14px] font-normal leading-[22px] text-[#212121] mt-[4px]">
                                    {Object.keys(skill)}
                                  </h1>
                                  <div className="flex mt-[8px] gap-[8px] flex-wrap">
                                    {Object.values(skill)[0].map(
                                      (relatedSkill, idx) => (
                                        <div
                                          key={idx}
                                          className="font-normal text-[12px] lg:text-[14px] text-[#0956FF] px-[16px] py-[6px] bg-[#E6EEFF] w-[fit-content] rounded-full cursor-pointer"
                                        >
                                          {relatedSkill}
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                            {/* <div>
                            <h1 className="text-[12px] lg:text-[14px] font-normal leading-[22px] text-[#212121] mt-[4px]">
                              プロジェクト管理
                            </h1>
                            <div className="flex mt-[8px] gap-[8px] flex-wrap">
                              <div className="font-normal text-[12px] lg:text-[14px] text-[#0956FF] px-[16px] py-[6px] bg-[#E6EEFF] w-[fit-content] rounded-full cursor-pointer">
                                PM
                              </div>
                              <div className="font-normal text-[12px] lg:text-[14px] text-[#0956FF] px-[16px] py-[6px] bg-[#E6EEFF] w-[fit-content] rounded-full cursor-pointer">
                                PMO
                              </div>
                              <div className="font-normal text-[12px] lg:text-[14px] text-[#0956FF] px-[16px] py-[6px] bg-[#E6EEFF] w-[fit-content] rounded-full cursor-pointer">
                                プロジェクトサポート
                              </div>
                              <div className="font-normal text-[12px] lg:text-[14px] text-[#0956FF] px-[16px] py-[6px] bg-[#E6EEFF] w-[fit-content] rounded-full cursor-pointer">
                                補佐その他
                              </div>
                            </div>
                          </div>
                          <div>
                            <h1 className="text-[12px] lg:text-[14px] font-normal leading-[22px] text-[#212121] mt-[4px]">
                              ITプロジェクト管理
                            </h1>
                            <div className="flex mt-[8px] gap-[8px] flex-wrap">
                              <div className="font-normal text-[12px] lg:text-[14px] text-[#0956FF] px-[16px] py-[6px] bg-[#E6EEFF] w-[fit-content] rounded-full cursor-pointer">
                                IT
                              </div>
                              <div className="font-normal text-[12px] lg:text-[14px] text-[#0956FF] px-[16px] py-[6px] bg-[#E6EEFF] w-[fit-content] rounded-full cursor-pointer">
                                PMIT
                              </div>
                              <div className="font-normal text-[12px] lg:text-[14px] text-[#0956FF] px-[16px] py-[6px] bg-[#E6EEFF] w-[fit-content] rounded-full cursor-pointer">
                                PMOIT
                              </div>
                            </div>
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </section>

                    {/* Filled in by*/}
                    <section className="bg-white mt-6 md:mt-[40px] py-6 px-3 md:p-[14px] lg:p-[20px]">
                      <div className="flex items-center justify-between pb-[4px] lg:pb-[8px] border-b">
                        <div>
                          <h1 className="font-bold pb-4 md:pb-0 text-[16px] md:text-[18px] lg:text-[20px]">
                            記入者
                          </h1>
                        </div>
                      </div>
                      <div className="mt-[16px] md:mt-[12px] lg:mt-[16px]">
                        <div
                          role="group"
                          aria-labelledby="my-radio-group"
                          className="mt-[8px] flex items-center flex-wrap"
                        >
                          <label className="cursor-pointer mr-[24px] flex py-1 items-center text-sm md:text-base">
                            <input
                              name="role"
                              type="radio"
                              defaultValue="エンドクライアント"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              checked={values?.role === "エンドクライアント"}
                              disabled={projectData?.project_history_feedback}
                              className="mr-[8px] font-normal text-[12px] lg:text-[14px] text-[#212121]"
                            />
                            エンドクライアント
                          </label>
                          <label className="cursor-pointer mr-[24px] flex py-1 items-center text-sm md:text-base">
                            <input
                              name="role"
                              type="radio"
                              defaultValue="エージェント"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              checked={values?.role === "エージェント"}
                              disabled={projectData?.project_history_feedback}
                              className="mr-[8px] font-normal text-[12px] lg:text-[14px] text-[#212121]"
                            />
                            エージェント
                          </label>
                        </div>
                        {errors.role && touched.role && (
                          <p className="text-red-600 text-[12px] ml-[128px]">
                            *{errors.role}
                          </p>
                        )}
                      </div>
                    </section>

                    {/* email address*/}
                    <section className="bg-white mt-6 md:mt-[40px] py-6 px-3 md:p-[14px] lg:p-[20px]">
                      <div className="flex items-center justify-between pb-[12px] lg:pb-[16px] border-b">
                        <div>
                          <h1 className="font-bold pb-4 md:pb-0 text-[16px] md:text-[18px] lg:text-[20px]">
                            メールアドレス
                          </h1>
                        </div>
                      </div>
                      <div className="font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
                        <div className="mb-4 text-black">
                          <label htmlFor="project_name">
                            会社ドメインのメールアドレスをご入力ください
                          </label>
                        </div>
                        <input
                          name="email"
                          type="email"
                          value={values?.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={projectData?.project_history_feedback}
                          placeholder="メールアドレスを入力してください"
                          // value={values?.project_name}
                          className="h-[40px] md:h-[48px] w-full font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px] outline-none"
                        />
                      </div>
                      {errors.email && touched.email && (
                        <p className="text-red-600 text-[12px]">
                          *{errors.email}
                        </p>
                      )}
                    </section>

                    {/* Evaluation comment*/}
                    <section className="bg-white mt-6 md:mt-[40px] py-6 px-3 md:p-[14px] lg:p-[20px]">
                      <div className="flex items-center justify-between pb-[12px] lg:pb-[16px] border-b">
                        <div>
                          <h1 className="font-bold pb-4 md:pb-0 text-[16px] md:text-[18px] lg:text-[20px]">
                            評価コメント
                          </h1>
                        </div>
                      </div>
                      <div className="font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
                        <div className="mb-4 text-black">
                          <label htmlFor="project_name">
                            200文字程度でご入力ください
                          </label>
                        </div>
                        <div className="w-full border border-[#CED0D8]">
                          <textarea
                            name="message"
                            value={values?.message}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={projectData?.project_history_feedback}
                            placeholder="ここにメッセージを書いてください..."
                            className="text-[14px] font-normal leading-[22px] w-full h-fit min-h-[120px] resize-none outline-none  py-[8px] px-[16px]"
                          ></textarea>
                        </div>
                        {errors.message && touched.message && (
                          <p className="text-red-600 text-[12px]">
                            *{errors.message}
                          </p>
                        )}
                      </div>
                    </section>

                    {/* Submit button */}
                    <div className="mt-6 md:mt-[40px] flex justify-center px-4 md:px-0">
                      <button
                        // onClick={(e) => setOpenAgreement(true)}
                        type="submit"
                        disabled={projectData?.project_history_feedback || dataSubmitting}
                        className={`w-[226px] md:w-[265px] lg:w-[358px] h-[40px] ${projectData?.project_history_feedback || dataSubmitting ? "bg-neutral-500" : "bg-gradient-to-r from-[#0956FF] to-[#5389FF]"} rounded-[4px] text-xs font-bold text-[#fff]`}
                      >
                        {dataSubmitting ? (
                          <div className="flex justify-center">
                            <Loader />
                          </div>
                        ) : (
                          "送信"
                        )}
                      </button>
                    </div>
                  </div>
                  {/* {openAgreement && <Agreement 
                                        setOpenAgreement={setOpenAgreement} 
                                        terms_condition={terms_condition} 
                                        setTerms_condition={setTerms_condition} 
                                        dataSubmitting={dataSubmitting}
                                      />} */}
                </form>
              )}
            </Formik>
          </div>
        </div>
        <Footer />
      </div>
    )
  );
};

export default ProjectEvalution;
