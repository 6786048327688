import { useContext, useState } from "react";

import { Formik } from "formik";
import { toast } from "react-toastify";

import OpenChev from "../../Assets/icons/openChev.svg";
import PlusIcon from "../../Assets/icons/plusIcon-blue.svg";
import { skillsSchema } from "../validation/FormikValidation";
import CloseModalButton from "../buttons/CloseModalButton";
import Loader from "../buttons/Loader";
import Data from "../../JSON/data.json";
import { ContextData } from "../../Store/Context";
import { useUpdateUserDetailsMutation } from "../../Store/slice/AuthSlice";

function EditSkills({ skills, setOpenEditSkills, refModal }) {
  const [updateSkills, { error, isLoading }] = useUpdateUserDetailsMutation();

  const sample_text = Data?.Sample_skills_text;
  const [open, setOpen] = useState(false);
  const { setUserDetails } = useContext(ContextData);

  if (error) {
    if (error?.originalStatus === 503) {
      toast.error("サービスは利用できません ！");
    } else {
      toast.error(error?.data?.message || "Something went wrong!");
    }
  }

  return (
    <div className="overlay">
      <div
        id="skills-modal"
        tabIndex="-1"
        aria-hidden="true"
        className=" fixed z-50 inset-0 overflow-y-auto"
      >
        <div className="flex justify-center items-center h-full">
          <div className=" bg-[#fff] w-[326px] md:w-[516px] lg:w-[632px]" ref={refModal}>
            <div className="border-b border-[#CED0D8] mb-[24px] relative">
              <div className="mx-[50px] my-[16px]">
                <h3 className="text-sm ms:text-[16px] tracking-[5%] font-bold text-[#212121] text-center h-[24px] leading-[24px]">
                  活かせるスキルを編集
                </h3>
                <CloseModalButton onClick={() => setOpenEditSkills(false)} />
              </div>
            </div>
            <div className="mx-[16px] mb-[24px]">
              <Formik
                initialValues={{
                  skills: skills?.skills || [],
                }}
                validationSchema={skillsSchema}
                enableReinitialize={true}
                onSubmit={async (values, { resetForm }) => {
                  const data = {
                    skills: values?.skills?.filter((skill) => skill !== ""),
                  };
                  const response = await updateSkills(data);
                  if (response?.error) {
                    toast.error(
                      response?.error?.data?.errors[0]?.detail ||
                        "何か問題が発生しました"
                    );
                  }
                  if (response?.data?.success === true) {
                    setUserDetails({ details: response?.data?.data?.detail });
                    setOpenEditSkills(false);
                    toast.success(
                      response?.data?.message ||
                        "スキルが正常に更新されました。"
                    );
                    resetForm();
                  }
                }}
              >
                {({
                  handleBlur,
                  values,
                  handleSubmit,
                  setFieldValue,
                  errors,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="h-fit max-h-[410px] lg:max-h-[535px] overflow-y-auto mt-[24px]">
                      <div>
                        {values?.skills?.map?.((items, index) => {
                          return (
                            <div
                              key={index}
                              className="bg-[#F6F6F6] px-[12px] py-[16px] w-full max-h-[88px] mb-[16px]"
                            >
                              <p className="flex items-center gap-[9px]">
                                <input
                                  name={`skills.${index}`}
                                  type="text"
                                  placeholder=""
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `skills.${index}`,
                                      e.target.value
                                    );
                                  }}
                                  value={items}
                                  className="border text-[14px] font-normal w-full leading-[22px] text-[#212121] px-[16px] py-[8px] rounded-[4px] outline-none"
                                />
                              </p>
                              <div className="mt-[8px]">
                                <p
                                  className="text-transparent bg-clip-text bg-gradient-to-r from-[#0956FF] to-[#5389FF text-xs font-normal leading cursor-pointer"
                                  onClick={() => {
                                    const remove = values?.skills.filter(
                                      (ele) => ele !== items
                                    );
                                    setFieldValue("skills", remove);
                                  }}
                                >
                                  このスキルを削除する
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="flex justify-center ">
                        <button
                          type="button"
                          className="flex items-center border border-[#212121] py-2 md:py-[13px] px-4 md:px-[68px] w-fit h-[48px] rounded-[4px] gap-[4px]"
                          onClick={() =>
                            setFieldValue(
                              `skills.${[values?.skills?.length]}`,
                              ""
                            )
                          }
                        >
                          <img
                            src={PlusIcon}
                            alt="PlusIcon"
                            className="h-[12px] w-[12px]"
                          />
                          <p className="text-[#212121] text-[14px] font-bold leading-[22px]">
                            スキルを追加する
                          </p>
                        </button>
                      </div>
                      <div className="mt-[24px]">
                        <button
                          onClick={() => setOpen(!open)}
                          type="button"
                          className="flex items-center gap-[8px] bg-[#E6EEFF] w-full h-[46px] pt-[12px] pr-[16px] pb-[12px] pl-[16px]"
                        >
                          {open ? (
                            <img
                              src={OpenChev}
                              alt="cheav"
                              className="transform rotate-[180deg]"
                            />
                          ) : (
                            <img src={OpenChev} alt="cheav" />
                          )}
                          <p className="text-[14px] font-normal leading-[22px] text-[#212121]">
                            サンプル記入例
                          </p>
                        </button>
                        {open && (
                          <div className="text-[14px] font-normal leading-[22px]">
                            {sample_text?.map((text, index) => {
                              return (
                                <p
                                  key={index}
                                  className="flex items-center gap-[9px] mt-2"
                                >
                                  <input
                                    type="text"
                                    disabled
                                    value={text}
                                    className="text-[14px] font-normal w-full leading-[22px] bg-[#E6EEFF] px-[16px] py-[8px] rounded-[4px] outline-none"
                                  />
                                </p>
                              );
                            })}
                          </div>
                        )}
                      </div>
                      <div className="mt-[24px] md:mt-[40px]">
                        <div className="flex gap-[10px] items-center justify-center"> 
                          <button
                            type="button"
                            className="flex-1 h-[40px] py-[9px] px-auto font-normal text-xs rounded-[4px] border border-[#CED0D8]"
                            onClick={() => setOpenEditSkills(false)}
                          >
                            キャンセル
                          </button>
                          <button
                            type="submit"
                            disabled={isLoading}
                            className={`w-[194px] md:w-[358px] h-[40px] ${
                              isLoading
                                ? "bg-neutral-500"
                                : " bg-gradient-to-r from-[#0956FF] to-[#5389FF]"
                            } rounded-[4px] text-xs font-bold text-[#fff]`}
                          >
                            {isLoading ? (
                              <div className="flex justify-center">
                                <Loader />
                              </div>
                            ) : (
                              "保存する"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EditSkills;
