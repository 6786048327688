import { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Formik } from "formik";
import { toast } from "react-toastify";

import Data from "../JSON/data.json";
import logo from "../Assets/Images/logo.png";
import SubmitButtonStroke from "../Assets/icons/SubmitButtonStroke.svg";
import Loader from "../Components/buttons/Loader";
import { scrollToErrors } from "../Components/comman/ScrollToError";
import { loginSchema } from "../Components/validation/FormikValidation";
import { ContextData } from "../Store/Context";
import {
  Auth,
  useGoogleLoginProcessMutation,
  useLoginUserMutation,
} from "../Store/slice/AuthSlice";
import PassHideShow from "../Components/buttons/PassHideShow";
import { GoogleLogin } from "@react-oauth/google";

const Login = () => {
  const navigate = useNavigate();
  const [getLoginUser, { error, isLoading }] = useLoginUserMutation();
  const [trigger] = Auth.endpoints.GetUserProfile.useLazyQuery();
  const { setTokenForLocal, tokenForLocal } = useContext(ContextData);
  const [pass, setPass] = useState(false);
  const [googleLoginProcess, { error: googleError }] = useGoogleLoginProcessMutation();
  const [termsCondition, setTermsCondition] = useState(false);
  const handleToggle = () => {
    setPass(!pass);
  };

  if (googleError) {
    if (googleError.originalStatus === 503) {
      toast.error("サービスは利用できません ！");
    } else {
      toast.error(googleError.data?.message || "Something went wrong!");
    }
  }

  if (error) {
    if (error?.originalStatus === 503) {
      toast.error("サービスは利用できません ！");
    }
  }
  if (error) {
    var err = {};
    error?.data?.errors?.forEach((element) => {
      err = element.user_authentication;
    });
    toast.error(error?.data?.message || err || "Something went wrong!");
  }

  useEffect(() => {
    const getProfile = async () => {
      if (tokenForLocal) {
        await trigger();
        localStorage.setItem("token", tokenForLocal);
        navigate("/profile");
      }
    };
    getProfile();
    // eslint-disable-next-line
  }, [tokenForLocal]);

  return (
    <div className="px-6 md:px-[16px] lg:px-[24px] md:py-[20px] lg:py-[44px] w-full md:w-fit lg:w-[458px] h-full">
      <div className="flex flex-col items-center h-full md:h-[444px] lg:h-auto">
        <div className="flex flex-col justify-center w-full md:w-[unset] items-center mt-10 md:mt-0">
          <img
            src={logo}
            alt="logo"
            className="w-[152px] h-[24px] flex justify-center"
          />
          <h1 className="leading-[100%] text-[24px] md:text-[28px] lg:text-[40px] font-bold mt-[64px] mb-[24px] md:my-[18px] lg:my-[20px]">
            {Data?.Login}
          </h1>
        </div>
        <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={loginSchema}
            innerRef={(ref) => {
              if (ref?.errors && ref?.isSubmitting === true) {
                scrollToErrors(ref?.errors);
              }
            }}
            onSubmit={async (values, { setErrors, resetForm }) => {
              const userData = {
                email: values.email,
                password: values.password,
              };
              const response = await getLoginUser(userData);

              if (response?.error?.data?.errors) {
                response?.error?.data?.errors?.forEach((element) => {
                  setErrors(element);
                  // err[element.field] = element.detail;
                });
                document.getElementsByName("email")[0].focus();
              }
              if (response?.data?.success === true) {
                localStorage.setItem(
                  "token",
                  response?.data?.data?.response?.token
                );
                setTokenForLocal(response?.data?.data?.response?.token);
                navigate("/profile");
                resetForm();
                toast.success(
                  response?.data?.message || "ログインに成功しました。"
                );
              }
            }}
          >
            {({
              errors,
              touched,
              handleChange,
              handleBlur,
              values,
              handleSubmit,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit} className="w-full md:w-[unset]">
                <div className="mb-10 md:mb-[20px] lg:mb-[20px] overflow-hidden">
                  <div className="mb-[16px] md:mb-[24px]">
                    <label
                      htmlFor="email"
                      className="text-[14px] font-medium leading-[150%] text-[#212121]"
                    >
                      {Data?.email_address}
                    </label>
                    <div className="flex mt-[4px] md:mt-[6px] lg:mt-[10px] items-center relative">
                      <input
                        name="email"
                        type="email"
                        placeholder={Data?.email_placeholder}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        className="custom-input w-full p-[12px] box-border outline-none bg-white relative z-[1] focus:outline-none focus:bg-transparent"
                      />
                      <div className="custom-border"></div>
                    </div>
                    {((errors.email && touched.email) ||
                      errors?.user_authentication) && (
                        <p className="text-red-600 text-[12px]">
                          *{errors.email || errors.user_authentication}
                        </p>
                      )}
                  </div>
                  <div>
                    <div>
                      <label
                        htmlFor="password"
                        className="text-[14px] font-medium leading-[150%] text-[#212121]"
                      >
                        {Data?.password}
                      </label>
                    </div>
                    <div className="flex mt-[4px] md:mt-[6px] lg:mt-[10px] items-center relative">
                      <input
                        name="password"
                        type={pass ? "text" : "password"}
                        placeholder={Data?.Password_placeholder}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        className="border border-[#CED0D8] w-full p-[12px] pr-[45px] box-border outline-none bg-white relative z-[1] focus:outline-none focus:bg-transparent"
                      />
                      <div className="custom-border-pass"></div>
                      <div
                        className="absolute top-[33%] right-[5%] z-[99999]"
                        onClick={handleToggle}
                      >
                        <PassHideShow ShowHide={pass} />
                      </div>
                    </div>
                    {errors.password && touched.password && (
                      <p className="text-red-600 text-[12px]">
                        *{errors.password}
                      </p>
                    )}
                    <div className="flex justify-end mt-[4px] md:mt-[6px] lg:mt-[8px]">
                      <Link
                        to="/forgot-password"
                        className="font-normal text-[12px] leading-[18px] text-[#3B89FF]"
                      >
                        {Data?.reset_password}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center">
                  <div className="flex w-full md:w-[unset]">
                    <button
                      disabled={isLoading}
                      type="submit"
                      className={`w-full flex-1 md:flex-[unset] md:w-[270px] lg:w-[387px] h-[60px] leading-6 font-medium ${isLoading ? "bg-neutral-500" : "bg-custom-gradient"
                        } text-[#fff]`}
                    >
                      {isLoading ? (
                        <div className="flex justify-center">
                          <Loader />
                        </div>
                      ) : (
                        Data?.log_in_button
                      )}
                    </button>
                    <img src={SubmitButtonStroke} alt="SubmitButtonStroke" />
                  </div>
                  <Link to="/signup" className="flex justify-center mt-[10px]">
                    <p className="font-bold text-[16px] text-[#212121] leading-[16px]">
                      {Data?.New_registration}
                      <span className="text-[#FF6F00] underline"> こちら </span>
                    </p>
                  </Link>
                </div>
                <div className="mt-[10px]">
                  <div className="cursor-pointer flex justify-center items-center">
                    <label>
                      <input
                        name="terms_condition"
                        type="checkbox"
                        className="h-[18px] w-[18px] cursor-pointer mt-[14px]"
                        onBlur={handleBlur}
                        onChange={(e) => { setFieldValue("terms_condition", e.target.checked); setTermsCondition(!termsCondition) }} />
                    </label>
                    <div className="flex justify-center mt-[14px] ml-[8px] font-normal text-[10px] lg:text-[12px] leading-[22px]">
                      <Link
                        to="https://torpid-apparel-d15.notion.site/ab85786367ed47958f98950a60a6b91f"
                        target="_blank"
                      >
                        <div className="text-[#FF6F00] underline">
                          利用規約
                        </div>
                      </Link>
                      ・{" "}
                      <Link
                        to="https://torpid-apparel-d15.notion.site/3e962470eabd48d5a2c9ccc7e773a809"
                        target="_blank"
                        className="underline text-[#FF6F00] "
                      >
                        プライバシーポリシー
                      </Link>
                      ・{" "}
                      <Link
                        to="https://torpid-apparel-d15.notion.site/27a28c163e7048a9930fc20d22d0bc10?pvs=25"
                        target="_blank"
                      >
                        <span className="text-[#FF6F00] underline">
                          外部送信ポリシー
                        </span>
                        <span className="text-[#FF6F00]">に同意する</span>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="w-full mt-[10px] py-[14px]">
                  {
                    !termsCondition ? (
                      <div>
                        <button disabled className="py-2 px-2 max-w-[401px] mx-auto md:max-w-[unset] w-full md:w-[401px] flex items-center gap-3 bg-white border border-[#DADCE0] justify-center rounded-[4px]">
                          <svg viewBox="0 0 48 48" className="LgbsSe-Bz112c w-4 h-4"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
                          <span className="text-[12px]">Sign up with Google</span>
                        </button>
                        <p className="text-[12px] text-center">チェックボックスを押すとGoogleアカウントで登録ができます</p>
                      </div>
                    ) : (
                      <GoogleLogin
                        width={"401px"}
                        size="large"
                        onSuccess={async (credentialResponse) => {
                          const response = await googleLoginProcess(
                            credentialResponse.credential
                          );
                          if (response?.data) {
                            localStorage.setItem("token", response?.data?.jwt?.token);
                            toast.success("ログインに成功しました。");
                            navigate("/profile");
                          }
                          if (response?.error) {
                            toast.error(response?.error?.error);
                          }
                        }}
                        onError={() => {
                          toast.error("ログインに失敗しました");
                        }}
                      />
                    )
                  }
                </div>
              </form>
            )}
        </Formik>
      </div>
    </div>
  );
};
export default Login;
