import React, { useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

const RegistrationSteps = ({
  userstep,
  setUserStep,
  subStep,
  setSubStep,
  userProfile,
  setUserProfile,
  userDetail,
  setUserDetails,
}) => {
  const [currentStep, setCurrentStep] = useState(userstep || 1);

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  return (
    <div
      className={`${
        currentStep === 3 ? "max-w-[1005px]" : "max-w-[800px]"
      } mx-auto my-6 md:my-[64px] h-fit flex flex-col items-center`}
    >
      {/***********Steps***********/}
      <div className="steps w-fit mb-10 md:mb-16">
        <h1
          className="text-[16px] lg:text-[24px] font-bold text-center mb-6"
          autoFocus={true}
        >
          職務経歴書作成
        </h1>
        <div className="flex gap-2 md:gap-4 lg:gap-6 justify-center items-center">
          {[1, 2, 3, 4].map((step) => (
            <React.Fragment key={step}>
              <div
                className={`w-[72px] h-[72px] lg:h-[100px] lg:w-[100px] p-2 rounded-full flex justify-center items-center flex-col ${
                  step <= userstep && "cursor-pointer"
                } ${
                  currentStep === step
                    ? "bg-[#0956FF] text-white"
                    : step <= userstep
                    ? "bg-white text-[#0956FF]"
                    : "bg-white text-[#CED0D8]"
                }`}
                onClick={() => step <= userstep && handleStepClick(step)}
              >
                <p className="text-xs font-bold">step {step}</p>
                <span className="text-xs lg:text-base font-bold text-center">
                  {step === 1
                    ? "ユーザー情報"
                    : step === 2
                    ? "入力"
                    : step === 3
                    ? "出力"
                    : "完了"}
                </span>
              </div>
              {step < 4 && (
                <div className="divider w-2 md:w-8 lg:w-16 h-1 bg-gradient-to-r from-[#0956FF] to-[#5389FF] rounded-full"></div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      {/***********Step Components***********/}
      {currentStep === 1 && (
        <Step1
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          userProfile={userProfile}
          setUserProfile={setUserProfile}
          setUserStep={setUserStep}
        />
      )}
      {currentStep === 2 && (
        <Step2
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          subStep={subStep}
          setSubStep={setSubStep}
          userDetail={userDetail}
          setUserDetails={setUserDetails}
          setUserStep={setUserStep}
        />
      )}
      {currentStep === 3 && (
        <Step3
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          setUserStep={setUserStep}
        />
      )}
      {currentStep === 4 && <Step4 />}
    </div>
  );
};

export default RegistrationSteps;
