import { useContext, useEffect, useState } from "react";

import { toast } from "react-toastify";

import Loader from "../buttons/Loader";
import CloseModalButton from "../buttons/CloseModalButton";
import { useUpdateUserDetailsMutation } from "../../Store/slice/AuthSlice";
import Data from "../../JSON/data.json";
import { ContextData } from "../../Store/Context";

function EditSpecialty({
  specialityData,
  setSpecialityData,
  openEditSpeciality,
  setOpenEditSpeciality,
  refModal,
}) {
  const speciality = Data?.specialty;
  const [selectedItems, setSelectedItems] = useState([]);
  const { setUserDetails } = useContext(ContextData);
  const [updateSpeciality, { error, isLoading }] =
    useUpdateUserDetailsMutation();

  if (error) {
    if (error?.originalStatus === 503) {
      toast.error("サービスは利用できません ！");
    } else {
      toast.error(error?.data?.message || "Something went wrong!");
    }
  }

  useEffect(() => {
    const result = specialityData.reduce((acc, curr) => {
      const [key] = Object.keys(curr);
      const values = curr[key];
      return [
        ...acc,
        ...values.map((value) => {
          return { category: key, sm: value };
        }),
      ];
    }, []);
    setSelectedItems(result);
  }, [specialityData]);

  const handleClick = (category, sm) => {
    const selectedItem = { category, sm };
    const isSelected = selectedItems.some(
      (item) => item.category === category && item.sm === sm
    );
    if (isSelected) {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter(
          (item) => !(item.category === category && item.sm === sm)
        )
      );
    } else {
      setSelectedItems((prevSelectedItems) => [
        ...prevSelectedItems,
        selectedItem,
      ]);
    }
  };

  const handleSubmit = async () => {
    function selectedResult() {
      var categories = [];
      function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
      }
      var a = selectedItems.map((item) => item.category);
      var unique = a.filter(onlyUnique);

      unique.forEach((cat) => {
        let val = [];
        selectedItems.forEach((item) => {
          if (cat === item.category) {
            val.push(item.sm);
          } else {
            return null;
          }
        });
        categories = [...categories, { [cat]: val }];
      });
      return categories;
    }
    const data = selectedResult();
    const specialities = {
      specialities: data.map((obj) => {
        const key = Object.keys(obj)[0];
        const value = obj[key];
        return `{ ${key}: [${value.map((val) => `${val}`)}] }`;
      }),
    };

    const response = await updateSpeciality(specialities);

    if (response?.error) {
      toast.error(
        response?.error?.data?.errors[0]?.detail || "変更は正常に保存されました"
      );
    }

    if (response?.data?.success === true) {
      setUserDetails({ details: response?.data?.data?.detail });
      const specialityres = response?.data?.data?.detail?.specialities?.map(
        (skill) => {
          const key = skill.split(":")[0].replace("{", "").trim();
          const value = skill
            .split(":")[1]
            .trim()
            .replace("}", "")
            .replace("[", "")
            .replace("]", "")
            .trim()
            .split(",");
          return { [key]: value };
        }
      );
      setSpecialityData(specialityres);
      toast.success(
        response?.data?.message ||
          "スペシャライゼーションが正常に更新されました。"
      );
      setOpenEditSpeciality(false);
    }
  };

  return (
    <div className="overlay">
      <div
        id="speciality-modal"
        tabIndex="-1"
        aria-hidden="true"
        className=" fixed z-50 inset-0 overflow-y-auto"
      >
        <div className="flex justify-center items-center h-full">
          <div className=" bg-[#fff] w-[326px] md:w-[632px]" ref={refModal}>
            <div className="border-b border-[#CED0D8] mb-[24px] relative">
              <div className="mx-[50px] my-[16px]">
                <h3 className="text-sm md:text-[16px] tracking-[5%] font-bold text-[#212121] text-center h-[24px] leading-[24px]">
                  得意分野を編集
                </h3>
                <CloseModalButton
                  onClick={() => setOpenEditSpeciality(false)}
                />
              </div>
            </div>
            <div className="mt-[24px] mx-[16px] mb-[24px] relative">
              <div className="h-[360px] md:h-[400px] lg:h-[585px] overflow-y-auto">
                {speciality?.map((items) => {
                  return (
                    <div key={items?.id} className="mb-[24px]">
                      <h1 className="text-[16px] leading-[24px] font-bold text-[#878787] mb-[8px]">
                        {items?.category}
                      </h1>
                      <div className="flex gap-[8px] items-center flex-wrap">
                        {items?.subcategories?.map((category) => {
                          const isSelected = selectedItems.some(
                            (item) =>
                              item.category === items?.category &&
                              item.sm === category?.sm
                          );
                          return (
                            <p
                              onClick={() =>
                                handleClick(items?.category, category?.sm)
                              }
                              key={category?.id}
                              className={`text-[14px] cursor-pointer font-normal leading-[22px] py-[6px] rounded-full px-[16px] ${
                                isSelected
                                  ? `text-[#0956FF] bg-[#E6EEFF] border border-[#0956FF]`
                                  : "border-0 bg-[#F6F6F6] text-[#878787]"
                              } w-[fit-content] rounded-full`}
                            >
                              {category?.sm}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
                <div className="mt-[-4px] lg:mt-[16px">
                  <div className="flex gap-[10px] items-center justify-center">
                    <button
                      type="button"
                      className="flex-1 h-[40px] py-[9px] px-auto font-normal text-xs rounded-[4px] border border-[#CED0D8]"
                      onClick={() => setOpenEditSpeciality(!openEditSpeciality)}
                    >
                      キャンセル
                    </button>
                    <button
                      type="submit"
                      disabled={isLoading || !selectedItems?.length}
                      className={`w-[194px] md:w-[358px] h-[40px] ${
                        isLoading || !selectedItems?.length
                          ? "bg-neutral-500"
                          : " bg-gradient-to-r from-[#0956FF] to-[#5389FF]"
                      } rounded-[4px] text-xs font-bold text-[#fff]`}
                      onClick={() => handleSubmit()}
                    >
                      {isLoading ? (
                        <div className="flex justify-center">
                          <Loader />
                        </div>
                      ) : (
                        "保存する"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EditSpecialty;
