import { useContext, useEffect, useState } from "react";

import { Formik } from "formik";
import { toast } from "react-toastify";

import Data from "../../JSON/data.json";
import Loader from "../buttons/Loader";
import { ContextData } from "../../Store/Context";
import OpenChev from "../../Assets/icons/openChev.svg";
import AI from "../../Assets/icons/ai.svg";
import { summarySchema } from "../validation/FormikValidation";
import CloseModalButton from "../buttons/CloseModalButton";
import { useUpdateUserDetailsMutation } from "../../Store/slice/AuthSlice";
import { scrollToErrors } from "../comman/ScrollToError";

function EditJobSummary({ data, setOpenJobSummary, refModal, setOpenAIModal }) {
  const jobSummary = Data?.edit_jobSummary;
  const { editJobSummaryData, setEditJobSummaryData } = useContext(ContextData);
  const [open, setOpen] = useState(false);
  const { setUserDetails } = useContext(ContextData);

  const [updateJobSummary, { error, isLoading }] =
    useUpdateUserDetailsMutation();

  if (error) {
    if (error?.originalStatus === 503) {
      toast.error("サービスは利用できません ！");
    } else {
      toast.error(error?.data?.message || "Something went wrong!");
    }
  }

  useEffect(() => {
    if (open === true) {
      const element = document.getElementById("content");
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [open]);

  return (
    <div className="overlay">
      <div
        id="jobSummary-modal"
        tabIndex="-1"
        aria-hidden="true"
        className=" fixed z-50 inset-0 overflow-y-auto"
      >
        <div className="flex justify-center items-center h-full">
          <div className=" bg-[#fff] w-[326px] md:w-[500px] md:m-4" ref={refModal}>
            <div className="border-b border-[#CED0D8] relative">
              <div className="mx-[50px] my-[16px]">
                <h3 className="text-[14px] md:text-[16px] tracking-[5%] font-bold text-[#212121] text-center h-[24px] leading-[24px]">
                  {jobSummary?.title}
                </h3>
                <CloseModalButton
                  onClick={() => {
                    setOpenJobSummary(false);
                    setEditJobSummaryData("");
                  }}
                />
              </div>
            </div>

            <div className="mx-[16px] md:mx-[22px] mb-[24px]">
              <Formik
                initialValues={{
                  job_summary: editJobSummaryData
                    ? editJobSummaryData
                    : data || "",
                }}
                validationSchema={summarySchema}
                enableReinitialize={true}
                innerRef={(ref) => {
                  if (ref?.errors && ref?.isSubmitting === true) {
                    scrollToErrors(ref?.errors);
                  }
                }}
                onSubmit={async (values, { resetForm }) => {
                  const response = await updateJobSummary(values);

                  if (response?.error) {
                    toast.error(
                      response?.error?.data?.errors[0]?.detail ||
                        "何か問題が発生しました"
                    );
                  }
                  if (response?.data?.success === true) {
                    setUserDetails({ details: response?.data?.data?.detail });
                    setOpenJobSummary(false);
                    toast.success(
                      response?.data?.message ||
                        "ジョブの概要が正常に更新されました。"
                    );
                    setEditJobSummaryData("");
                    resetForm();
                  }
                }}
              >
                {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  values,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className=" my-2 ml-auto rounded bg-gradient-to-r from-[#0956FF] to-[#5389FF] h-fit w-fit p-[1px]">
                      <button
                        type="button"
                        className="text-[#0956FF] h-fit w-fit bg-white py-2 md:py-[12px] px-3 md:px-[16px] rounded font-bold flex items-center text-[8px] md:text-[10px] xl:text-[12px]"
                        onClick={() => {
                          setEditJobSummaryData(values?.job_summary);
                          setOpenAIModal(true);
                          setOpenJobSummary(false);
                        }}
                      >
                        <span>
                          <img src={AI} alt="FileSvg" />
                        </span>
                        &nbsp;
                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#0956FF] to-[#5389FF]">
                          {Data?.ai_text}
                        </span>
                      </button>
                    </div>
                    <div>
                      <div className="h-[290px] lg:h-[390px] overflow-y-auto overflow-x-hidden">
                        <div className="w-full">
                          <textarea
                            name="job_summary"
                            cols="51"
                            rows="9"
                            className="text-[14px] font-normal leading-[22px] py-[8px] px-[10px] w-[inherit] border-2 border-[#CED0D8]-100 outline-none"
                            value={values?.job_summary}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {errors.job_summary && touched.job_summary && (
                            <p className="text-red-600 text-[12px] ">
                              *{errors.job_summary}
                            </p>
                          )}
                        </div>
                        <div className=" mt-[20px] lg:mt-[40px]">
                          <button
                            type="button"
                            className="flex items-center gap-[8px] bg-[#E6EEFF] w-full h-[46px] pt-[12px] pr-[16px] pb-[12px] pl-[16px]"
                            onClick={() => setOpen(!open)}
                          >
                            <span>
                              {open ? (
                                <img
                                  src={OpenChev}
                                  alt="cheav"
                                  className="transform rotate-[180deg]"
                                />
                              ) : (
                                <img src={OpenChev} alt="cheav" />
                              )}
                            </span>
                            <p className="text-[14px] font-normal leading-[22px] text-[#212121]">
                              サンプル記入例
                            </p>
                          </button>
                        </div>
                        {open && (
                          <div
                            id="content"
                            className=" bg-[#E6EEFF] h-[auto] pt-[8px] pr-[16px] pb-[8px] pl-[16px] mt-[4px]"
                          >
                            <div className="text-[14px] font-normal leading-[22px] text-justify">
                              {jobSummary?.sample_text}
                            </div>
                          </div>
                        )}
                        <div className="mt-[40px]">
                          <div className="flex gap-[10px] items-center">
                            <button
                              type="button"
                              className="flex-1 h-[40px] py-[9px] px-auto font-normal text-xs rounded-[4px] border border-[#CED0D8]"
                              onClick={() => {
                                setOpenJobSummary(false);
                                setEditJobSummaryData("");
                              }}
                            >
                              キャンセル
                            </button>
                            <button
                              type="submit"
                              disabled={isLoading}
                              className={`w-[194px] md:w-[358px] h-[40px] ${
                                isLoading
                                  ? "bg-neutral-500"
                                  : "bg-gradient-to-r from-[#0956FF] to-[#5389FF]"
                              } rounded-[4px] text-xs font-bold text-[#fff]`}
                            >
                              {isLoading ? (
                                <div className="flex justify-center">
                                  <Loader />
                                </div>
                              ) : (
                                "保存する"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EditJobSummary;
