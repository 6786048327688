function JobPrLoader() {
  return (
    <div className="mt-[16px] animate-pulse">
      <div className="mt-[8px] md:mt-[12px] lg:mt-[16px]">
        {[...Array(4)].map((abc,index) => (
          <h1 key={index} className="flex items-center gap-[4px] mt-2">
            <span className="w-full h-[10px] bg-slate-200 rounded"></span>
          </h1>
        ))}
      </div>
    </div>
  );
}

export default JobPrLoader;
