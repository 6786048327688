function SkillsLoader() {
  return (
    <div>
      {[...Array(2)].map((abc, index) => (
        <div key={index}>
          <div className=" mt-[16px] flex items-center gap-[8px] animate-pulse bg-slate-200 py-[10px] px-[16px] w-full">
            <div className=" px-[16px] py-[6px] bg-slate-200 h-[20px] cursor-pointer"></div>
            <span />
          </div>
        </div>
      ))}
    </div>
  );
}

export default SkillsLoader;
