import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const ProjectHistory = createApi({
  reducerPath: "ProjectHistory",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/api`,
  }),
  endpoints: (builder) => ({
    AddProjectHistory: builder.mutation({
      query: (payload) => {
        return {
          url: "/v1/project_histories",
          method: "POST",
          body: payload,
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
      },
    }),

    GetProjectHistory: builder.query({
      query: () => ({
        url: "/v1/project_histories",
        method: "Get",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    }),

    GetProjectHistoryById: builder.mutation({
      query: (project_id) => {
        return {
          url: `/v1/project_histories/${project_id}`,
          method: "Get",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
      },
    }),

    UpdateProjectHistory: builder.mutation({
      query: (updatedData) => {
        return {
          url: `/v1/project_histories/${updatedData?.user_id}`,
          method: "Put",
          body: updatedData?.project_history,
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
      },
    }),

    DeleteProjectHistory: builder.mutation({
      query: (user_id) => {
        return {
          url: `/v1/project_histories/${user_id}`,
          method: "Delete",
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
      },
    }),

    AddProjectConfirmation: builder.mutation({
      query: (payload) => {
        return {
          url: "/v1/project_history_feedbacks",
          method: "POST",
          body: payload,
        };
      },
    }),

    GetProjectHistoryByIdPublic: builder.mutation({
      query: (project_id) => {
        return {
          url: `/v1/project_histories/${project_id}`,
          method: "Get",
        };
      },
    }),
  }),
});


export const { useAddProjectHistoryMutation } = ProjectHistory;
export const { useGetProjectHistoryQuery } = ProjectHistory;
export const { useUpdateProjectHistoryMutation } = ProjectHistory;
export const { useGetProjectHistoryByIdMutation } = ProjectHistory;
export const { useDeleteProjectHistoryMutation } = ProjectHistory;
export const { useAddProjectConfirmationMutation } = ProjectHistory;
export const { useGetProjectHistoryByIdPublicMutation } = ProjectHistory;
