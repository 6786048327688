import { useContext, useEffect, useState } from "react";

import EditSkills from "../modals/EditSkills";
import { ContextData } from "../../Store/Context";
import SkillsLoader from "../Loader/SkillsLoader";
import NoteIcon from "../../Assets/icons/noteIcon.svg";
import EditButton from "../buttons/EditButton";

function Skills({ openEditSkills, setOpenEditSkills, refModal }) {
  const { userDetail } = useContext(ContextData);
  const [skills, setSkills] = useState({});

  useEffect(() => {
    setSkills(userDetail?.details);
  }, [userDetail]);

  return (
    <section
      className="bg-white p-[10px] md:p-[14px] lg:p-[20px] mt-[16px]"
      id="section3"
    >
      <div className="flex items-center justify-between mb-[12px] lg:mb-[16px] border-b">
        <h1 className="font-bold text-[16px] md:text-[18px] lg:text-[20px] pb-[8px]">
        活かせるスキル・資格
        </h1>
        <EditButton onClick={() => setOpenEditSkills(!openEditSkills)} />
      </div>
      {userDetail?.isLoading ? (
        <SkillsLoader />
      ) : skills?.skills?.length ? (
        skills?.skills?.map((items, index) => {
          return (
            <div
              key={index}
              className=" mt-[16px] flex items-center gap-[8px] bg-[#F6F6F6] py-[12px] px-[16px]"
            >
              <img src={NoteIcon} alt="NoteIcon" />
              <span className="font-normal text-[12px] lg:text-[14px] leading-[22px] tetx-[#212121]">
                {items}
              </span>
            </div>
          );
        })
      ) : (
        <div className="font-bold text-center">データなし</div>
      )}
      {openEditSkills && (
        <EditSkills
          skills={skills}
          openEditSkills={openEditSkills}
          setOpenEditSkills={setOpenEditSkills}
          refModal={refModal}
        />
      )}
    </section>
  );
}
export default Skills;
