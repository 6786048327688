import React from 'react'
import AITextLoader from '../Loader/AITextLoader';

const ImportResumeModal = () => {
  return (
    <div className="overlay">
      <div
        id=""
        tabIndex="-1"
        aria-hidden="true"
        className=" fixed z-50 inset-0 overflow-y-auto"
      >
        <div className="flex justify-center items-center h-full">
          <div
            className=" bg-[#fff] w-[326px] md:w-[500px] overflow-y-auto overflow-x-hidden"
          >
            <div className="flex flex-col justify-center items-center my-10 md:my-[80px]">
              <AITextLoader />
              <h3 className={`font-bold text-md mt-4 text-[#0956FF]`}>
                ファイルを読み込み中
              </h3>
              <p className="font-normal text-xs p-2">30秒ほどお待ちください</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImportResumeModal