import { useContext, useEffect, useState } from "react";

import { Formik } from "formik";
import { toast } from "react-toastify";
// import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";

import { UpdateWorkHistorySchema } from "../validation/FormikValidation";
import { scrollToErrors } from "../comman/ScrollToError";
import CloseModalButton from "../buttons/CloseModalButton";
import {
  useGetWorkHistoryByIdMutation,
  useUpdateWorkHistoryMutation,
} from "../../Store/slice/WorkSlice";
import Loader from "../buttons/Loader";
import { Sales_amount, emp_type, listed_type } from "../comman/Options";
import { ContextData } from "../../Store/Context";

registerLocale("ja", ja);

function EditWorkHistory({
  setOpenEditWorkHistory,
  id,
  workHistory,
  setWorkHistory,
  refModal,
}) {
  const [updateHistory, { error, isLoading }] = useUpdateWorkHistoryMutation();
  const [getDataById] = useGetWorkHistoryByIdMutation();
  const { userDetail, setUserDetails } = useContext(ContextData);
  const [updateData, setUpdateData] = useState();
  const CurrentYear = new Date().getFullYear();
  const CurrentMonth = new Date().getMonth() + 1;

  if (error) {
    if (error?.originalStatus === 503) {
      toast.error("サービスは利用できません ！");
    } else {
      toast.error(error?.data?.message || "Something went wrong!");
    }
  }

  useEffect(() => {
    async function getData() {
      const response = await getDataById(id);
      setUpdateData(response?.data?.data?.work_history);
    }
    getData();
  }, [getDataById, id]);

  // const StartMonthInput = forwardRef(({ value, onClick }, ref) => (
  //   <button
  //     type="button"
  //     className="border pl-5 pt-3 pb-3 border-[#CED0D8] pr-5 react-datepicker-ignore-onclickoutside "
  //     onClick={onClick}
  //     ref={ref}
  //   >
  //     {value.replace("月", "")}
  //   </button>
  // ));

  // const EndMonthInput = forwardRef(
  //   ({ value, onClick, affiliationVal }, ref) => (
  //     <button
  //       type="button"
  //       disabled={affiliationVal}
  //       className={`border pl-5 pt-3 pb-3  pr-5 border-[#CED0D8] react-datepicker-ignore-onclickoutside ${
  //         affiliationVal ? "bg-gray-300" : "bg-white"
  //       }`}
  //       onClick={onClick}
  //       ref={ref}
  //     >
  //       {value.replace("月", "")}
  //     </button>
  //   )
  // );

  const startdate = new Date(updateData?.start_date);
  const startYear = startdate?.getFullYear();
  const startMonth = startdate?.getMonth() + 1;

  const enddate = new Date(updateData?.end_date);
  const endYear = enddate?.getFullYear();
  const endMonth = enddate?.getMonth() + 1;

  return (
    <div className="overlay">
      <div
        id="work-history-modal"
        tabIndex="-1"
        aria-hidden="true"
        className=" fixed z-50 inset-0 overflow-y-auto"
      >
        <div className="flex justify-center items-center h-full">
          <div
            className="bg-[#fff] w-[326px] md:w-[510px]"
            ref={refModal}
          >
            <div className="border-b border-[#CED0D8] mb-[24px] relative">
              <div className="mx-[50px] my-[16px]">
                <h3 className="text-sm md:text-base tracking-[5%] font-semibold text-[#212121] text-center h-[24px]">
                  職務経歴を編集
                </h3>
                <CloseModalButton
                  onClick={() => setOpenEditWorkHistory(false)}
                />
              </div>
            </div>
            <div className="mx-4 md:mx-[22px] mb-[24px]">
              <Formik
                initialValues={{
                  currently_affiliated: updateData?.currently_affiliated,
                  company_name: updateData?.company_name
                    ? updateData?.company_name
                    : "",
                  position: updateData?.position ? updateData?.position : "",
                  description: updateData?.description
                    ? updateData?.description
                    : "",
                  employment_type: updateData?.employment_type
                    ? updateData?.employment_type
                    : "",
                  start_year: startYear || "",
                  start_month: startMonth || "",
                  end_year: endYear || "",
                  end_month: endMonth || "",
                  capital: updateData?.capital ? updateData?.capital : "",
                  listing_type: updateData?.listing_type
                    ? updateData?.listing_type
                    : "",
                  amount_of_sales: updateData?.amount_of_sales
                    ? updateData?.amount_of_sales
                    : "",
                  number_of_employees: updateData?.number_of_employees
                    ? updateData?.number_of_employees
                    : "",
                  business_details: updateData?.business_details
                    ? updateData?.business_details
                    : "",
                }}
                validationSchema={UpdateWorkHistorySchema}
                enableReinitialize={true}
                innerRef={(ref) => {
                  if (ref?.errors && ref?.isSubmitting === true) {
                    scrollToErrors(ref?.errors);
                  }
                }}
                onSubmit={async (values, { resetForm, setErrors }) => {
                  if (values.start_year === values.end_year) {
                    if (values.start_month > values.end_month) {
                      setErrors({ end_month: "end month is not valid" });
                      return;
                    }
                  }

                  const start = new Date(
                    values.start_year.toString() +
                      "-" +
                      values.start_month.toString() +
                      "-" +
                      "01"
                  )
                    .toLocaleDateString("ja-JP")
                    .replaceAll("/", "-");
                  const end = new Date(
                    values.end_year.toString() +
                      "-" +
                      values.end_month.toString() +
                      "-" +
                      "30"
                  )
                    .toLocaleDateString("ja-JP")
                    .replaceAll("/", "-");

                  const response = await updateHistory({
                    work_history: {
                      ...values,
                      start_date: start,
                      end_date: values.currently_affiliated ? null : end,
                    },
                    user_id: id,
                  });

                  if (response?.error) {
                    toast.error(
                      response?.error?.data?.errors[0]?.detail ||
                        "変更は正常に保存されました"
                    );
                  }

                  if (response?.data?.success === true) {
                    setWorkHistory(
                      workHistory.map((work) =>
                        work?.id !== response?.data?.data?.work_history?.id
                          ? work
                          : response?.data?.data?.work_history
                      )
                    );
                    setUserDetails({
                      ...userDetail,
                      details: {
                        ...userDetail?.details,
                        work_histories: workHistory.map((work) =>
                          work?.id !== response?.data?.data?.work_history?.id
                            ? work
                            : response?.data?.data?.work_history
                        ),
                      },
                    });
                    setOpenEditWorkHistory(false);
                    toast.success(
                      response?.data?.message || "職歴が正常に更新されました。"
                    );
                    resetForm();
                  }
                }}
              >
                {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  values,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="h-[400px] xl2:h-[500px]  overflow-y-auto">
                      <div className="">
                        <h1 className="text-[14px] font-bold leading-[22px] text-[#878787]">
                          職務経歴(所属経歴)
                        </h1>
                        <div>
                          <div className="md:flex gap-[16px] items-center font-medium text-[14px] leading-[22px] text-[#212121] md:mt-[16px]">
                            <div className="w-[56px]">
                              <label htmlFor="company_name">社名</label>
                            </div>
                            <input
                              name="company_name"
                              type="text"
                              placeholder=""
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.company_name}
                              className="w-full md:w-[384px] h-[48px] mt-2 md:mt-0 font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px]"
                            />
                          </div>
                          {errors.company_name && touched.company_name && (
                            <p className="text-red-600 text-[12px] ml-[72px]">
                              *{errors.company_name}
                            </p>
                          )}
                        </div>
                        <div className="md:mt-[16px]">
                          <div className="mt-[16px]">
                            <div className="md:flex gap-[16px] items-center font-medium text-[14px] leading-[22px] text-[#212121] md:mt-[16px]">
                              <div className="w-[56px]">
                                <label htmlFor="position">役職</label>
                              </div>
                              <input
                                name="position"
                                type="text"
                                placeholder=""
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.position}
                                className="w-full md:w-[384px] h-[48px] mt-2 md:mt-0 font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px]"
                              />
                            </div>
                            {errors.position && touched.position && (
                              <p className="text-red-600 text-[12px] ml-[72px]">
                                *{errors.position}
                              </p>
                            )}
                          </div>
                          <div className="mt-[16px]">
                            <div className="md:flex gap-[16px] items-center font-medium text-[14px] leading-[22px] text-[#212121] md:mt-[16px]">
                              <div className="w-[56px]">
                                <label htmlFor="description">業務内容</label>
                              </div>
                              <input
                                name="description"
                                type="text"
                                placeholder=""
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.description}
                                className="w-full md:w-[384px] h-[48px] mt-2 md:mt-0 font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px]"
                              />
                            </div>
                            {errors.description && touched.description && (
                              <p className="text-red-600 text-[12px] ml-[72px]">
                                *{errors.description}
                              </p>
                            )}
                          </div>
                          <div className="mt-[16px]">
                            <div className="md:flex gap-[16px] items-center font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
                              <div className="w-[56px] mb-2 md:mb-0">
                                <label htmlFor="affiliation_period ">
                                  所属期間
                                </label>
                              </div>
                              <div className="w-full md:w-[384px] font-normal pb-[12px] md:py-[12px]">
                                <div className="md:flex items-center">
                                  <div className="flex items-center mb-2 md:mb-0">
                                    {/* <DatePicker
                                    className="picker"
                                    name="start_year"
                                    selected={
                                      values.start_year
                                        ? new Date().setFullYear(
                                            values.start_year
                                          )
                                        : new Date().setFullYear("2010")
                                    }
                                    minDate={new Date().setFullYear(1900)}
                                    maxDate={new Date()}
                                    onBlur={(date) => {
                                      setFieldValue(
                                        "start_year",
                                        date.target.value || "2010"
                                      );
                                    }}
                                    onChange={(date) => {
                                      setFieldValue(
                                        "start_year",
                                        date?.getFullYear()
                                      );
                                    }}
                                    showYearPicker
                                    dateFormat="YYYY"
                                  /> */}
                                    <input
                                      name="start_year"
                                      type="number"
                                      min={1900}
                                      max={new Date().getFullYear()}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.start_year}
                                      className="w-[65px] h-[48px] font-normal py-[12px] pl-[16px] pr-0  border border-[#CED0D8] rounded-[2px]"
                                    />
                                    <p className="px-[8px]">年</p>
                                    {/* <DatePicker
                                    className="picker"
                                    locale="ja"
                                    name="start_month"
                                    selected={
                                      values.start_month
                                        ? new Date().setMonth(
                                            values.start_month - 1
                                          )
                                        : new Date()
                                    }
                                    onBlur={(date) => {
                                      setFieldValue(
                                        "start_month",
                                        values?.start_month ||
                                          new Date().getMonth() + 1
                                      );
                                    }}
                                    onChange={(date) => {
                                      setFieldValue(
                                        "start_month",
                                        date?.toLocaleString("default", {
                                          month: "2-digit",
                                        })
                                      );
                                    }}
                                    dateFormat="MMM"
                                    showMonthYearPicker
                                    showFullMonthYearPicker
                                    customInput={<StartMonthInput />}
                                  /> */}
                                    <input
                                      name="start_month"
                                      type="number"
                                      min={1}
                                      max={12}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.start_month}
                                      className="w-[50px] h-[48px] font-normal pl-5 pt-3 pb-3  pr-0  border border-[#CED0D8] rounded-[2px]"
                                    />
                                    <div className="flex">
                                      <p className="px-[8px]">月</p>
                                      <p className="px-[8px]">〜</p>
                                    </div>
                                  </div>
                                  <div className="flex items-center">
                                    {/* <DatePicker
                                    className={`picker ${
                                      values?.currently_affiliated
                                        ? "bg-gray-300"
                                        : "bg-white"
                                    }`}
                                    disabled={values?.currently_affiliated}
                                    name="end_year"
                                    selected={
                                      values.end_year
                                        ? new Date().setFullYear(
                                            values.end_year
                                          )
                                        : new Date()
                                    }
                                    minDate={new Date().setFullYear(1900)}
                                    maxDate={new Date()}
                                    onBlur={(date) => {
                                      setFieldValue(
                                        "end_year",
                                        date.target.value ||
                                          new Date().getFullYear()
                                      );
                                    }}
                                    onChange={(date) => {
                                      setFieldValue(
                                        "end_year",
                                        date?.getFullYear()
                                      );
                                    }}
                                    showYearPicker
                                    dateFormat="yyyy"
                                  /> */}
                                    <input
                                      name="end_year"
                                      type="number"
                                      min={1900}
                                      max={new Date().getFullYear()}
                                      disabled={values?.currently_affiliated}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.end_year}
                                      className={`w-[65px] h-[48px] font-normal py-[12px] pl-[16px] pr-0  border border-[#CED0D8] rounded-[2px] ${
                                        values?.currently_affiliated
                                          ? "bg-gray-300"
                                          : "bg-white"
                                      }`}
                                    />
                                    <p className="px-[8px]">年</p>
                                    {/* <DatePicker
                                    className={`picker ${
                                      values?.currently_affiliated
                                        ? "bg-gray-300"
                                        : "bg-white"
                                    }`}
                                    locale="ja"
                                    name="end_month"
                                    selected={
                                      values.end_month
                                        ? new Date().setMonth(
                                            values.end_month - 1
                                          )
                                        : new Date()
                                    }
                                    onChange={(date) => {
                                      setFieldValue(
                                        "end_month",
                                        date?.toLocaleString("default", {
                                          month: "2-digit",
                                        })
                                      );
                                    }}
                                    onBlur={(date) => {
                                      setFieldValue(
                                        "end_month",
                                        values?.end_month
                                          ? values?.end_month
                                          : new Date().getMonth() + 1
                                      );
                                    }}
                                    dateFormat="MMM"
                                    showMonthYearPicker
                                    showFullMonthYearPicker
                                    customInput={
                                      <EndMonthInput
                                        affiliationVal={
                                          values?.currently_affiliated
                                        }
                                      />
                                    }
                                  /> */}
                                    <input
                                      name="end_month"
                                      type="number"
                                      min={1}
                                      max={
                                        values?.end_year ===
                                        new Date().getFullYear()
                                          ? new Date().getMonth() + 1
                                          : 12
                                      }
                                      disabled={values?.currently_affiliated}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.end_month}
                                      className={`w-[50px] h-[48px] font-normal pl-5 pt-3 pb-3  pr-0  border border-[#CED0D8] rounded-[2px] ${
                                        values?.currently_affiliated
                                          ? "bg-gray-300"
                                          : "bg-white"
                                      }`}
                                    />
                                    <p className="px-[8px]">月</p>
                                  </div>
                                </div>
                                <div className="flex justify-between">
                                  <div>
                                    {errors.start_year &&
                                      touched.start_year && (
                                        <p className="text-red-600 text-[12px]">
                                          *{errors.start_year}
                                        </p>
                                      )}
                                    {errors.start_month &&
                                      touched.start_month && (
                                        <p className="text-red-600 text-[12px]">
                                          *{errors.start_month}
                                        </p>
                                      )}
                                  </div>
                                  <div>
                                    {errors.end_year && touched.end_year && (
                                      <p className="text-red-600 text-[12px] ml-[-165px]">
                                        *{errors.end_year}
                                      </p>
                                    )}
                                    {errors.end_month && touched.end_month && (
                                      <p className="text-red-600 text-[12px] ml-[-165px]">
                                        *{errors.end_month}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <label className="cursor-pointer text-xs md:mt-[16px] w-fit gap-3 text-center flex justify-start items-end md:ml-[75px]">
                              <input
                                name="currently_affiliated"
                                type="checkbox"
                                disabled={
                                  !values?.start_year || !values?.start_month
                                }
                                checked={values?.currently_affiliated}
                                className="h-[18px] w-[18px] cursor-pointer"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  setFieldValue(
                                    "currently_affiliated",
                                    e.target.checked
                                  );
                                  if (e.target.checked === true) {
                                    setFieldValue("end_year", CurrentYear);
                                    setFieldValue("end_month", CurrentMonth);
                                  }
                                  if (e.target.checked === false) {
                                    setFieldValue(
                                      "end_year",
                                      endYear ? endYear : ""
                                    );
                                    setFieldValue(
                                      "end_month",
                                      endMonth ? endMonth : ""
                                    );
                                  }
                                }}
                              />
                              現在も所属している
                            </label>
                          </div>
                          <div className="mt-[16px]">
                            <div className="md:flex gap-[16px] items-center font-medium text-[14px] leading-[22px] text-[#212121] md:mt-[16px]">
                              <div className="w-[56px]">
                                <label htmlFor="employment_type">
                                  雇用形態
                                </label>
                              </div>
                              <div className="w-full md:w-[384px] mt-2 md:mt-0">
                                <select
                                  name="employment_type"
                                  value={values.employment_type}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  style={{ display: "block" }}
                                  className="w-[208px] md:w-[300px] h-[48px] font-normal py-[12px] px-[16px] bg-[#fff] border border-[#CED0D8] rounded-[2px]"
                                >
                                  <option value="">-- 雇用を選択 --</option>
                                  {emp_type?.map((option, index) => {
                                    return (
                                      <option key={index} value={option?.value}>
                                        {option?.label}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            {errors.employment_type &&
                              touched.employment_type && (
                                <p className="text-red-600 text-[12px] ml-[72px]">
                                  *{errors.employment_type}
                                </p>
                              )}
                          </div>
                          <div className="mt-[24px]">
                            <h1 className="text-[14px] font-bold leading-[22px] text-[#878787]">
                              会社概要
                            </h1>
                            <div>
                              <div className="md:flex gap-[16px] items-center font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
                                <div className="w-[56px]">
                                  <label htmlFor="amount_of_sales">
                                    売上高
                                  </label>
                                </div>
                                <div className="flex items-center mt-2 md:mt-0">
                                  <select
                                    name="amount_of_sales"
                                    value={values.amount_of_sales}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    style={{ display: "block" }}
                                    className="w-[208px] md:w-[300px] h-[48px] font-normal py-[12px] px-[16px] bg-[#fff] border border-[#CED0D8] rounded-[2px]"
                                  >
                                    <option disabled value="">
                                      --売上高を選択--
                                    </option>
                                    {Sales_amount?.map((option, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={option?.value}
                                        >
                                          {option?.label}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  {/* <p className="text-[14px] ml-[8px] font-normal leading-[22px] text-[#212121]">
                                    億円
                                  </p> */}
                                </div>
                              </div>
                              {errors.amount_of_sales &&
                                touched.amount_of_sales && (
                                  <p className="text-red-600 text-[12px] ml-[72px]">
                                    *{errors.amount_of_sales}
                                  </p>
                                )}
                            </div>
                            <div className="mt-[16px]">
                              <div className="md:flex gap-[16px] items-center font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
                                <div className="w-[56px]">
                                  <label htmlFor="listing_type">上場</label>
                                </div>
                                <div className="w-full md:w-[384px] mt-2 md:mt-0">
                                  <select
                                    name="listing_type"
                                    value={values.listing_type}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    style={{ display: "block" }}
                                    className="w-[208px] md:w-[300px] h-[48px] font-normal py-[12px] px-[16px] bg-[#fff] border border-[#CED0D8] rounded-[2px]"
                                  >
                                    {listed_type?.map((option, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={option?.value}
                                        >
                                          {option?.label}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                              {errors.listing_type && touched.listing_type && (
                                <p className="text-red-600 text-[12px] ml-[72px]">
                                  *{errors.listing_type}
                                </p>
                              )}
                            </div>
                            <div>
                              <div className="md:flex gap-[16px] items-center font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
                                <div className="w-[56px]">
                                  <label htmlFor="number_of_employees ">
                                    従業員数
                                  </label>
                                </div>
                                <div className="flex items-center mt-2 md:mt-0">
                                  <input
                                    name="number_of_employees"
                                    type="number"
                                    placeholder=""
                                    min={0}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.number_of_employees}
                                    className="w-[100px] h-[48px] font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px]"
                                  />
                                  <p className="text-[14px] ml-[8px] font-normal leading-[22px] text-[#212121]">
                                    人
                                  </p>
                                </div>
                              </div>
                              {errors.number_of_employees &&
                                touched.number_of_employees && (
                                  <p className="text-red-600 text-[12px] ml-[72px]">
                                    *{errors.number_of_employees}
                                  </p>
                                )}
                            </div>
                            <div>
                              <div className="md:flex gap-[16px] items-center font-medium text-[14px] leading-[22px] text-[#212121] mt-[16px]">
                                <div className="w-[56px]">
                                  <label htmlFor="business_details">
                                    事業内容
                                  </label>
                                </div>
                                <input
                                  name="business_details"
                                  type="text"
                                  placeholder="事業内容が入ります事業内容が入ります"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.business_details}
                                  className="w-full md:w-[384px] h-[48px] mt-2 md:mt-0 font-normal py-[12px] px-[16px] border border-[#CED0D8] rounded-[2px]"
                                />
                              </div>
                              {errors.business_details &&
                                touched.business_details && (
                                  <p className="text-red-600 text-[12px] ml-[72px]">
                                    *{errors.business_details}
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-[40px]">
                        <div className="flex gap-[10px] items-center">
                          <button
                            type="button"
                            className="flex-1 h-[40px] py-[9px] px-auto font-normal text-xs rounded-[4px] border border-[#CED0D8]"
                            onClick={() => setOpenEditWorkHistory(false)}
                          >
                            キャンセル
                          </button>
                          <button
                            type="submit"
                            disabled={isLoading}
                            className={`w-[194px] md:w-[358px] h-[40px] ${
                              isLoading
                                ? "bg-neutral-500"
                                : " bg-gradient-to-r from-[#0956FF] to-[#5389FF]"
                            } rounded-[4px] text-xs font-bold text-[#fff]`}
                          >
                            {isLoading ? (
                              <div className="flex justify-center">
                                <Loader />
                              </div>
                            ) : (
                              "保存する"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EditWorkHistory;
