import React, { useState } from "react";
import AI from "../../../Assets/icons/ai-blue.svg";
import OpenChev from "../../../Assets/icons/openChev-blue.svg";
import Data from "../../../JSON/data.json";
import { Formik } from "formik";
import { scrollToErrors } from "../../comman/ScrollToError";
import { summarySchema } from "../../validation/FormikValidation";
import { toast } from "react-toastify";
import {
  useUpdateUserDetailsMutation,
  useUpdateUserMutation,
} from "../../../Store/slice/AuthSlice";
import Loader from "../../buttons/Loader";

const InnerStep1 = ({
  setOpenAIModal,
  openStep,
  setOpenStep,
  setCurrentStep,
  userDetail,
  setUserDetails,
  setSubStep,
}) => {
  const jobSummary = Data?.edit_jobSummary;
  const [open, setOpen] = useState(false);
  const [aiPopup, setAiPopup] = useState(true)
  const [updateJobSummary, { error, isLoading }] =
    useUpdateUserDetailsMutation();
  const [
    registerUserDetail,
    { error: isstepsError, isLoading: isstepsLoading },
  ] = useUpdateUserMutation();

  if (error || isstepsError) {
    if (error?.originalStatus || isstepsError?.originalStatus === 503) {
      toast.error("サービスは利用できません ！");
    } else {
      toast.error(
        error?.data?.message ||
          isstepsError?.data?.message ||
          "Something went wrong!"
      );
    }
  }

  return (
    <Formik
      initialValues={{
        job_summary: userDetail?.details?.job_summary || "",
      }}
      enableReinitialize
      validationSchema={summarySchema}
      innerRef={(ref) => {
        if (ref?.errors && ref?.isSubmitting === true) {
          scrollToErrors(ref?.errors);
        }
      }}
      onSubmit={async (values, { setErrors, resetForm }) => {
        const response = await updateJobSummary(values);

        if (response?.error) {
          toast.error(
            response?.error?.data?.errors[0]?.detail || "何か問題が発生しました"
          );
        }
        if (response?.data?.success === true) {
          const stepResponse = await registerUserDetail({
            steps_completed: "job_summary",
          });

          if (stepResponse?.error) {
            toast.success(response?.error?.data?.errors[0]);
          }

          if (stepResponse?.data?.success === true) {
            // setUserDetails({ details: response?.data?.data?.detail })
            setUserDetails({...userDetail,
              details: {
                ...userDetail?.details,
                job_summary: response?.data?.data?.detail?.job_summary}})
            setOpenStep(2);
            setSubStep(2);
            setCurrentStep(2);
            resetForm();
            toast.success(
              response?.data?.message || "ジョブの概要が正常に更新されました。"
            );
          }
        }
      }}
    >
      {({
        errors,
        touched,
        handleChange,
        handleBlur,
        values,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="flex justify-end items-center mb-2 relative">
            <div className={`bg-[#0956FF] p-4 text-white text-xs rounded-md absolute -top-16 -right-4 ${aiPopup ? "block" : "hidden"}`}>
              <button type="button" onClick={()=>{setAiPopup(false)}} className="absolute top-[2px] right-1">&#x2715;</button>
              <span className="font-semibold">文章を簡単に生成できます！</span>
              <span className="absolute -bottom-2 left-1/2 border-l-[10px] border-r-[10px] border-t-[10px] border-x-transparent border-t-[#0956FF]"></span>
            </div>
            <div className="bg-gradient-to-r from-[#0956FF] to-[#5389FF] h-fit w-fit p-[1px] rounded">
              <button
                type="button"
                className="flex items-center text-[#0956FF] text-[8px] md:text-[10px] xl:text-[12px] font-bold bg-white py-2 md:py-[12px] px-3 md:px-[16px] rounded"
                onClick={() => setOpenAIModal(true)}
              >
                <span>
                  <img src={AI} alt="FileSvg" />
                </span>
                &nbsp;
                <span>{Data?.ai_text}</span>
              </button>
            </div>
          </div>
          <div>
            <div className="w-full">
              <textarea
                name="job_summary"
                cols="51"
                rows="9"
                placeholder=""
                className="text-[14px] font-normal leading-[22px] py-[8px] px-[10px] w-[inherit] border-2 border-[#CED0D8]-100 outline-none"
                value={values?.job_summary}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.job_summary && touched.job_summary && (
                <p className="text-red-600 text-[12px] ">
                  *{errors.job_summary}
                </p>
              )}
            </div>
            <div className="mt-[16px]" onClick={() => setOpen(!open)}>
              <button
                type="button"
                className="flex items-center gap-[8px] bg-[#E6EEFF] w-full h-[46px] pt-[12px] pr-[16px] pb-[12px] pl-[16px]"
              >
                <span>
                  {open ? (
                    <img
                      src={OpenChev}
                      alt="cheav"
                      className="transform rotate-[180deg]"
                    />
                  ) : (
                    <img src={OpenChev} alt="cheav" />
                  )}
                </span>
                <p className="text-[14px] font-normal leading-[22px] text-[#212121]">
                  サンプル記入例
                </p>
              </button>
            </div>
            {open && (
              <div
                id="content"
                className=" bg-[#E6EEFF] h-[auto] pt-[8px] pr-[16px] pb-[8px] pl-[16px] mt-[4px]"
              >
                <div className="text-[14px] font-normal leading-[22px] text-justify">
                  {jobSummary?.sample_text}
                </div>
              </div>
            )}
            <div className="mt-[40px]">
              <div className="flex gap-[10px] items-center">
                <button
                  type="button"
                  className="py-[12px] px-[24px] font-normal text-xs rounded-[4px] border border-[#CED0D8]"
                  onClick={() => {
                    setCurrentStep(1);
                    setOpenStep(1);
                  }}
                >
                  キャンセル
                </button>
                <button
                  type="submit"
                  disabled={
                    Object.keys(errors)?.length || isLoading || isstepsLoading
                  }
                  className={`py-[12px] px-[24px] ${
                    Object.keys(errors)?.length || isLoading || isstepsLoading
                      ? "bg-neutral-500"
                      : "bg-gradient-to-r from-[#0956FF] to-[#5389FF]"
                  } rounded-[4px] text-xs font-bold text-[#fff]`}
                >
                  {isLoading || isstepsLoading ? (
                    <div className="flex justify-center">
                      <Loader />
                    </div>
                  ) : (
                    "保存する"
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default InnerStep1;
