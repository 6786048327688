import { useContext, useEffect, useState } from "react";

import JobPrLoader from "../Loader/JobPrLoader";
import { ContextData } from "../../Store/Context";
import EditJobSummary from "../modals/EditJobSummary";
import EditButton from "../buttons/EditButton";
import AIDraft from "../modals/AIDraft"
import AIDraftProgress from "../modals/AIDraftProgress";
import AIDraftSuccess from "../modals/AIDraftSuccess";
import Data from '../../JSON/data.json'

function JobSummary({ openJobSummary, setOpenJobSummary, refModal }) {
  const [aiBaseText, setAiBaseText] = useState("")
  const [jobSummary, setJobSummary] = useState([]);
  const [openAIModal, setOpenAIModal] = useState(false)
  const [openAIModalInProgress, setOpenAIModalInProgress] = useState(false)
  const [openAIModalSucess, setOpenAIModalSucess] = useState(false)
  // const [generatedText, setGeneratedText] = useState(Data.sample_generated_text)
  const [userInput, setUserInput] = useState('')
  const { userDetail } = useContext(ContextData);
  const { aiCall, setAiCall } = useContext(ContextData);

  useEffect(() => {
    setJobSummary(userDetail?.details?.job_summary);
  }, [userDetail]);

  return (
    <section
      className="bg-white p-[10px] md:p-[14px] lg:p-[20px]"
      id="section0"
    >
      <div className="flex items-center justify-between pb-[12px] lg:pb-[16px] border-b">
        <h1 className="font-bold text-[16px] md:text-[18px] lg:text-[20px]">
          職務要約
        </h1>
        <EditButton onClick={() => { setOpenJobSummary(!openJobSummary); setAiCall("editJobsummray") }} />
      </div>
      <div className="font-normal text-[12px] lg:text-[14px] text-[#212121] mt-[8px] md:mt-[12px] lg:mt-[16px] leading-[22px]">
        {userDetail?.isLoading ? (
          <JobPrLoader />
        ) : jobSummary?.length ? (
          jobSummary
        ) : (
          <div className="font-bold text-center">データなし</div>
        )}
      </div>
      {openJobSummary && (
        <EditJobSummary
          data={jobSummary}
          setOpenJobSummary={setOpenJobSummary}
          refModal={refModal}
          setOpenAIModal={setOpenAIModal}
        />
      )}
      {openAIModal && (
        <AIDraft refModal={refModal}
          setOpenAIModal={setOpenAIModal}
          setOpenAIModalInProgress={setOpenAIModalInProgress}
          userInput={userInput}
          setUserInput={setUserInput}
          setAiBaseText={setAiBaseText}
          setOpenAIModalSucess={setOpenAIModalSucess}
          aiCall={aiCall}
          setOpenJobSummary={setOpenJobSummary}
          header={Data.ai_message_workHistory}
          sampleInput={Data.ai_sample_header}
          sampleInputData = {Data.sample_text_ai_workHistory}
          
        />
      )}
      {openAIModalInProgress && (
        <AIDraftProgress refModal={refModal}
          setOpenAIModalInProgress={setOpenAIModalInProgress}
          userInput={userInput}
        />
      )}
      {openAIModalSucess && (
        <AIDraftSuccess refModal={refModal}
          setOpenAIModalSucess={setOpenAIModalSucess}
          generatedText={aiBaseText}
          setOpenAIModalInProgress={setOpenAIModalInProgress}
          setOpenAIModal={setOpenAIModal}
          setUserInput={setUserInput}
          aiCall={aiCall}
          setOpenJobSummary={setOpenJobSummary}
          userInput={userInput}
        />
      )}
    </section>
  );
}
export default JobSummary;
