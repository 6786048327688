import EditIcon from "../../Assets/icons/editIcon.svg";

function EditButton({ onClick }) {
  return (
    <button type="button" className="flex items-center" onClick={onClick}>
      <img src={EditIcon} alt="EditIcon" /> &nbsp;
      <span className="text-[#0956FF] text-xs font-normal">編集</span>
    </button>
  );
}
export default EditButton;
