import logo from "../../Assets/Images/logo.png";

function PageLoader() {
  return (
    <div className="grid h-screen place-items-center mx-24">
      <div>
        <img src={logo} alt="logo" className="animate-bounce" />
        <div className="loader_line" />
      </div>
    </div>
  );
}
export default PageLoader;
