import { useCallback, useEffect, useState, useRef, useContext } from "react";

import { useNavigate } from "react-router-dom";

import Data from "../../JSON/data.json";
import EditProfile from "../modals/EditProfile";
import FileSvg from "../../Assets/icons/fileIcon.svg";
import userLoader from "../../Assets/Images/userLoader.png";
import EditButton from "../buttons/EditButton";
import { ContextData } from "../../Store/Context";

function ProfileHeader() {
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const [openEditProfile, setOpenEditProfile] = useState(false);
  const [profilePic, setProfilePic] = useState(userLoader);
  const { userProfile, setUserProfile } = useContext(ContextData);

  useEffect(() => {
    if (openEditProfile) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openEditProfile]);

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && openEditProfile) {
        setOpenEditProfile(false);
      }
    },
    [setOpenEditProfile, openEditProfile]
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpenEditProfile(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setOpenEditProfile]);

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  const nameLetter = userProfile?.romanized_first_name?.toUpperCase().charAt(0);
  const surNameLetter = userProfile?.romanized_last_name
    ?.toUpperCase()
    .charAt(0);

  function checkImage(url) {
    var imagePath = new Image();
    imagePath.onload = function () {
      if (this.width > 0) {
        setProfilePic(url);
      }
    };
    imagePath.onerror = function () {
      setProfilePic(userLoader);
    };
    imagePath.src = url;
    return true;
  }
  checkImage(userProfile?.avatar);

  return (
    <div>
      <div className="w-full h-[86px] md:h-[210px] lg:h-[240px] bg-[#E6EEFF] py-[24px] md:py-[32px] lg:py-[40px] px-[34px] md:px-[48px] lg:px-[60px]">
        <div className="flex">
          <div className="ml-0 lg:ml-[275px] xl:ml-[315px] flex justify-between w-full z-[20]">
            <div className="flex flex-col md:flex-row gap-[10px] md:gap-[12px] lg:gap-[40px] w-fit xl:ml-[20px]">
              <img
                src={profilePic}
                alt="user"
                className="h-[80px] md:h-[130px] lg:h-[140px] xl:h-[160px] w-[80px] md:w-[130px] lg:w-[140px] xl:w-[160px] rounded-full"
              />
              <div className="flex gap-3 lg:gap-4 xl:gap-10 items-center">
                <div className="flex gap-3 items-center">
                  <h1 className="font-bold text-[16px] lg:text-[18px] xl:text-[24px] leading-[36px] text-[#212121]">
                    {userProfile?.first_name + " " + userProfile?.last_name}
                  </h1>
                  <span className="font-normal text-base text-[16px] text-[#878787] leading-[24px]">
                    {userProfile?.romanized_first_name
                      ? nameLetter
                      : userProfile?.first_name}
                    .
                    {userProfile?.romanized_last_name
                      ? surNameLetter
                      : userProfile?.last_name}
                  </span>
                </div>
                <EditButton
                  onClick={() => setOpenEditProfile(!openEditProfile)}
                />
              </div>
            </div>
            <div className="flex items-start md:items-end gap-4">
              <div className="rounded bg-gradient-to-r from-[#0956FF] to-[#5389FF] h-fit w-fit p-[1px]">
                <button
                  className="text-[#0956FF] h-fit w-full bg-white py-[12px] px-[16px] rounded font-bold flex items-center text-[8px] md:text-[10px] xl:text-[12px]"
                  onClick={() => {
                    navigate("/output-resume");
                  }}
                >
                  <span>
                    <img src={FileSvg} alt="FileSvg" />
                  </span>
                  &nbsp;
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#0956FF] to-[#5389FF] text-[8px] md:text-[10px] xl:text-[12px]">
                    {Data?.output_resume_button}
                  </span>
                </button>
              </div>
              {/* <button
              type="button"
              className="flex items-center bg-[#FF6F00] text-white text-[8px] md:text-[10px] xl:text-[12px] font-bold py-[12px] px-[16px] rounded"
            >
              <span>
                <img src={UploadIcon} alt="UploadIcon" />
              </span>
              &nbsp;
              <span>{Data?.share_resume_button}</span>
            </button> */}
            </div>
          </div>
        </div>
      </div>
      {openEditProfile && (
        <EditProfile
          setOpenEditProfile={setOpenEditProfile}
          user={userProfile}
          setUser={setUserProfile}
          refModal={modalRef}
        />
      )}
    </div>
  );
}
export default ProfileHeader;
