function WorkHistoryLoader() {
  return (
    <div className="mt-[16px] animate-pulse">
      <div className={`flex gap-[8px]`}>
        <div className="flex flex-col justify-between">
          <p className="h-[10px] w-[79px] bg-slate-200 rounded"></p>
          <p className="h-[10px] bg-slate-200 rounded"></p>
        </div>
        <div className="time-line my-[10px]"></div>
        <div className="flex flex-col gap-[16px] my-[20px] w-full">
          <div>
            {[...Array(4)].map((abc,index) => (
              <div key={index} className="flex items-center gap-[4px] mt-1">
                <div className="h-[15px] w-[14px]  bg-slate-200 rounded" />
                <span className="h-[10px] w-[100px] bg-slate-200 rounded"></span>
              </div>
            ))}
          </div>
          <div>
            {[...Array(5)].map((abc,index) => (
              <div key={index} className="py-[4px] px-0 flex w-[400px]">
                <div className="h-[10px] w-[50px] bg-slate-200 rounded"></div>
                <div className="ml-[16px] h-[10px] w-[300px] bg-slate-200 rounded"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkHistoryLoader;
