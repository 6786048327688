import React, { useEffect, useState } from "react";
import Data from "../../../JSON/data.json";
import { toast } from "react-toastify";
import {
  useUpdateUserDetailsMutation,
  useUpdateUserMutation,
} from "../../../Store/slice/AuthSlice";
import Loader from "../../buttons/Loader";

const InnerStep2 = ({
  openStep,
  setOpenStep,
  setCurrentStep,
  userDetail,
  setUserDetails,
  setSubStep,
}) => {
  const speciality = Data?.specialty;
  const [selectedItems, setSelectedItems] = useState([]);
  const [updateSpeciality, { error, isLoading }] =
    useUpdateUserDetailsMutation();
  const [
    registerUserDetail,
    { error: isstepsError, isLoading: isstepsLoading },
  ] = useUpdateUserMutation();

  if (error || isstepsError) {
    if (error?.originalStatus || isstepsError?.originalStatus === 503) {
      toast.error("サービスは利用できません ！");
    } else {
      toast.error(
        error?.data?.message ||
          isstepsError?.data?.message ||
          "Something went wrong!"
      );
    }
  }

  useEffect(() => {
    const specialityData = userDetail?.details?.specialities?.map((skill) => {
      const key = skill.split(":")[0].replace("{", "").trim();
      const value = skill
        .split(":")[1]
        .trim()
        .replace("}", "")
        .replace("[", "")
        .replace("]", "")
        .trim()
        .split(",");
      return { [key]: value };
    });
    const result = specialityData?.reduce((acc, curr) => {
      const [key] = Object.keys(curr);
      const values = curr[key];
      return [
        ...acc,
        ...values.map((value) => {
          return { category: key, sm: value };
        }),
      ];
    }, []);
    setSelectedItems(result);
  }, [userDetail]);

  const handleClick = (category, sm) => {
    const selectedItem = { category, sm };
    const isSelected = selectedItems?.some(
      (item) => item.category === category && item.sm === sm
    );
    if (isSelected) {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter(
          (item) => !(item.category === category && item.sm === sm)
        )
      );
    } else {
      setSelectedItems((prevSelectedItems) => [
        ...prevSelectedItems,
        selectedItem,
      ]);
    }
  };

  const handleSubmit = async () => {
    function selectedResult() {
      var categories = [];
      function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
      }
      var a = selectedItems?.map((item) => item.category);
      var unique = a.filter(onlyUnique);

      unique.forEach((cat) => {
        let val = [];
        selectedItems?.forEach((item) => {
          if (cat === item.category) {
            val.push(item.sm);
          } else {
            return null;
          }
        });
        categories = [...categories, { [cat]: val }];
      });
      return categories;
    }
    const data = selectedResult();
    const specialities = {
      specialities: data.map((obj) => {
        const key = Object.keys(obj)[0];
        const value = obj[key];
        return `{ ${key}: [${value.map((val) => `${val}`)}] }`;
      }),
    };

    const response = await updateSpeciality(specialities);

    if (response?.error) {
      toast.error(
        response?.error?.data?.errors[0]?.detail || "変更は正常に保存されました"
      );
    }

    if (response?.data?.success === true) {
      const stepResponse = await registerUserDetail({ steps_completed: "specialty" });

      if (stepResponse?.error) {
        toast.success(response?.error?.data?.errors[0]);
      }

      if (stepResponse?.data?.success === true) {
        // setUserDetails({ details: response?.data?.data?.detail });
        setUserDetails({...userDetail,
          details: {
            ...userDetail?.details,
            specialities: response?.data?.data?.detail?.specialities}})
        setOpenStep(3);
        setSubStep(3);
        setCurrentStep(2);
        toast.success(
          response?.data?.message ||
            "スペシャライゼーションが正常に更新されました。"
        );
      }
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      {speciality?.map((items) => {
        return (
          <div
            key={items?.id}
            className="mb-[24px] mt-[24px]"
          >
            <h1 className="text-[16px] leading-[24px] font-bold text-[#878787] mb-[8px]">
              {items?.category}
            </h1>
            <div className="flex gap-[8px] items-center flex-wrap">
              {items?.subcategories?.map((category) => {
                const isSelected = selectedItems?.some(
                  (item) =>
                    item.category === items?.category &&
                    item.sm === category?.sm
                );
                return (
                  <p
                    onClick={() => handleClick(items?.category, category?.sm)}
                    key={category?.id}
                    className={`text-[14px] cursor-pointer font-normal leading-[22px] py-[6px] rounded-full px-[16px] ${
                      isSelected
                        ? `text-[#0956FF] bg-[#E6EEFF] border border-[#0956FF]`
                        : "border-0 bg-[#F6F6F6] text-[#878787]"
                    } w-[fit-content] rounded-full`}
                  >
                    {category?.sm}
                  </p>
                );
              })}
            </div>
          </div>
        );
      })}
      <div className="mt-[40px]">
        <div className="flex gap-[10px] items-center">
          <button
            type="button"
            className="py-[12px] px-[24px] font-normal text-xs rounded-[4px] border border-[#CED0D8]"
            onClick={() => {
              setCurrentStep(2);
              setOpenStep(1);
            }}
          >
            キャンセル
          </button>
          <button
            type="submit"
            disabled={!selectedItems?.length || isLoading || isstepsLoading}
            className={`py-[12px] px-[24px] ${
              !selectedItems?.length || isLoading || isstepsLoading
                ? "bg-neutral-500"
                : "bg-gradient-to-r from-[#0956FF] to-[#5389FF]"
            } rounded-[4px] text-xs font-bold text-[#fff]`}
          >
            {isLoading || isstepsLoading ? (
              <div className="flex justify-center">
                <Loader />
              </div>
            ) : (
              "保存する"
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default InnerStep2;
