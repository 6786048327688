import { useContext, useEffect, useState } from "react";

import EditeSpecialty from "../modals/EditSpeciality";
import EditButton from "../buttons/EditButton";
import SpecialityLoader from "../Loader/SpecialityLoader";
import { ContextData } from "../../Store/Context";

function Specialty({ openEditSpeciality, setOpenEditSpeciality, refModal }) {
  const { userDetail } = useContext(ContextData);
  const [speciality, setSpeciality] = useState([]);

  useEffect(() => {
    if (userDetail) {
      const specialityData = userDetail?.details?.specialities?.map((skill) => {
        const key = skill.split(":")[0].replace("{", "").trim();
        const value = skill
          .split(":")[1]
          .trim()
          .replace("}", "")
          .replace("[", "")
          .replace("]", "")
          .trim()
          .split(",");
        return { [key]: value };
      });
      setSpeciality(specialityData);
    } else {
      setSpeciality([]);
    }
  }, [userDetail]);

  return (
    <section
      className="bg-white p-[10px] md:p-[14px] lg:p-[20px] mt-[16px]"
      id="section2"
    >
      <div className="flex items-center justify-between mb-[12px] lg:mb-[16px] border-b">
        <h1 className="font-bold text-[16px] md:text-[18px] lg:text-[20px] pb-[8px]">
          得意分野
        </h1>
        <EditButton
          onClick={() => setOpenEditSpeciality(!openEditSpeciality)}
        />
      </div>
      <div>
        {userDetail?.isLoading ? (
          <SpecialityLoader />
        ) : speciality?.length ? (
          speciality?.map((skill, index) => {
            return (
              <div key={index}>
                <h1 className="text-[12px] lg:text-[14px] font-normal leading-[22px] text-[#212121] mt-[4px]">
                  {Object.keys(skill)}
                </h1>
                <div className="flex mt-[8px] gap-[8px] flex-wrap">
                  {Object.values(skill)[0].map((relatedSkill, idx) => (
                    <div
                      key={idx}
                      className="font-normal text-[12px] lg:text-[14px] text-[#0956FF] px-[16px] py-[6px] bg-[#E6EEFF] w-[fit-content] rounded-full cursor-pointer"
                    >
                      {relatedSkill}
                    </div>
                  ))}
                </div>
              </div>
            );
          })
        ) : (
          <div className="font-bold text-center">データなし</div>
        )}
      </div>
      {openEditSpeciality && (
        <EditeSpecialty
          specialityData={speciality}
          setSpecialityData={setSpeciality}
          openEditSpeciality={openEditSpeciality}
          setOpenEditSpeciality={setOpenEditSpeciality}
          refModal={refModal}
        />
      )}
    </section>
  );
}
export default Specialty;
