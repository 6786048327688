import {
  useOutputResumeExcelMutation,
  useOutputResumeMutation,
  useOutputResumePDFMutation,
} from "../../Store/slice/OutputResume";
import Data from "../../JSON/data.json";
import React, { useContext, useEffect, useState } from "react";
import NoteIcon from "../../Assets/icons/noteIcon.svg";
import noteIcon from "../../Assets/icons/noteIcon.svg";
import Address from "../../Assets/icons/Addressicon.svg";
import { Formik } from "formik";
import { ContextData } from "../../Store/Context";
// import { SetworkHistory } from "../ExcelJs/SetworkHistory";
// import { SetProgectHistory } from "../ExcelJs/SetProgectHistory"
import { toast } from "react-toastify";
import JobPrLoader from "../Loader/JobPrLoader";
import SpecialityLoader from "../Loader/SpecialityLoader";
import SkillsLoader from "../Loader/SkillsLoader";
import WorkHistoryLoader from "../Loader/WorkHistoryLoader";
import Loader from "../buttons/Loader";
import { useUpdateUserMutation } from "../../Store/slice/AuthSlice";

const Step3 = ({ currentStep, setCurrentStep, setUserStep }) => {
  const name_notation = Data?.name_notation;
  const file_type = Data?.file_type;
  const [userDetails, setUserDetails] = useState([]);
  const [speciality, setSpeciality] = useState([]);
  // const [id, setId] = useState([]);
  // eslint-disable-next-line
  const [nameNotations, setNameNotations] = useState("しない");
  // eslint-disable-next-line
  const { userDetail, userProfile } = useContext(ContextData);
  // eslint-disable-next-line
  const [fileupload, { isLoading }] = useOutputResumeMutation();
  const [fileType, setFileType] = useState("New-PDF");
  // eslint-disable-next-line
  const [getExcelFile, { error: excelError, isLoading: excelLoading }] =
    useOutputResumeExcelMutation();
  // eslint-disable-next-line
  const [getPdfFile, { error: pdfError, isLoading: pdfLoading }] =
    useOutputResumePDFMutation();
  // eslint-disable-next-line
  const [registerUserDetail, { error: isstepsError, isLoading: isstepsLoading }] = useUpdateUserMutation();

  if (isstepsError) {
    if (isstepsError?.originalStatus === 503) {
      toast.error("サービスは利用できません ！");
    } else {
      toast.error(isstepsError?.data?.message || "Something went wrong!");
    }
  }

  useEffect(() => {
    setUserDetails(userDetail?.details);
  }, [userDetail]);

  // Speciality convert array to object
  useEffect(() => {
    const abc = userDetails?.specialities?.map((speciality) => {
      const key = speciality?.split(":")[0].replace("{", "").trim();
      const value = speciality
        .split(":")[1]
        .trim()
        .replace("}", "")
        .replace("[", "")
        .replace("]", "")
        .trim()
        .split(",");
      return { [key]: value };
    });
    setSpeciality(abc);
  }, [userDetails]);

  return (
    <Formik
      initialValues={{
        notation: "しない",
        job_summary: userDetails?.job_summary ? userDetails?.job_summary : "",
        specialty: speciality,
        skill: userDetails?.skills ? userDetails?.skills : [],
        work_history: userDetails?.work_histories
          ? userDetails?.work_histories
          : [],
        Project_history: userDetails?.project_histories
          ? userDetails?.project_histories
          : [],
      }}
      enableReinitialize={true}
      onSubmit={async (values) => {
        const data = {
          name: values?.notation === "しない" ? false : true,
          new_pdf:
            fileType === "Excel"
              ? false
              : fileType === "Old-PDF"
              ? false
              : true,
          skills: values?.skill,
          work_history_ids: values?.work_history?.map((e) => e.id),
          project_history_ids: values?.Project_history?.map((e) => e.id),
        };
        var response = "";
        if (fileType === "Excel") {
          response = await getExcelFile(data);
        } else {
          response = await getPdfFile(data);
        }

        if (response?.error?.data?.errors) {
          toast.error(
            response?.error?.message ||
              "あなたの履歴書はアップロードされていません。"
          );
        }
        if (response?.data?.success === true) {
          if (fileType === "Old-PDF" || fileType === "New-PDF") {
            fetch(response?.data?.data?.resume_url)
              .then((response) => {
                response
                  .blob()
                  .then((blob) => {
                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(blob);

                    // Setting various property values for Download
                    let alink = document.createElement("a");
                    alink.href = fileURL;
                    alink.download = "resume.pdf";
                    alink.click();
                    toast.success(
                      response?.data?.message ||
                        "履歴書は正常にアップロードされました。"
                    );
                    setCurrentStep(4);
                    setUserStep(4);
                  })
                  .catch((error) => {
                    toast.error(
                      response?.error?.message ||
                        "あなたの履歴書はアップロードされていません。"
                    );
                  });
              })
              .catch((error) => {
                toast.error(
                  response?.error?.message ||
                    "あなたの履歴書はアップロードされていません。"
                );
              });
          } else {
            const anchor = document.createElement("a");
            anchor.href = response?.data?.data?.resume_url;
            anchor.download = "resume.xlxs";
            anchor.click();
            toast.success(
              response?.data?.message ||
                "履歴書は正常にアップロードされました。"
            );
            setCurrentStep(4);
            setUserStep(4);
          }
        }
      }}
    >
      {({
        errors,
        touched,
        handleChange,
        handleBlur,
        values,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form
          className="w-full flex flex-col items-center px-4"
          onSubmit={handleSubmit}
        >
          <div className="w-full md:py-10">
            <div className="w-full">
              {/* File selection Excel or PDF */}
              <section className="bg-white p-[10px] md:p-[14px] lg:p-[20px]">
                <div className="flex items-center justify-between pb-[4px] lg:pb-[8px] border-b">
                  <div>
                    <h1
                      autoFocus={currentStep === 3}
                      className="font-bold text-base md:text-[18px] lg:text-[20px] text-[#212121]"
                    >
                      データ形式
                    </h1>
                  </div>
                </div>
                <div className=" mt-[8px] md:mt-[12px] lg:mt-[16px]">
                  <div
                    role="group"
                    aria-labelledby="my-radio-group"
                    className="mt-[8px] md:flex items-center"
                  >
                    {file_type?.map((item) => {
                      return (
                        <label
                          className="mt-4 md:mt-0 text-xs md:text-base cursor-pointer mr-[24px] flex items-center"
                          key={item?.id}
                        >
                          <input
                            name="file_type"
                            type="radio"
                            value={item?.title}
                            onChange={(e) => setFileType(e.target.value)}
                            // onChange={(event) => {
                            //   setNameNotations(event.target.value);
                            // }}
                            className="mr-[8px] font-normal text-[12px] lg:text-[14px] text-[#212121]"
                            defaultChecked={item?.check}
                          />
                          {item?.titleToDisplay}
                        </label>
                      );
                    })}
                  </div>
                </div>
              </section>

              {/* Selection of Do or Do not */}
              <section className="bg-white mt-[16px] p-[10px] md:p-[14px] lg:p-[20px]">
                <div className="flex items-center justify-between pb-[4px] lg:pb-[8px] border-b">
                  <div>
                    <h1 className="font-bold text-base md:text-[18px] lg:text-[20px] text-[#212121]">
                      名前の表記
                    </h1>
                  </div>
                </div>
                <div className=" mt-[8px] md:mt-[12px] lg:mt-[16px]">
                  <label
                    id="my-radio-group"
                    className=" text-xs text-[#878787] font-bold lg:text-[14px]"
                  >
                    ノンネーム表記
                  </label>
                  <div
                    role="group"
                    aria-labelledby="my-radio-group"
                    className=" mt-[8px] flex items-center"
                  >
                    {name_notation?.map((item) => {
                      return (
                        <label
                          className="text-base cursor-pointer mr-[24px] flex items-center registration"
                          key={item?.id}
                        >
                          <input
                            name="notation"
                            type="radio"
                            value={item?.title}
                            onChange={handleChange}
                            className="mr-[8px] font-normal text-[12px] lg:text-[14px] text-[#212121]"
                            defaultChecked={item?.check}
                          />
                          {item?.title}
                        </label>
                      );
                    })}
                  </div>
                </div>
              </section>

              {/* Job summmary */}
              <section className="bg-white p-[10px] md:p-[14px] lg:p-[20px] mt-[16px]">
                <div className="flex items-center justify-between pb-[4px] lg:pb-[8px] border-b">
                  <div>
                    <h1 className="text-base font-bold text-[20px] md:text-[18px] lg:text-[20px] text-[#212121]">
                      職務要約
                    </h1>
                  </div>
                </div>
                <div className="font-normal text-[12px] lg:text-[14px] text-[#212121] mt-[8px] md:mt-[12px] lg:mt-[16px] leading-[22px]">
                  {userDetail?.isLoading ? (
                    <JobPrLoader />
                  ) : userDetails?.job_summary?.length ? (
                    userDetails?.job_summary
                  ) : (
                    <div className="font-bold text-center">データなし</div>
                  )}{" "}
                </div>
              </section>

              {/* Specialty */}
              <section className="bg-white p-[10px] md:p-[14px] lg:p-[20px] mt-[16px]">
                <div className="flex items-center justify-between pb-[4px] lg:pb-[8px] border-b mb-[16px]">
                  <div>
                    <h1 className="font-bold text-[16px] md:text-[18px] lg:text-[20px]">
                      得意分野
                    </h1>
                  </div>
                </div>
                <div className="font-normal text-[12px] lg:text-[14px] text-[#212121] mt-[8px] md:mt-[12px] lg:mt-[16px] leading-[22px]">
                  {userDetail?.isLoading ? (
                    <SpecialityLoader />
                  ) : speciality?.length ? (
                    speciality?.map((specialitys, index) => {
                      return (
                        <div key={index} className="mb-[24px] max-w-[600px]">
                          <h1 className="text-[#878787] font-bold text-[12px] lg:text-[14px]">
                            {Object.keys(specialitys)}
                          </h1>
                          <div className="flex gap-[8px] items-center flex-wrap mt-2">
                            {Object.values(specialitys)[0].map(
                              (category, idx) => {
                                const isSelected = values?.specialty?.find(
                                  (ele) => {
                                    return Object.values(ele)[0].includes(
                                      category
                                    );
                                  }
                                );
                                return (
                                  <div
                                    onClick={(e) => {
                                      var selectedKey =
                                        Object.keys(specialitys);
                                      if (isSelected) {
                                        const for_remove = values.specialty
                                          .map((ele) => {
                                            if (ele[selectedKey]) {
                                              const filteredArray =
                                                Object.values(
                                                  ele[selectedKey]
                                                ).filter(
                                                  (aaa) =>
                                                    aaa !== e.target.textContent
                                                );
                                              if (filteredArray.length === 0) {
                                                return null;
                                              }
                                              return {
                                                [selectedKey]: filteredArray,
                                              };
                                            }
                                            return ele;
                                          })
                                          .filter(Boolean); // use for handle null value
                                        setFieldValue("specialty", for_remove);
                                      } else {
                                        const for_add = values?.specialty?.map(
                                          (ele) => {
                                            if (ele[selectedKey]) {
                                              const qqq = [
                                                ...Object.values(ele)[0],
                                                e.target.textContent,
                                              ];
                                              return {
                                                [selectedKey]: qqq,
                                              };
                                            } else {
                                              return ele;
                                            }
                                          }
                                        );
                                        setFieldValue("specialty", for_add);
                                      }
                                    }}
                                    key={idx}
                                    className={`text-[14px] cursor-pointer font-normal leading-[22px] py-[6px] rounded-full px-[16px] ${
                                      isSelected
                                        ? `text-[#0956FF] bg-[#E6EEFF] border border-[#0956FF]`
                                        : "border-0 bg-[#F6F6F6] text-[#878787]"
                                    } w-[fit-content] rounded-full`}
                                  >
                                    {category}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="font-bold text-center">データなし</div>
                  )}
                </div>
              </section>

              {/* Skills */}
              <section className="bg-white p-[10px] md:p-[14px] lg:p-[20px] mt-[16px]">
                <div className="flex items-center justify-between pb-[4px] lg:pb-[8px] border-b mb-[16px]">
                  <div>
                    <h1 className="font-bold text-[16px] md:text-[18px] lg:text-[20px]">
                      活かせるスキル・資格
                    </h1>
                  </div>
                </div>
                {userDetail?.isLoading ? (
                  <SkillsLoader />
                ) : userDetails?.skills?.length ? (
                  userDetails?.skills?.map((items) => {
                    return (
                      <label key={items?.id}>
                        <div
                          key={items?.id}
                          className="cursor-pointer mt-[16px] flex items-center gap-[8px] bg-[#F6F6F6] py-[12px] px-[16px] registration"
                        >
                          <input
                            name="skill"
                            type="checkbox"
                            className="h-[18px] w-[18px]"
                            value={items?.skill}
                            defaultChecked
                            onBlur={handleBlur}
                            onChange={(e) => {
                              const checkedvalue = e.target.checked;
                              const updatedSkills = checkedvalue
                                ? [...values.skill, items]
                                : values.skill.filter(
                                    (skill) => skill !== items
                                  );
                              setFieldValue("skill", updatedSkills);
                            }}
                          />
                          <img src={NoteIcon} alt="NoteIcon" />
                          <span className="font-normal text-[12px] lg:text-[14px] leading-[22px] tetx-[#212121]">
                            {items}
                          </span>
                        </div>
                      </label>
                    );
                  })
                ) : (
                  <div className="font-bold text-center">データなし</div>
                )}
              </section>

              {/* Work history  */}
              <section
                className="bg-white p-[10px] md:p-[14px] lg:p-[20px] mt-[16px]"
                id="section4"
              >
                <div className="flex items-center justify-between mb-[12px] lg:mb-[16px] border-b">
                  <h1 className="font-bold text-[16px] md:text-[18px] lg:text-[20px] pb-[8px]">
                    職務経歴(所属経歴)
                  </h1>
                </div>
                <div className="mt-[16px]">
                  {userDetail?.isLoading ? (
                    <WorkHistoryLoader />
                  ) : userDetails?.work_histories?.length ? (
                    userDetails?.work_histories?.map((item, index) => {
                      return (
                        <label
                          className="flex cursor-pointer"
                          key={index}
                          htmlFor={`wrokhistory${index}`}
                        >
                          <input
                            id={`wrokhistory${index}`}
                            name="work_history"
                            type="checkbox"
                            className="h-[18px] w-[18px] mr-[16px]"
                            value={item}
                            onBlur={handleBlur}
                            defaultChecked
                            onChange={(e) => {
                              const updatedWorkHistory = e.target.checked
                                ? [...values?.work_history, item]
                                : values?.work_history.filter(
                                    (history) => history !== item
                                  );
                              setFieldValue("work_history", updatedWorkHistory);
                            }}
                          />
                          <div key={index} className={`flex gap-[8px]`}>
                            <div className="hidden md:flex flex-col justify-between">
                              <h1 className="text-[14px] h-[22px] w-[79px] font-bold leading-[22px]">
                                {item?.start_date}
                              </h1>
                              <h1 className="text-[14px] h-[22px] font-bold leading-[22px]">
                                {item?.end_date}
                              </h1>
                            </div>
                            <div className="blue-time-line my-[10px]"></div>
                            <div className="flex flex-col gap-[16px] my-[11px] w-full">
                            <h1 className="text-[14px] block md:hidden mt-[-10px] h-[22px] w-[79px] font-bold leading-[22px]">
                                {item?.start_date}
                              </h1>
                              
                              <div>
                                <div>
                                  <div>
                                    <div className="flex justify-between">
                                      <h1 className="flex items-center gap-[4px]">
                                        <img
                                          src={Address}
                                          alt="Address"
                                          className="h-[15px] w-[14px]"
                                        />
                                        <span className="text-[12px] lg:text-[14px] font-bold leading-[22px] text-[#212121]">
                                          {item?.company_name}
                                        </span>
                                      </h1>
                                    </div>
                                    <h1 className="flex items-center mt-[4px] gap-[4px]">
                                      <img
                                        src="/Assets/icons/timezoneIcon.svg"
                                        alt="img icon"
                                        className="h-[15px] w-[14px]"
                                      />
                                      <span className="text-[12px] font-normal text-[#878787] leading-[18px]">
                                        {item?.employment_type}
                                      </span>
                                    </h1>
                                    <h1 className="flex items-center mt-[4px] gap-[4px]">
                                      <img
                                        src="/Assets/icons/position.svg"
                                        alt="img icon"
                                        className="h-[15px] w-[14px]"
                                      />
                                      <span className="text-[12px] font-normal text-[#878787] leading-[18px]">
                                        {item?.position}
                                      </span>
                                    </h1>
                                    <h1 className="flex items-center mt-[4px] gap-[4px]">
                                      <img
                                        src="/Assets/icons/msgIcon.svg"
                                        alt="img icon"
                                        className="h-[15px] w-[14px]"
                                      />
                                      <span className="text-[12px] font-normal text-[#878787] leading-[18px]">
                                        {item?.business_details}
                                      </span>
                                    </h1>
                                  </div>
                                </div>
                                <div className="mt-[16px]">
                                  <div className="pt-[4px] pr-[0] pb-[4px] pl-[0] flex w-[400px]  items-center text-[12px] lg:text-[14px] max-w-max">
                                    <h1 className="text-[#878787] font-bold w-[56px] leading-[22px]">
                                      販売
                                    </h1>
                                    <p className="text-[#212121] font-normal ml-[16px] text-start leading-[22px]">
                                      {item?.amount_of_sales}
                                    </p>
                                  </div>
                                  <div className="pt-[4px] pr-[0] pb-[4px] pl-[0] flex w-[400px]  items-center text-[12px] lg:text-[14px] max-w-max">
                                    <h1 className="text-[#878787] font-bold w-[56px] leading-[22px]">
                                      上場
                                    </h1>
                                    <p className="text-[#212121] font-normal ml-[16px] text-start leading-[22px]">
                                      {item?.listing_type}
                                    </p>
                                  </div>
                                  <div className="pt-[4px] pr-[0] pb-[4px] pl-[0] flex w-[400px]  items-center text-[12px] lg:text-[14px] max-w-max">
                                    <h1 className="text-[#878787] font-bold w-[56px] leading-[22px]">
                                      就業者数
                                    </h1>
                                    <p className="text-[#212121] font-normal ml-[16px] text-start leading-[22px]">
                                      {item?.number_of_employees}
                                    </p>
                                    <p className="text-[14px] ml-[8px] font-normal leading-[22px] text-[#212121]">
                                      人
                                    </p>
                                  </div>
                                  <div className="pt-[4px] pr-[0] pb-[4px] pl-[0] flex w-[400px]  items-center text-[12px] lg:text-[14px] max-w-max">
                                    <h1 className="text-[#878787] font-bold w-[56px] leading-[22px]">
                                      業務内容
                                    </h1>
                                    <p className="text-[#212121] font-normal ml-[16px] text-start leading-[22px]">
                                      {item?.description}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <h1 className="text-[14px] block md:hidden mb-[-10px] h-[22px] font-bold leading-[22px]">
                                {item?.end_date}
                              </h1>
                            </div>
                          </div>
                        </label>
                      );
                    })
                  ) : (
                    <div className="font-bold text-center">データなし</div>
                  )}
                </div>
              </section>

              {/* Project history */}
              <section className="bg-white p-[10px] md:p-[14px] lg:p-[20px] mt-[16px] w-full">
                <div className="flex items-center justify-between pb-[4px] lg:pb-[8px] border-b">
                  <h1 className="font-bold text-[16px] md:text-[18px] lg:text-[20px]">
                    プロジェクト経歴
                  </h1>
                </div>
                <div className="mt-[16px]">
                  {userDetails?.project_histories?.length ? (
                    userDetails?.project_histories?.map((items, index) => {
                      const skillData = items?.related_skills?.map((skill) => {
                        const key = skill.split(":")[0].replace("{", "").trim();
                        var value = skill
                          .split(":")[1]
                          .replace("}", "")
                          .replace("[", "")
                          .replace("]", "")
                          .split(",");
                        const formattedValue = value.join("/");
                        return { [key]: formattedValue };
                      });
                      return (
                        <label
                          key={index}
                          className="flex cursor-pointer"
                          // htmlFor={`projectHistory${index}`}
                        >
                          <input
                            id={`projectHistory${index}`}
                            name="Project_history"
                            type="checkbox"
                            className="h-[18px] w-[18px] mr-[16px]"
                            value={values?.Project_history}
                            defaultChecked
                            onBlur={handleBlur}
                            onChange={(e) => {
                              const updatedProjectHistory = e.target.checked
                                ? [...values?.Project_history, items]
                                : values?.Project_history.filter(
                                    (history) => history !== items
                                  );
                              setFieldValue(
                                "Project_history",
                                updatedProjectHistory
                              );
                            }}
                          />
                          <div className="flex gap-[8px]">
                            <div className="hidden md:flex flex-col justify-between cursor-pointer text-[#212121]">
                              <h1 className="text-[14px] h-[22px] w-[79px] font-bold leading-[22px]">
                                {items?.start_date}
                              </h1>
                              <h1 className="text-[14px] h-[22px] font-bold leading-[22px]">
                                {items?.end_date}
                              </h1>
                            </div>
                            <div className="blue-time-line my-[10px]"></div>
                            <div className="flex flex-col gap-[4px] my-[11px] w-full">
                              <h1 className="text-[14px] block md:hidden mt-[-10px] h-[22px] w-[79px] font-bold leading-[22px]">
                                {items?.start_date}
                              </h1>
                              <div>
                                <div className="flex justify-between">
                                  <h1 className="text-[12px] lg:text-[14px] font-bold leading-[22px] flex items-center gap-[4px]">
                                    <img
                                      src={noteIcon}
                                      alt="noteIcon"
                                      className="h-[15px] w-[14px]"
                                    />
                                    <span className="text-[#212121]">
                                      {items?.project_name}
                                    </span>
                                  </h1>
                                </div>
                                <h1 className="flex items-center mt-[4px] gap-[4px]">
                                  <img
                                    src="/Assets/icons/userIcon.svg"
                                    alt="icon"
                                    className="h-[15px] w-[14px]"
                                  />
                                  <span className="text-[12px] font-normal text-[#878787] leading-[18px] ">
                                    {items?.company}
                                  </span>
                                </h1>
                                <h1 className="flex items-center mt-[4px] gap-[4px]">
                                  <img
                                    src="/Assets/icons/position.svg"
                                    alt="icon"
                                    className="h-[15px] w-[14px]"
                                  />
                                  <span className="text-[12px] font-normal text-[#878787] leading-[18px] ">
                                    {items?.role}
                                  </span>
                                </h1>
                                <h1 className="flex items-center mt-[4px] gap-[4px]">
                                  <img
                                    src="/Assets/icons/Addressicon.svg"
                                    alt="icon"
                                    className="h-[15px] w-[14px]"
                                  />
                                  <span className="text-[12px] font-normal text-[#878787] leading-[18px] ">
                                    {items?.affiliation}
                                  </span>
                                </h1>
                              </div>
                              {/* {id?.find((item) => item === items?.id) ===
                                items?.id && ( */}
                              <h1 className="text-[14px] block md:hidden mb-[-10px] h-[22px] font-bold leading-[22px]">
                                {items?.end_date}
                              </h1>
                              <div>
                                <div className="mt-[16px] w-full">
                                  <div>
                                    <div className="pt-[4px] pr-[0] pl-[0] flex items-center gap-[16px] font-bold text-[12px] lg:text-[14px] leading-[22px]">
                                      <h1 className="text-[#878787]">
                                        プロジェクト概要 :
                                      </h1>
                                    </div>
                                    <div className="pt-[4px] pr-[0] pb-[4px] pl-[0] flex items-center gap-[16px] font-normal text-[12px] lg:text-[14px]">
                                      <h1 className="text-[#212121] leading-[22px] break-all">
                                        {items?.project_overview}
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-[16px]">
                                  <div>
                                    <h1 className="text-[#878787] text-[12px] lg:text-[14px] font-bold">
                                      担当業務 :
                                    </h1>
                                    {items?.responsibilities?.map((items) => {
                                      return (
                                        <h1
                                          key={items?.id}
                                          className="text-[12px] lg:text-[14px] font-normal text-[#212121] leading-[22px] flex items-center mt-[4px] gap-[4px] break-all"
                                        >
                                          <img
                                            src="/Assets/icons/pinicon.svg"
                                            alt="icon"
                                          />
                                          <span>{items}</span>
                                        </h1>
                                      );
                                    })}
                                  </div>
                                </div>
                                <div className="mt-[16px] w-full">
                                  <div>
                                    <div className="pt-[4px] pr-[0] pb-[4px] pl-[0] flex items-center gap-[16px] font-bold text-[12px] lg:text-[14px]">
                                      <h1 className="text-[#878787]">
                                        実績 / 取り組み :
                                      </h1>
                                    </div>
                                    <div className="pt-[4px] pr-[0] pb-[4px] pl-[0] flex items-center gap-[16px] font-normal text-[12px] lg:text-[14px]">
                                      <h1 className="text-[#212121] leading-[22px] break-all">
                                        {items?.achievements_initiatives}
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-[16px]">
                                  <div>
                                    <h1 className="text-[#878787]">
                                      関連スキル :
                                    </h1>
                                  </div>
                                  {skillData?.map((skill, index) => {
                                    return (
                                      <div key={index}>
                                        <h1 className="text-[12px] lg:text-[14px] font-normal leading-[22px] text-[#212121] mt-[4px]">
                                          {Object.keys(skill)}
                                        </h1>
                                        <div className="flex mt-[8px] gap-[8px] flex-wrap">
                                          <div className="font-normal text-[12px] lg:text-[14px] text-[#0956FF] px-[16px] py-[6px] bg-[#E6EEFF] w-[fit-content] rounded-2xl md:rounded-full cursor-pointer">
                                            {Object.values(skill)}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                              {/* )} */}
                              {/* <button
                                type="button"
                                className="mt-[16px] flex items-center gap-[2px]"
                                onClick={() => {
                                  setId(items?.id);
                                  handleOpen(items?.id);
                                }}
                              >
                                <p className="text-[12px] font-bold text-[#FF6F00]">
                                  {Data?.view_details_button}
                                </p>
                                <span>
                                  {id?.find((item) => item === items?.id) ===
                                  items?.id ? (
                                    <img
                                      src={OpenChev}
                                      alt="cheav"
                                      className="transform rotate-[180deg]"
                                    />
                                  ) : (
                                    <img src={OpenChev} alt="cheav" />
                                  )}
                                </span>
                              </button> */}
                            </div>
                          </div>
                        </label>
                      );
                    })
                  ) : (
                    <div className="font-bold text-center">データなし</div>
                  )}
                </div>
              </section>
            </div>
          </div>
          {/* Submit button */}
          <div className="mt-16 flex gap-[10px] items-center justify-center w-fit text-xs">
            <button
              className="w-[100px] h-[40px] bg-white"
              onClick={() => {
                setCurrentStep(2);
              }}
              type="button"
            >
              戻る
            </button>
            <button
              type="submit"
              disabled={
                isLoading || pdfLoading || isstepsLoading || excelLoading
              }
              className={`w-40 md:w-[300px] h-[40px] text-white flex gap-2 justify-center items-center rounded-[4px] font-bold ${isLoading || pdfLoading || isstepsLoading || excelLoading ? "bg-[#CED0D8]" : "bg-gradient-to-r from-[#0956FF] to-[#5389FF]"}`}
            >
              {isLoading || pdfLoading || isstepsLoading || excelLoading ? (
                <div className="flex justify-center">
                  <Loader />
                </div>
              ) : (
                "出力する"
              )}

              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.64598 1.64689C4.69242 1.60033 4.7476 1.56339 4.80834 1.53818C4.86909 1.51297 4.93421 1.5 4.99998 1.5C5.06575 1.5 5.13087 1.51297 5.19161 1.53818C5.25236 1.56339 5.30753 1.60033 5.35398 1.64689L11.354 7.64689C11.4005 7.69334 11.4375 7.74852 11.4627 7.80926C11.4879 7.87001 11.5009 7.93513 11.5009 8.00089C11.5009 8.06666 11.4879 8.13178 11.4627 8.19253C11.4375 8.25327 11.4005 8.30845 11.354 8.35489L5.35398 14.3549C5.26009 14.4488 5.13275 14.5015 4.99998 14.5015C4.8672 14.5015 4.73986 14.4488 4.64598 14.3549C4.55209 14.261 4.49935 14.1337 4.49935 14.0009C4.49935 13.8681 4.55209 13.7408 4.64598 13.6469L10.293 8.00089L4.64598 2.35489C4.59941 2.30845 4.56247 2.25327 4.53727 2.19253C4.51206 2.13178 4.49908 2.06666 4.49908 2.00089C4.49908 1.93513 4.51206 1.87001 4.53727 1.80926C4.56247 1.74852 4.59941 1.69334 4.64598 1.64689V1.64689Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Step3;
