import * as Yup from "yup";

// eslint-disable-next-line
const regex = /^(?=.*[a-zA-Z])(?=.*[0-9!@#\$%\^&\*])[a-zA-Z0-9!@#\$%\^&\*]{8,15}$/;

export const loginSchema = Yup.object().shape({
    email: Yup.string().required("メールアドレスを入力してください。 "),
    password: Yup.string().required("パスワードを入力してください。 "),
});

export const RegisterSchema = Yup.object().shape({
    email: Yup.string().required("メールアドレスを入力してください。 "),
    password: Yup.string()
        // .min(8, "短すぎます (最低 8 文字)")
        // .max(15, "長すぎます (最大 15 文字)")
        .matches(regex, "パスワードは半角英数字の組み合わせで、8文字以上15文字以下である必要があります")
        .required("パスワードを入力してください。 "),
    // first_name: Yup.string().required("姓を入力してください。 "),
    // last_name: Yup.string().required("名を入力してください。 "),
    // romanized_first_name: Yup.string().required(
    //   "FirstName(ローマ字)を入力してください。 "
    // ),
    // romanized_last_name: Yup.string().required(
    //   "LastName (ローマ字)を入力してください。 "
    // ),
    // dob: Yup.string().required("生年月日を入力してください。 "),
    // region: Yup.string().required("Please select region Required "),
});

export const resetPasswordSchema = Yup.object().shape({
    oldPassword: Yup.string()
        .required("古いパスワードを入力してください。 ")
        .min(8, "短すぎます (最低 8 文字)")
        .max(15, "長すぎます (最大 15 文字)"),
    newPassword: Yup.string()
        .required("新しいパスワードを入力してください。 ")
        .min(8, "短すぎます (最低 8 文字)")
        .max(15, "長すぎます (最大 15 文字)")
        .matches(regex, "パスワードは半角英数字の組み合わせで、8文字以上15文字以下である必要があります"),
    confirmPassword: Yup.string()
        .required("新しいパスワードを再入力してください。 ")
        .min(8, "短すぎます (最低 8 文字)")
        .max(15, "長すぎます (最大 15 文字)")
        .oneOf(
            [Yup.ref("newPassword"), null],
            "パスワードの確認は新しいパスワードと同じである必要があります"
        ),
});

export const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email("有効な電子メール アドレスを入力してください。")
        .required("メールアドレスを入力してください。 "),
});

export const Forgot_ResetPasswordSchema = Yup.object().shape({
    new_password: Yup.string()
        .required("新しいパスワードを入力してください。 ")
        .min(8, "短すぎます (最低 8 文字)")
        .max(15, "長すぎます (最大 15 文字)")
        .matches(regex, "パスワードは半角英数字の組み合わせで、8文字以上15文字以下である必要があります"),
    confirm_password: Yup.string()
        .required("新しいパスワードを再入力してください。 ")
        .min(8, "短すぎます (最低 8 文字)")
        .max(15, "長すぎます (最大 15 文字)")
        .oneOf(
            [Yup.ref("new_password"), null],
            "パスワードの確認は新しいパスワードと同じである必要があります"
        ),
});

export const summarySchema = Yup.object().shape({
    job_summary: Yup.string()
        .required("概要を空にすることはできません")
        .test("check-summary", "概要を空にすることはできません", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
});

export const editProfileSchema = Yup.object().shape({
    first_name: Yup.string()
        .required("名前 必須")
        .test("check-summary", "名前 必須", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
    last_name: Yup.string()
        .required("姓 必須")
        .test("check-summary", "姓 必須", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
        //if u want to allow only certain file sizes in bytes
    image: Yup.mixed().test("fileSize", "The file is too large", (file) => {return !file || file.size <= 2000000}),
    romanized_first_name: Yup.string()
        .required("FirstName (ローマ字) 必須")
        .test("check-summary", "FirstName (ローマ字) 必須", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
    romanized_last_name: Yup.string()
        .required("LastName (ローマ字) 必須")
        .test("check-summary", "LastName (ローマ字) 必須", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
});

// export const achievementsSchema = Yup.object().shape({
//   achievements: Yup.string().required("Achievement  Required "),
// });

export const selfPromotionSchema = Yup.object().shape({
    selfPromotion: Yup.string()
        .required("自己宣伝が必要です")
        .test("check-summary", "自己宣伝が必要です", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
});

export const skillsSchema = Yup.object().shape({
    skills: Yup.array().required("必要なスキル"),
});

export const UpdateWorkHistorySchema = Yup.object().shape({
    company_name: Yup.string()
        .required("会社名は必須です")
        .test("check-summary", "会社名は必須です", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
    position: Yup.string()
        .required("役職名は必須です")
        .test("check-summary", "役職名は必須です", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
    description: Yup.string()
        .required("業務内容は必須です")
        .test("check-summary", "業務内容は必須です", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
    start_year: Yup.number()
        .min(1900, `無効な年月です`)
        .max(new Date().getFullYear(), `今年以前の年月を入力してください`)
        .required("開始年は必須です"),
    start_month: Yup.number()
        .min(1, "月を 1 ～ 12 の間で入力してください")
        .max(12, "月を 1 ～ 12 の間で入力してください")
        .required("開始月は必須です"),
    end_year: Yup.number()
        .min(
            Yup.ref("start_year"),
            "終了年を開始年より前にすることはできません"
        )
        .max(new Date().getFullYear(), `今年以前の年月を入力してください`)
        .required("終了年は必須です"),
    end_month: Yup.number()
        .min(1, "月を 1 ～ 12 の間で入力してください")
        .max(12, "月を 1 ～ 12 の間で入力してください")
        .required("終了月は必須です"),
    employment_type: Yup.string()
        .required("従業員のステータスは必須です")
        .test("check-summary", "従業員のステータスは必須です", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
    // capital: Yup.number()
    //   .min(0, "資本金は0以上である必要があります")
    //   .required("資本金は必須です"),
    listing_type: Yup.string()
        .required("リストから選択してください")
        .test("check-summary", "リストから選択してください", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
    amount_of_sales: Yup.string().required("売上高は必須です"),
    number_of_employees: Yup.number()
        .min(1, "従業員は 1 人以上である必要があります")
        .required("従業員数は必須です"),
    business_details: Yup.string()
        .required("事業内容は必須です")
        .test("check-summary", "事業内容は必須です", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
});

export const AddWorkHistoryBioSchema = Yup.object().shape({
    company_name: Yup.string()
        .required("会社名は必須です")
        .test("check-summary", "会社名は必須です", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
    position: Yup.string()
        .required("役職名は必須です")
        .test("check-summary", "役職名は必須です", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
    description: Yup.string()
        .required("業務内容は必須です")
        .test("check-summary", "業務内容は必須です", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
    start_year: Yup.number()
        .min(1900, `無効な年月です`)
        .max(new Date().getFullYear(), `今年以前の年月を入力してください`)
        .required("開始年は必須です"),
    start_month: Yup.number()
        .min(1, "月を 1 ～ 12 の間で入力してください")
        .max(12, "月を 1 ～ 12 の間で入力してください")
        .required("開始月は必須です"),
    end_year: Yup.number()
        .min(
            Yup.ref("start_year"),
            "終了年を開始年より前にすることはできません"
        )
        .max(new Date().getFullYear(), `今年以前の年月を入力してください`)
        .required("終了年は必須です"),
    end_month: Yup.number()
        .min(1, "月を 1 ～ 12 の間で入力してください")
        .max(
            Yup.ref("end_year") === 2024 ? 3 : 12,
            "月を 1 ～ 12 の間で入力してください"
        )
        .required("終了月は必須です"),
    employment_type: Yup.string()
        .required("従業員のステータスは必須です")
        .test("check-summary", "従業員のステータスは必須です", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
    // capital: Yup.number()
    //   .min(0, "資本金は0以上である必要があります")
    //   .required("資本金は必須です"),
    listing_type: Yup.string()
        .required("リストから選択してください")
        .test("check-summary", "リストから選択してください", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
    amount_of_sales: Yup.string().required("売上高は必須です"),
    number_of_employees: Yup.number()
        .min(1, "従業員は 1 人以上である必要があります")
        .required("従業員数は必須です"),
    business_details: Yup.string()
        .required("事業内容は必須です")
        .test("check-summary", "事業内容は必須です", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
});

export const addBioProjectHistory = Yup.object().shape({
    name: Yup.string()
        .required("プロジェクト名を空にすることはできません")
        .test(
            "check-summary",
            "プロジェクト名を空にすることはできません",
            (value) => {
                if (!value) return false;
                return value.trim().length > 0;
            }
        ),
    client_name: Yup.string()
        .required("会社名を空白にすることはできません")
        .test(
            "check-summary",
            "会社名を空白にすることはできません",
            (value) => {
                if (!value) return false;
                return value.trim().length > 0;
            }
        ),
    role: Yup.string()
        .required("役割を空にすることはできません")
        .test("check-summary", "役割を空にすることはできません", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
    affiliation: Yup.string()
        .required("所属を空にすることはできません")
        .test("check-summary", "所属を空にすることはできません", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
    start_year: Yup.number()
        .min(1900, `無効な年月です`)
        .max(new Date().getFullYear(), `今年以前の年月を入力してください`)
        .required("開始年は必須です"),
    start_month: Yup.number()
        .min(1, "月を 1 ～ 12 の間で入力してください")
        .max(12, "月を 1 ～ 12 の間で入力してください")
        .required("開始月は必須です"),
    end_year: Yup.number()
        .min(
            Yup.ref("start_year"),
            "終了年を開始年より前にすることはできません"
        )
        .max(new Date().getFullYear(), `今年以前の年月を入力してください`)
        .required("終了年は必須です"),
    end_month: Yup.number()
        .min(1, "月を 1 ～ 12 の間で入力してください")
        .max(12, "月を 1 ～ 12 の間で入力してください")
        .required("開始月は必須です"),
    overview: Yup.string()
        .required("概要を空にすることはできません")
        .test("check-summary", "概要を空にすることはできません", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
    achievements: Yup.string()
        .required("実績を空にすることはできません")
        .test("check-summary", "実績を空にすることはできません", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
    responsibilities: Yup.array().required("責任が求められる"),
    // specialities: Yup.array().required(""),
});

export const updateBioProjectHistory = Yup.object().shape({
    project_name: Yup.string()
        .required("プロジェクト名を空にすることはできません")
        .test(
            "check-summary",
            "プロジェクト名を空にすることはできません",
            (value) => {
                if (!value) return false;
                return value.trim().length > 0;
            }
        ),
    company: Yup.string().required("会社名を空白にすることはできません"),
    // .test("check-summary", "会社名を空白にすることはできません", (value) => {
    //   if (!value) return false;
    //   return value.trim().length > 0;
    // }),
    role: Yup.string()
        .required("役割を空にすることはできません")
        .test("check-summary", "役割を空にすることはできません", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
    affiliation: Yup.string()
        .required("所属を空にすることはできません")
        .test("check-summary", "所属を空にすることはできません", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
    start_year: Yup.number()
        .min(1900, "無効な年月です")
        .max(new Date().getFullYear(), `今年以前の年月を入力してください`)
        .required("開始年は必須です"),
    start_month: Yup.number()
        .min(1, "月を 1 ～ 12 の間で入力してください")
        .max(12, "月を 1 ～ 12 の間で入力してください")
        .required("開始月は必須です"),
    end_year: Yup.number()
        .min(
            Yup.ref("start_year"),
            "終了年を開始年より前にすることはできません"
        )
        .max(new Date().getFullYear(), `今年以前の年月を入力してください`)
        .required("終了年は必須です"),
    end_month: Yup.number()
        .min(1, "月を 1 ～ 12 の間で入力してください")
        .max(12, "月を 1 ～ 12 の間で入力してください")
        .required("開始月は必須です"),
    project_overview: Yup.string()
        .required("概要を空にすることはできません")
        .test("check-summary", "概要を空にすることはできません", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
    achievements: Yup.string()
        .required("実績を空にすることはできません")
        .test("check-summary", "実績を空にすることはできません", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
    responsibilities: Yup.array().required("責任が求められる"),
    // specialities: Yup.array().required(""),
});

export const OutputResumeSchema = Yup.object().shape({});

export const RegistrationStep1Schema = Yup.object().shape({
    first_name: Yup.string().required("姓を入力してください。 "),
    last_name: Yup.string().required("名を入力してください。 "),
    romanized_first_name: Yup.string().required(
        "FirstName (ローマ字)を入力してください。 "
    ),
    romanized_last_name: Yup.string().required(
        "LastName (ローマ字)を入力してください。 "
    ),
    dob: Yup.string().required("生年月日を入力してください"),
    affiliation: Yup.string().required("所属を選択してください"),
    region: Yup.string().required("地域を選択してください"),
});

export const ProjectVerification = Yup.object().shape({
    role: Yup.string().required("あなたの役割を選択してください。 "),
    email: Yup.string().required("メールアドレスを入力してください。 "),
    message: Yup.string()
        .required("メッセージを入力してください。")
        .test("check-summary", "メッセージを入力してください。", (value) => {
            if (!value) return false;
            return value.trim().length > 0;
        }),
});
