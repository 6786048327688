import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const AiDraft = createApi({
  reducerPath: "AiDraft",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/api`,
  }),
  endpoints: (builder) => ({
    AddAidraft: builder.mutation({
      query: (payload) => {
        return {
          url: "/v1/send_message",
          method: "POST",
          body: payload,
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        };
      },
    }),

  }),
});


export const { useAddAidraftMutation } = AiDraft;
