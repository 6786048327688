import { Outlet } from "react-router-dom";
// import authsideimg from "../Assets/Images/authSideImg.png";
import authsideimg from "../Assets/Images/auth-img1.png";

function AuthLayout() {
  const year = new Date().getFullYear();
  return (
    <div className="auth h-screen w-screen flex flex-col items-center justify-center">
      {/* <> */}
        <div className="md:login w-full md:w-[650px] lg:w-[998px] flex-1 md:flex-[unset] h-auto md:h-auto lg:h-[654px] flex z-10 justify-center bg-white">
          <div className="w-full hidden md:flex md:h-[inherit] lg:h-[654px] items-center">
            <img
              src={authsideimg}
              alt="sideimg"
              className="h-fit w-full object-fill lg:object-cover"
            />
          </div>
          <Outlet />
        </div>
        <div className="mt-[20px] mb-4 md:mb-0 text-[14px] leading-[22px] font-normal text-[#878787] md:text-white flex justify-center">
          {`@${year} 株式会社Plus Synergy All Right Reserved.`}
        </div>
      {/* </> */}
    </div>
  );
}
export default AuthLayout;